import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {
	let default_day = $('#get_today').val()
  $('span[id^="scout_calender_select_"]').on('click',function(){
  		$('#'+default_day+'_detail').hide()
  		$('#'+'scout_calender_select_'+default_day).removeClass('flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white')
  		default_day = $(this).data('date')
  		$('#'+'scout_calender_select_'+default_day).addClass('flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white')
  		$('#'+default_day+'_detail').show()
    })

  $('#player_edit_save_button').on('click',function(){
    $(this).addClass('animate-pulse transition')
    $(this).removeClass('cursor-pointer')
  })

});