import * as d3 from "d3";
import _ from "lodash";

import { pitchColors } from "./pitch_info";

export default function batterSummary(
  chartContainerId,
  tableContainerId,
  pitches,
  byPitchType,
  projections,
  batter_stats
) {
  const tableCell =
    "px-2 py-2 text-xs text-right text-gray-500 leading-4 font-medium tracking-wider";
  const pitchTableColDefinition = [
    {
      label: "Season",
      html: row => row.season,
      className: `${tableCell} font-bold text-md`
    },
    {
      label: "Playing Time",
      html: row => row.playing_time_proj,
      className: tableCell
    },
    {
      label: "Age",
      html: row => row.age,
      className: tableCell
    },
    {
      label: "Regressed K%",
      html: row => row.regressed_k_percent,
      className: tableCell
    },
    {
      label: "Regressed BB%",
      html: row => row.regressed_bb_percent,
      className: tableCell
    },
    {
      label: "Regressed xDAM375",
      html: row => row.regressed_xdam375,
      className: tableCell
    },
    {
      label: "Hitter Projections",
      html: row => row.proj_batting_runs_520,
      className: tableCell
    },
  ];

  const advanceTableColDefinition = [
    {
      label: "Pitch Type",
      html: row => row.pitch_type,
      className: `${tableCell} font-bold text-md`
    },
    {
      label: "Max EV",
      html: row => row.max_ev,
      className: tableCell
    },
    {
      label: "Avg EV",
      html: row => row.avg_ev,
      className: tableCell
    },
    {
      label: "Avg Angle",
      html: row => row.avg_angle,
      className: tableCell
    },
    {
      label: "Pull%",
      html: row => row.pull_rate,
      className: tableCell
    },
    {
      label: "Hard Hit%",
      html: row => row.hard_hit_rate,
      className: tableCell
    },
    {
      label: "Heart Swing%",
      html: row => row.heart_swing_rate,
      className: tableCell
    },
    {
      label: "Chase%",
      html: row => row.chase_rate,
      className: tableCell
    },
    {
      label: "Ground Ball%",
      html: row => row.ground_ball_rate,
      className: tableCell
    },
    {
      label: "Xdamage 375",
      html: row => row.xdam375,
      className: tableCell
    },
  ];
populateDataTable(tableContainerId, [projections], pitchTableColDefinition);
populateDataTable(
    "#batter-table-2-container",
    batter_stats,
    advanceTableColDefinition
  );
function populateDataTable(tableContainerId, projections, colDefinition) {
    const table = d3.select(tableContainerId);
    console.log(projections)
    table
      .select("thead")
      .selectAll("tr")
      .data([""])
      .enter()
      .append("tr")
      .selectAll("th")
      .data((row, i) => {
        return colDefinition.map(col => {
          const cell = {};
          for (const c in col) {
            cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
          }
          return cell;
        });
      })
      .enter()
      .append("th")
      .html(cell => cell.label)
      .attr("class", cell => cell.className);

    table
      .select("tbody")
      .selectAll("tr")
      .data(projections)
      .enter()
      .append("tr")
      .attr("class", (r, i) => (i % 2 == 0 ? "bg-white" : "bg-gray-50"))
      .selectAll("td")
      .data((row, i) => {
        return colDefinition.map(col => {
          const cell = {};
          for (const c in col) {
            cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
          }
          return cell;
        });
      })
      .enter()
      .append("td")
      .html(cell => cell.html)
      .attr("class", cell => cell.className);
    return;
  }
}
