import * as d3 from "d3";
import * as d3Lasso from "d3-lasso";

import Rails from "@rails/ujs";
import pickadate from "pickadate";
import koreanTranslation from "pickadate/builds/translations/ko_KR";
import videojs from "video.js";
import videojsPlaylistPlugin from "videojs-playlist";

const playlistUi = require("videojs-playlist-ui");
videojs.registerPlugin("playlistUi", playlistUi.default);

const DEFAULT_PLAYLIST_NAME = "My Playlist";
const DEFAULT_START_DATE = new Date(
  new Date().setDate(new Date().getDate() - 3)
);
const DEFAULT_END_DATE = new Date();
const ALL_PITCH_TYPES = [
  "Fastball",
  "Slider",
  "Curveball",
  "ChangeUp",
  "Sinker",
  "Cutter",
  "Splitter",
  "Other"
];
const ALL_PITCH_RESULTS = [
  "StrikeCalled",
  "FoulBall",
  "StrikeSwinging",
  "InPlay",
  "BallCalled",
  "HitByPitch"
];
const ALL_HIT_TYPES = [
  "FlyBall",
  "LineDrive",
  "Popup",
  "Bunt",
  "GroundBall",
  "Undefined"
];
const ALL_PA_RESULTS = [
  "Out",
  "Single",
  "Double",
  "Triple",
  "HomeRun",
  "Sacrifice",
  "Error",
  "FieldersChoice",
  "Strikeout",
  "Walk",
  "Undefined"
];
const COMBINED_HIT_RESULTS = ["Single", "Double", "Triple", "HomeRun"];
const AT_BAT = [
  "Out",
  "Strikeout",
  "Single",
  "Double",
  "Triple",
  "HomeRun",
  "Error",
  "FieldersChoice"
];

const selectedFilters = {};
let pitcherSearch;
let batterSearch;
let allPitches = [];
let selectedPitch = {};
let fromPicker;
let toPicker;
let player;
let svg;
let lassoedPitches = [];
let view_select;

document.addEventListener("turbolinks:load", () => {
  const vidElem = document.getElementById("pitch-scatter-vjs");
  if (!vidElem) {
    return;
  }

  setUpDateFilters();
  enablePitcherSearch();
  enableBatterSearch();
  $("#pitcher-team-filter").on("change", () => {
    pitcherSearch.destroy();
    delete selectedFilters["pitcher"];
    enablePitcherSearch();
    applyFilters();
  });
  $("#batter-team-filter").on("change", () => {
    batterSearch.destroy();
    delete selectedFilters["batter"];
    enableBatterSearch();
    applyFilters();
  });
  $("#pitch-type-filter").on("change", applyFilters);
  $("#pitch-result-filter").on("change", applyFilters);
  $("#pitcher-throws-r").on("change", applyFilters);
  $("#pitcher-throws-l").on("change", applyFilters);
  $("#batter-side-r").on("change", applyFilters);
  $("#batter-side-l").on("change", applyFilters);
  $("#bases-empty").on("change", applyFilters);
  $("#man-on-first").on("change", applyFilters);
  $("#man-on-second").on("change", applyFilters);
  $("#man-on-third").on("change", applyFilters);
  $("#hit-type").on("change", applyFilters);
  $("#pa-result").on("change", applyFilters);

  player = videojs("pitch-scatter-vjs", {
    controls: true,
    autoplay: true,
    preload: "auto",
    playbackRates: [0.25,0.5,0.75,1,1.25,1.5]
  });
  draw([]);
  applyFilters();
  $("#pitch-scatter-play-all").on("click", createPlaylist);
  player.on("playlistitem", highlightPitchInPlaylist);
  $("#pitch-scatter-clear-selection").on("click", clearLasso);
  $("#pitch-scatter-download").on("click", downloadPlaylist);
});

function downloadPlaylist() {
  name = $("#pitch-scatter-playlist-name").val() || DEFAULT_PLAYLIST_NAME;
  Rails.ajax({
    url: `/downloads?kind=playlist&name=${name}&video_ids=${allPitches
      .map(pitch => pitch.id)
      .join(",")}`,
    type: "post",
    success: Turbolinks.visit("/downloads")
  });
}

function clearLasso() {
  lassoedPitches = [];
  applyFilters();
}

function setUpDateFilters() {
  const fromInitialState = { selected: DEFAULT_START_DATE };
  const toInitialState = { selected: DEFAULT_END_DATE };

  fromPicker = pickadate.create(fromInitialState, koreanTranslation);
  const fromElement = document.getElementById("pitch-video-start-date");
  if (!fromElement) {
    return;
  }
  pickadate.render(fromElement, fromPicker);

  toPicker = pickadate.create(toInitialState, koreanTranslation);
  const toElement = document.getElementById("pitch-video-end-date");
  if (!toElement) {
    return;
  }
  pickadate.render(toElement, toPicker);

  fromPicker.setMaximum({ value: toPicker.store.getState().selected });
  toPicker.setMinimum({ value: fromPicker.store.getState().selected });

  fromElement.addEventListener("pickadate:change", formattedValue => {
    fromElement.value = fromPicker.getValue();
    toPicker.setMinimum({ value: fromPicker.store.getState().selected });
    applyFilters();
  });
  toElement.addEventListener("pickadate:change", formattedValue => {
    toElement.value = toPicker.getValue();
    fromPicker.setMaximum({ value: toPicker.store.getState().selected });
    applyFilters();
  });
}

function applyFilters() {
  const query_params = [];

  const fromDate = fromPicker
    ? new Date(fromPicker.store.getState().selected)
    : DEFAULT_START_DATE;
  const toDate = toPicker
    ? new Date(toPicker.store.getState().selected)
    : DEFAULT_END_DATE;
  query_params.push(`date_from=${fromDate.toISOString()}`);
  query_params.push(`date_to=${toDate.toISOString()}`);

  if (lassoedPitches.length > 0) {
    $("#pitch-scatter-clear-selection").show();
    query_params.push(`trackman_ids=${lassoedPitches.join(",")}`);
  } else {
    $("#pitch-scatter-clear-selection").hide();
  }

  let selectedPitchType = $("#pitch-type-filter").val();
  if (selectedPitchType === "*") {
    selectedPitchType = ALL_PITCH_TYPES.join(",");
  }
  query_params.push(`pitch_type=${selectedPitchType}`);

  let selectedPitchResult = $("#pitch-result-filter").val();
  if (selectedPitchResult === "*") {
    selectedPitchResult = ALL_PITCH_RESULTS.join(",");
  }
  query_params.push(`pitch_result=${selectedPitchResult}`);

  let pitcherThrows = [];
  if ($("#pitcher-throws-r").prop("checked")) {
    pitcherThrows.push("R");
  }
  if ($("#pitcher-throws-l").prop("checked")) {
    pitcherThrows.push("L");
  }
  if (pitcherThrows.length > 0) {
    query_params.push(`pitcher_throws=${pitcherThrows.join(",")}`);
  }

  let batterSide = [];
  if ($("#batter-side-r").prop("checked")) {
    batterSide.push("R");
  }
  if ($("#batter-side-l").prop("checked")) {
    batterSide.push("L");
  }
  if (batterSide.length > 0) {
    query_params.push(`batter_side=${batterSide.join(",")}`);
  }

  let runnersOn = [];
  if ($("#bases-empty").prop("checked")) {
    runnersOn = [false, false, false];
  } else if (
    $("#man-on-first").prop("checked") ||
    $("#man-on-second").prop("checked") ||
    $("#man-on-third").prop("checked")
  ) {
    runnersOn = [false, false, false];
    if ($("#man-on-first").prop("checked")) {
      runnersOn[0] = true;
    }
    if ($("#man-on-second").prop("checked")) {
      runnersOn[1] = true;
    }
    if ($("#man-on-third").prop("checked")) {
      runnersOn[2] = true;
    }
  }

  if (runnersOn.length > 0) {
    query_params.push(`runners_on=${runnersOn.join(",")}`);
  }

  if (selectedFilters.hasOwnProperty("pitcher")) {
    query_params.push(`pitcher_id=${selectedFilters["pitcher"]}`);
  }

  let selectedPitcherTeam = $("#pitcher-team-filter").val();
  if (selectedPitcherTeam != "") {
    if ($("#video_major_minor").val() === 'minor'){
      selectedPitcherTeam = check_organization(selectedPitcherTeam)
    }
    query_params.push(`pitcher_team_id=${selectedPitcherTeam}`);
  }
  else if ($("#video_major_minor").val() === 'major'){
    query_params.push(`pitcher_team_id=${[1,2,3,4,5,6,7,8,9,23].join(",")}`);
  }
  else if ($("#video_major_minor").val() === 'minor'){
    query_params.push(`pitcher_team_id=${['11','12','13','14','15','16','17','18','19','20','21'].join(",")}`);
  }

  if (selectedFilters.hasOwnProperty("batter")) {
    query_params.push(`batter_id=${selectedFilters["batter"]}`);
  }

  let selectedBatterTeam = $("#batter-team-filter").val();
  if (selectedBatterTeam != "") {
    if ($("#video_major_minor").val() === 'minor'){
      selectedBatterTeam = check_organization(selectedBatterTeam)
    }
    query_params.push(`batter_team_id=${selectedBatterTeam}`);
    
  }
  else if ($("#video_major_minor").val() === 'major'){
    query_params.push(`batter_team_id=${[1,2,3,4,5,6,7,8,9,23].join(",")}`);
  }
  else if ($("#video_major_minor").val() === 'minor'){
    query_params.push(`batter_team_id=${['11','12','13','14','15','16','17','18','19','20','21'].join(",")}`);
  }

  let selectedHitType = $("#hit-type").val();
  if (selectedHitType === "*") {
    selectedHitType = ALL_HIT_TYPES.join(",");
  }
  query_params.push(`hit_type=${selectedHitType}`);

  let selectedPAResult = $("#pa-result").val();
  if (selectedPAResult === "*") {
    selectedPAResult = "All";
  } else if (selectedPAResult === "Hit") {
    selectedPAResult = COMBINED_HIT_RESULTS.join(",");
  } else if (selectedPAResult === "At Bat") {
    selectedPAResult = AT_BAT.join(",");
  }
  query_params.push(`pa_result=${selectedPAResult}`);

  $("#pitch-scatter-playlist").hide();
  player.playlist([]);
  Rails.ajax({
    url: "/filtered_pitches",
    type: "post",
    data: query_params.join("&"),
    success: redraw
  });
}

function check_organization(selectedTeamId){
  let return_select_team_id
  switch (selectedTeamId){
    case '1':
      return_select_team_id = 11
      break
    case '2':
      return_select_team_id = 12
      break
    case '3':
      return_select_team_id = 13
      break
    case '4':
      return_select_team_id = 14
      break
    case '5':
      return_select_team_id = 15
      break
    case '6':
      return_select_team_id = 16
      break
    case '7':
      return_select_team_id = 17
      break
    case '8':
      return_select_team_id = 18
      break
    case '9':
      return_select_team_id = 19
      break
    case '23':
      return_select_team_id = 20
      break
    default:
      return_select_team_id = selectedTeamId
  }
  return return_select_team_id
}

function saveFilter(filterType, value) {
  selectedFilters[filterType] = value;
}

function pitcherSearchDropdown() {
  if (pitcherSearch.ul.childNodes.length === 0) {
    pitcherSearch.minChars = 0;
    pitcherSearch.evaluate();
  } else if (pitcherSearch.ul.hasAttribute("hidden")) {
    pitcherSearch.open();
  } else {
    pitcherSearch.close();
  }
}

function batterSearchDropdown() {
  if (batterSearch.ul.childNodes.length === 0) {
    batterSearch.minChars = 0;
    batterSearch.evaluate();
  } else if (batterSearch.ul.hasAttribute("hidden")) {
    batterSearch.open();
  } else {
    batterSearch.close();
  }
}

function enablePitcherSearch() {
  const input = document.querySelector("#pitch-video-pitcher-search");
  if (!input) {
    return;
  }
  input.value = "";
  input.addEventListener("awesomplete-selectcomplete", selected => {
    saveFilter("pitcher", selected.text.value);
    applyFilters();
  });
  input.addEventListener("input", ev => {
    const value = input.value.trim();

    if (!value) {
      delete selectedFilters["pitcher"];
      applyFilters();
    }
  });

  var ajax = new XMLHttpRequest();
  let queryParams = [""];
  let selectedTeam = $("#pitcher-team-filter").val();
  if (selectedTeam != "") {
    queryParams.push(`pitcher_team_id=${selectedTeam}`);
  }
  ajax.open("GET", `/pitch_pitchers?${queryParams.join("&")}`, true);
  ajax.onload = function() {
    const list = JSON.parse(ajax.responseText)["pitch_pitchers"].map(i => ({
      label: `${i.name_kor} (${i.name_full}) (${i.back_number})`,
      value: i.id
    }));
    const pitchers = list;

    pitcherSearch = new Awesomplete(input, {
      list: pitchers,
      replace: suggestion => (input.value = suggestion.label),
      minChars: 0,
      maxItems: 5
    });

    Awesomplete.$("#pitcher-search-dropdown").removeEventListener(
      "click",
      pitcherSearchDropdown
    );
    Awesomplete.$("#pitcher-search-dropdown").addEventListener(
      "click",
      pitcherSearchDropdown
    );
  };
  ajax.send();
}

function enableBatterSearch() {
  const input = document.querySelector("#pitch-video-batter-search");
  if (!input) {
    return;
  }
  input.value = "";
  input.addEventListener("awesomplete-selectcomplete", selected => {
    saveFilter("batter", selected.text.value);
    applyFilters();
  });
  input.addEventListener("input", ev => {
    const value = input.value.trim();

    if (!value) {
      delete selectedFilters["batter"];
      applyFilters();
    }
  });

  var ajax = new XMLHttpRequest();
  let queryParams = [""];
  var selectedTeam = $("#batter-team-filter").val();
  if (selectedTeam != "") {
    queryParams.push(`hitter_team_id=${selectedTeam}`);
  }
  ajax.open("GET", `/pitch_hitters?${queryParams.join("&")}`, true);
  ajax.onload = function() {
    const list = JSON.parse(ajax.responseText)["pitch_hitters"].map(i => ({
      label: `${i.name_kor} (${i.name_full}) (${i.back_number})`,
      value: i.id
    }));
    const batters = list;

    batterSearch = new Awesomplete(input, {
      list: batters,
      replace: suggestion => (input.value = suggestion.label),
      minChars: 0,
      maxItems: 5
    });

    Awesomplete.$("#batter-search-dropdown").removeEventListener(
      "click",
      batterSearchDropdown
    );
    Awesomplete.$("#batter-search-dropdown").addEventListener(
      "click",
      batterSearchDropdown
    );
  };
  ajax.send();
}

function filterByPitchType() {
  Rails.ajax({
    url: `/pitches?pitch_type=${this.value}`,
    type: "get",
    success: redraw
  });
}

function filterByPitcherId(id) {
  Rails.ajax({
    url: `/pitches?pitcher_id=${id}`,
    type: "get",
    success: redraw
  });
}

function pitchColor(pitch) {
  switch (pitch.pitch_type) {
    case "Fastball":
      return "red";
    case "Sinker":
      return "orange";
    case "Slider":
      return "blue";
    case "ChangeUp":
      return "purple";
    case "Curveball":
      return "cyan";
    default:
      return "gray";
  }
}

function createPlaylist() {
  selectedPitch = {};
  $("#pitch-scatter-playlist").show();
  Rails.ajax({
    url: `/pitch_playlist?ids=${allPitches.map(pitch => pitch.id).join(",")}&view=broadcast`,
    type: "get",
    success: pitchVids => {
      const pitchPlaylist = [];
      for (const vid of pitchVids) {
        pitchPlaylist.push({
          name: `${vid.game_date}: ${vid.away_team} @ ${vid.home_team} --
          ${vid.pitcher_name_kor} ${vid.pitch_type} to ${vid.batter_name_kor}`,
          sources: [
            {
              src: vid.url,
              type: "video/mp4"
            }
          ]
        });
      }
      player.playlist(pitchPlaylist);
      player.playlist.autoadvance(0);
      player.playlistUi();
    }
  });
}

function redraw(pitches) {
  const margin = { top: 10, right: 30, bottom: 30, left: 60 };
  const width = 560 - margin.left - margin.right;
  const height = 500 - margin.top - margin.bottom;
  const x = d3
    .scaleLinear()
    .domain([-1, 1])
    .range([0, width]);
  const y = d3
    .scaleLinear()
    .domain([0, 2])
    .range([height, 0]);

  allPitches = pitches.pitches;

  if (allPitches && allPitches.length > 0) {
    if (allPitches.length <= 200) {
      $("#pitch-scatter-request-download").show();
    } else {
      $("#pitch-scatter-request-download").hide();
    }
    $("#pitch-scatter-play-all").show();
  } else {
    $("#pitch-scatter-request-download").hide();
    $("#pitch-scatter-play-all").hide();
  }

  const t = svg.transition().duration(500);
  const circles = svg
    .selectAll("circle")
    .data(allPitches, d => (d ? d.trackman_id : Math.random()))
    .join(enter =>
      enter
        .append("circle")
        .on("click", handleClick)
        .attr("cx", d => x(d.plate_loc_side))
        .attr("cy", d => y(d.plate_loc_height))
        .attr("r", 3)
        .style("opacity", 0)
        .attr("fill", d => pitchColor(d))
        .attr("class", "cursor-pointer")
        .attr("id", d => d.trackman_id)
        .call(enter => enter.transition(t).style("opacity", 0.5))
    );

  // Lasso
  if (circles.nodes) {
    let lasso = d3Lasso
      .lasso()
      .closePathSelect(true)
      .closePathDistance(100)
      .items(circles)
      .targetArea(d3.select("#strikezone"))
      .on("start", () => {})
      .on("draw", () => {})
      .on("end", () => {
        lassoedPitches = lasso.selectedItems()._groups[0].map(c => c.id);
        if (lassoedPitches.length > 0) {
          applyFilters();
        }
      });

    svg.call(lasso);
  }
}

function draw(pitches) {
  const margin = { top: 10, right: 30, bottom: 30, left: 60 };
  const width = 560 - margin.left - margin.right;
  const height = 500 - margin.top - margin.bottom;
  allPitches = pitches.pitches;

  // append the svg object to the body of the page
  svg = d3
    .select("#strikezone")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom);

  const x = d3
    .scaleLinear()
    .domain([-1, 1])
    .range([0, width]);
  const y = d3
    .scaleLinear()
    .domain([0, 2])
    .range([height, 0]);

  // Add axes
  // svg.append("g").attr("transform", "translate(0," + height + ")").call(d3.axisBottom(x))
  // svg.append("g").call(d3.axisLeft(y))

  const sz_width = x(0.2032) - x(-0.2032);
  const sz_height = y(0.5588) - y(0.9652);
  // Draw strike zone
  svg
    .append("rect")
    .attr("x", x(-0.2032))
    .attr("y", y(0.9652))
    .attr("width", sz_width)
    .attr("height", sz_height)
    .attr("fill", "none")
    .attr("stroke", "black");

  // Add dots
  redraw(pitches);
}

function highlightPitchInPlaylist() {
  let vid_src = player.currentSrc();
  if (!vid_src) {
    vid_src = player.playlist()[0].sources[0].src;
  }
  const re = /https:\/\/.*\/game_video\/\d{8}\/.*\/(.*)\.mp4\?/;
  const matches = re.exec(vid_src);
  const tm_id = matches[1];

  d3.selectAll("circle").style("stroke", null);
  d3.selectAll("circle").style("opacity", 0.5);
  selectedPitch = allPitches.filter(x => x.trackman_id === tm_id);
  d3.select(`[id='${tm_id}']`).style("stroke", "black");
  d3.select(`[id='${tm_id}']`).style("opacity", 1);
}

function handleClick(pitch) {
  Rails.ajax({
    url: `/pitches/${pitch.id}`,
    type: "get",
    success: playVideo
  });
}

function playVideo(pitch) {
  $("#pitch-scatter-playlist").hide();
  player.playlist([]);
  player.src({
    src: pitch.video_url,
    type: "video/mp4"
  });
  highlightPitchInPlaylist();
}
