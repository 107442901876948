import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
  $('#mobile_menu_detail_1').fadeIn(1000);
  $('#mobile_menu_detail_2').fadeIn(1000);
  $('#mobile_menu_detail_3').fadeIn(1000);
  $('#mobile_menu_detail_4').fadeIn(1000);
  $('#roster_popup').fadeIn(1000);

  $('div[id$="main_nav"]').on('click',function() {
    let hereid = $(this).attr('id').replace('main_nav','')
    $('#'+hereid+'arrow_nav').toggleClass('rotate-90');
    $('#'+hereid+'menu_nav').toggle(40);
  })

  $('#close_side_bar').on('click',function(){
    $('#full_side_bar').toggle(20)
  })
  $('#slide_menu_hamburger').on('click',function(){
    $('#menubar_icons').fadeToggle(200)
    $('#nav-bar-mobile').fadeToggle(200)
  })
  $("#menu-analytics-button").on('click',function(){
    $('#menu-analytics').fadeToggle(1000)
  })
  $("#menu-scouts-button").on('click',function(){
    $('#menu-scouts').fadeToggle(1000)
  })
  $("#menu-development-button").on('click',function(){
    $('#menu-development').fadeToggle(1000)
  })
  $("#menu-science-button").on('click',function(){
    $('#menu-science').fadeToggle(1000)
  })
  $("#menu-rookie-button").on('click',function(){
    $('#menu-rookie').fadeToggle(1000)
  })
  $("#menu-international-button").on('click',function(){
    $('#menu-international').fadeToggle(1000)
  })
  $("#nav-bar-close-button").on('click',function(){
    $('#nav-bar-mobile').hide()
  })
});