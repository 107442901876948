import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["player"];

  disconnect() {
    this.closePlaylist();
  }

  async getPlaylist(event) {
    event.preventDefault();

    const { paResult, season, batterId, pitcherId } = event.target.dataset;
    let params = `vid_type=${paResult}&season=${season}`;
    if (batterId !== undefined) {
      params += `&batter_id=${batterId}`;
    }
    if (pitcherId !== undefined) {
      params += `&pitcher_id=${pitcherId}`;
    }
    const response = await fetch(`/video_on_click?${params}`);
    const json = await response.json();

    const videoPlayerController = this.application.getControllerForElementAndIdentifier(
      this.playerTarget,
      "video-player"
    );
    if(this.playerTarget.id=='mobile_player_info_video'){this.playerTarget.setAttribute("class","");}
    else{this.playerTarget.setAttribute("class", "flex");}
    videoPlayerController.open(json);
  }

  closePlaylist() {
    this.playerTarget.setAttribute("class", "hidden");
    const videoPlayerController = this.application.getControllerForElementAndIdentifier(
      this.playerTarget,
      "video-player"
    );
    videoPlayerController.stop();
  }
}
