import { Controller } from "stimulus";

export default class extends Controller {

    show_major_batter_split_sector(event){
        var sector_screening = document.getElementById('sector_major_batter_split');
        sector_screening.style.display = 'block';
        var sector_biomechanic = document.getElementById('sector_minor_batter_split');
        sector_biomechanic.style.display = 'none';
    }
    
    show_minor_batter_split_sector(event){
        var sector_screening = document.getElementById('sector_minor_batter_split');
        sector_screening.style.display = 'block';
        var sector_biomechanic = document.getElementById('sector_major_batter_split');
        sector_biomechanic.style.display = 'none';   
    }

    show_major_pitcher_split_sector(event){
        var sector_screening = document.getElementById('sector_major_pitcher_split');
        sector_screening.style.display = 'block';
        var sector_biomechanic = document.getElementById('sector_minor_pitcher_split');
        sector_biomechanic.style.display = 'none';
    }
    
    show_minor_pitcher_split_sector(event){
        var sector_screening = document.getElementById('sector_minor_pitcher_split');
        sector_screening.style.display = 'block';
        var sector_biomechanic = document.getElementById('sector_major_pitcher_split');
        sector_biomechanic.style.display = 'none';   
    }


}