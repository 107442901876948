import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
	$('#button_year_1').click(function(){
		$('#year_1').show()
		$('#year_2').hide()
		$('#year_3').hide()
		$('#year_4').hide()
	});
	$('#button_year_2').click(function(){
		$('#year_1').hide()
		$('#year_2').show()
		$('#year_3').hide()
		$('#year_4').hide()
	});
	$('#button_year_3').click(function(){
		$('#year_1').hide()
		$('#year_2').hide()
		$('#year_3').show()
		$('#year_4').hide()
	});
	$('#button_year_4').click(function(){
		$('#year_1').hide()
		$('#year_2').hide()
		$('#year_3').hide()
		$('#year_4').show()
	});
});