import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", () => {
  const prefElem = document.getElementById("nominates_new");
  if (!prefElem) {
    return;
  }
  let nominatesNewSearch;
  enableNominatesNewSearch();

  function enableNominatesNewSearch(){
  	const input = document.querySelector("#nominates_new");
  	var ajax = new XMLHttpRequest();

	ajax.open("GET", `/nominates_search?`, true);
	ajax.onload = function() {
	const list = JSON.parse(ajax.responseText)["noimates_search"].map(i => ({
	label: `${i.player_name}`,
	value: i.player_name
	}));
	const players = list;

	nominatesNewSearch = new Awesomplete(input, {
	list: players,
	replace: suggestion => (input.value = suggestion.label),
	minChars: 0,
	maxItems: 5
	});
	};
	ajax.send();
  }
 })