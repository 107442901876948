import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", () => {
  const prefElem = document.getElementById("pref_new");
  if (!prefElem) {
    return;
  }
  let prefNewSearch;
  enablePrefNew();

  function enablePrefNew(){
  	const input = document.querySelector("#pref_new");
  	var ajax = new XMLHttpRequest();

	let queryParams = ["all_pitchers=true"];
	//let selectedTeam = $("#pitcher-team-filter").val();
	//if (selectedTeam != "") {
	//queryParams.push(`pitcher_team_id=${selectedTeam}`);
	//}
	ajax.open("GET", `/pref`, true);
	ajax.onload = function() {
	const list = JSON.parse(ajax.responseText)["pref_done"].map(i => ({
	label: `${i.player_name}`,
	value: i.player_name
	}));
	const players = list;

	prefDoneSearch = new Awesomplete(input, {
	list: players,
	replace: suggestion => (input.value = suggestion.label),
	minChars: 0,
	maxItems: 5
	});
	};
	ajax.send();
  }
 })