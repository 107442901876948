// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
	$('input[type="checkbox"][id^="acquire_checkbox"]').change(function(){
		if($(this).prop("checked")){
			$("#"+$(this).attr("id")+"_svg").removeClass("bg-blue-200")
			$("#"+$(this).attr("id")+"_svg").addClass("bg-blue-500")
			press_acquire($(this).data("rpid"))
		}
		else if($(this).prop("checked")==false){
			$("#"+$(this).attr("id")+"_svg").removeClass("bg-blue-500")
			$("#"+$(this).attr("id")+"_svg").addClass("bg-blue-200")
			cancel_acquire($(this).data("rpid"))
		}
	});

	function press_acquire(report_id){
		let acquire_url = "/reports/"+String(report_id)+"/press_acquire"
		Rails.ajax({
			url: acquire_url,
			type: 'PATCH'
		})
	}

	function cancel_acquire(report_id){
		let acquire_url = "/reports/"+String(report_id)+"/cancel_acquire"
		Rails.ajax({
			url: acquire_url,
			type: 'PATCH'
		})
	}
});