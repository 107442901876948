import Plyr from "plyr";

jQuery(document).on('turbolinks:load', function() {
  const input = document.querySelector("#scouts_video_input_1");

  var ajax_scouts_video = new XMLHttpRequest();
  ajax_scouts_video.open("GET", `/scout_videos_indexing?`, true);

  ajax_scouts_video.onload = function() {
    const list = JSON.parse(ajax_scouts_video.responseText)["scout_videos_indexing"].map(i => ({
      label: `${i.video_name}`,
      value: i.id
    }))
    const video_list = list
    var temp = new Awesomplete(input, {
        list: video_list,
        replace: suggestion => (input.value = suggestion.label),
        minChars: 0,
        maxItems: 5
    })
  }

  ajax_scouts_video.send()

  $('#scouts_video_input').on('click',()=>{})
  
  let scouts_video_player = new Plyr('#scouts_video_player');
  
  $('#scouts_video_input').on('change',() => {
    console.log($(this).attr('value'))
    var names = [];
    for (var i = 0; i < $("#scouts_video_input").get(0).files.length; ++i) {
        names.push($("#scouts_video_input").get(0).files[i].name);
    }
    $("#scouts_video_filename").val(names);
    $("#scouts_video_list").empty();
    for (var i=0; i<names.length; i++){
      $("#scouts_video_list").append("<li class='py-4 flex'><div class='ml-3'><p class='text-sm text-gray-500'>"+names[i]+"</p></div></li>");
    }
  });

  $('#biomechanic_input').on('change',() => {
    var names = [];
    for (var i = 0; i < $("#biomechanic_input").get(0).files.length; ++i) {
        names.push($("#biomechanic_input").get(0).files[i].name);
    }
    $("#biomechanic_report_filename").val(names);
    $("#biomechanic_report_list").empty();
    for (var i=0; i<names.length; i++){
      $("#biomechanic_report_list").append("<li class='py-4 flex'><div class='ml-3'><p class='text-sm text-gray-500'>"+names[i]+"</p></div></li>");
    }
  });

  $('#scouts_video_submit').on('click',()=>{
    $(this).attr('disabled', 'disabled');
    $('#scouts_video_submit').addClass('cursor-wait');
    $('#scouts_video_submit').addClass('animate-pulse');
    $('#scouts_video_submit').removeClass('hover:bg-blue-500');
  });

  $('#scouts_video_submit').on('click',function(){
    $(this).val('업로드중...');
  });

  $('#close_alert').on('click',() => {
    $("#alert").slideUp(400);
  });

  $(document).on('click','tr[id^="video_tr_"]',function(){
    let vi_id = $(this).attr('data-vi')
    $('#video_id_tag').val(vi_id)
    $('#video_id_delete_tag').val(vi_id)
    let temp = $(this).attr('data-ri');
    $('#sreport_id').val(temp).attr("selected", "selected");
    console.log($(this).attr('data-url'))
    player_made($(this).attr('data-url'));
  });

  $(document).on('click','a[id^="video_tr_"]',function(){
    console.log($(this).attr('data-url'))
    player_made($(this).attr('data-url'));
  });

  $(document).on("click", function(event){
    if(!$(event.target).closest("#scouts_video_modal").length){
        scouts_video_player.stop()
    }
    });

  $('#scouts_video_save').on('click',function(){
    scouts_video_player.stop()
  })

  $('#scouts_video_delete').on('click',function(){
    scouts_video_player.stop()
  })

  $('#scouts_video_cancel').on('click',function(){
    scouts_video_player.stop()
  })

  function player_made(path){
    scouts_video_player.source = {
      type: "video",
      sources: [
      {
        src: path,
        type:"video/mp4"
      }
      ]
    };
  }
});