import * as d3 from "d3";
import _ from "lodash";

import { pitchColors } from "./pitch_info";

export default function veloDensity(
  chartContainerId,
  tableContainerId,
  pitches,
  byPitchType,
  percentiles,
  byLeageAvgs
) {
  const margin = { top: 10, right: 30, bottom: 30, left: 60 };
  const width = 560 - margin.left - margin.right;
  const height = 500 - margin.top - margin.bottom;

  // append the svg object to the body of the page
  const svg = d3
    .select(chartContainerId)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  const x = d3
    .scaleLinear()
    .domain([90, 160])
    .range([0, width]);

  // x-axis
  svg
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x));

  // y-axis
  //svg.append("g").call(d3.axisLeft(y));

  // compute kernel density estimation

  const thresholds = x.ticks(70);
  const totalBins = pitchBins(thresholds, x.domain(), pitches);
  const pitchTypeBins = {};
  for (const pitchType in byPitchType) {
    pitchTypeBins[pitchType] = pitchBins(
      thresholds,
      x.domain(),
      byPitchType[pitchType]
    );
  }

  const pitchTypeWeights = {};
  for (const pitchType in byPitchType) {
    const weights = [];
    thresholds.forEach((_, i) => {
      const total = totalBins[i].length;
      const pitch = pitchTypeBins[pitchType][i].length;
      if (total === 0) {
        weights.push(0);
      } else {
        weights.push(pitch / total);
      }
    });
    pitchTypeWeights[pitchType] = weights;
  }

  const kde = kernelDensityEstimator(kernelEpanechnikov(2), thresholds);
  const density = kde(pitches.map(d => d.rel_speed));

  const y = d3
    .scaleLinear()
    .domain([0, d3.max(density.map(d => d[1]))])
    .range([height, 0]);

  plotDensity(svg, x, y, density, null);

  for (const pitchType in byPitchType) {
    const pitchDensity = computePitchDensity(
      density,
      pitchTypeWeights[pitchType]
    );
    plotDensity(svg, x, y, pitchDensity, pitchType);
  }

  dataTable(tableContainerId, byPitchType, pitches.length, percentiles);
}

function pitchBins(thresholds, domain, pitches) {
  const binGenerator = d3
    .histogram()
    .domain(domain)
    .thresholds(thresholds);
  return binGenerator(pitches.map(p => p.rel_speed));
}

function computePitchDensity(totalDensity, weights) {
  const pitchDensity = [];
  totalDensity.forEach((d, i) => {
    pitchDensity.push([d[0], d[1] * weights[i]]);
  });

  return pitchDensity;
}

function plotDensity(svg, x, y, density, pitchType) {
  const fillColor = pitchType !== null ? pitchColors[pitchType].color : "none";
  const strokeColor =
    pitchType !== null ? pitchColors[pitchType].color : "gray";

  const datum = svg.append("path").datum(density);

  datum
    .attr("opacity", 0.7)
    .attr("fill", fillColor)
    .attr("stroke", d3.color(strokeColor).darker())
    .attr("stroke-width", 2.5)
    .attr("stroke-linejoin", "round")
    .attr(
      "d",
      d3
        .line()
        .curve(d3.curveBasis)
        .x(d => x(d[0]))
        .y(d => y(d[1]))
    );

  if (pitchType === null) {
    datum.attr("stroke-dasharray", 4);
  }
}

function computeSummaryStats(byPitchType, totalPitches, percentiles) {
  const summary = [];
  const meanFastball = d3.mean(byPitchType["fastball"].map(p => p.rel_speed));
  for (const pitchType in byPitchType) {
    let mean = d3.mean(byPitchType[pitchType].map(p => p.rel_speed));
    summary.push({
      pitchType,
      usage: byPitchType[pitchType].length / totalPitches,
      mean,
      percentile: _.find(percentiles, ["metric", `avgvelo_${pitchType}`])
        ?.percentile,
      vsFastball: meanFastball - mean
    });
  }
  return _.orderBy(summary, ["usage"], ["desc"]);
}

function dataTable(tableContainerId, byPitchType, totalPitches, percentiles) {
  const table = d3.select(tableContainerId);
  const summary = computeSummaryStats(byPitchType, totalPitches, percentiles);
  const tableCell =
    "px-2 py-2 bg-gray-50 text-xs text-right text-gray-500 leading-4 font-medium tracking-wider";
  const colDefinition = [
    {
      label: "Pitch Type",
      html: row => row.pitchType,
      className: row =>
        `${tableCell} text-${row.pitchType}`.replace("text-right", "text-left")
    },
    {
      label: "Usage",
      html: row => d3.format(".1%")(row.usage),
      className: tableCell
    },
    {
      label: "Avg Velo",
      html: row => d3.format(".1f")(row.mean),
      className: tableCell
    },
    {
      label: "Percentile",
      html: row =>
        row.percentile === undefined ? "--" : d3.format(".0%")(row.percentile),
      className: tableCell
    },
    {
      label: "vs Fastball",
      html: row => d3.format(".1f")(row.vsFastball),
      className: tableCell
    }
  ];

  table
    .select("thead")
    .selectAll("tr")
    .data([""])
    .enter()
    .append("tr")
    .selectAll("th")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("th")
    .html(cell => cell.label)
    .attr("class", cell => cell.className);

  table
    .select("tbody")
    .selectAll("tr")
    .data(summary)
    .enter()
    .append("tr")
    .selectAll("td")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("td")
    .html(cell => cell.html)
    .attr("class", cell => cell.className);
}

function kernelDensityEstimator(kernel, X) {
  return function(V) {
    return X.map(function(x) {
      return [
        x,
        d3.mean(V, function(v) {
          return kernel(x - v);
        })
      ];
    });
  };
}

function kernelEpanechnikov(k) {
  return function(v) {
    return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
  };
}
