import * as d3 from "d3";
import _ from "lodash";

import { pitchColors } from "./pitch_info";

export default function pitcherSummary(
  chartContainerId,
  tableContainerId,
  pitches,
  byPitchType,
  percentiles,
  constants,
  byLeageAvgs,
  pq_pitch_type
) {
  const tableCell = "px-2 py-2 text-xs text-right text-gray-500 leading-4 font-medium tracking-wider";
  const pitchTableColDefinition = [
    {
      label: "Pitch Type",
      html: row => row.pitchType,
      className: row =>
        `${tableCell} text-${row.pitchType}`.replace("text-right", "text-left")
    },
    {
      label: "Usage",
      html: row => d3.format(".1%")(row.usage),
      className: tableCell
    },
    {
      label: "Avg Velo",
      html: row => d3.format(".1f")(row.avg_velo),
      className: tableCell
    },
    {
      label: "Max Velo",
      html: row => d3.format(".1f")(row.max_velo),
      className: tableCell
    },
    {
      label: "Avg Spin Rate",
      html: row => d3.format(".0f")(row.avg_spin_rate),
      className: tableCell
    },
    {
      label: "Avg V Mov",
      html: row => d3.format(".1f")(row.avg_v_mov),
      className: tableCell
    },
    {
      label: "Avg H Mov",
      html: row => d3.format(".1f")(row.avg_h_mov),
      className: tableCell
    },
    {
      label: "Avg Spin Eff.",
      html: row => d3.format(".1%")(row.avg_spin_eff),
      className: tableCell
    },
    {
      label: "Avg Spin Axis",
      html: row => d3.format(".0f")(row.avg_spin_axis) + "º",
      className: tableCell
    },
    {
      label: "Avg Tilt",
      html: row => row.tilt,
      className: tableCell
    },
    {
      label: "Rel Height",
      html: row => d3.format(".2f")(row.avg_rel_height),
      className: tableCell
    },
    {
      label: "Rel Side",
      html: row => d3.format(".2f")(row.avg_rel_side),
      className: tableCell
    },
    {
      label: "Extension",
      html: row => d3.format(".2f")(row.avg_ext),
      className: tableCell
    },
  ];

  const resultsTableColDefinition = [
    {
      label: "Pitch Type",
      html: row => row.pitchType,
      className: row =>
        `${tableCell} text-${row.pitchType}`.replace("text-right", "text-left")
    },
    {
      label: "wOBA",
      html: row => d3.format(".3f")(row.woba),
      className: tableCell
    },
    {
      label: "xwOBA",
      html: row => d3.format(".3f")(row.xwoba),
      className: tableCell
    },
    {
      label: "InZone%",
      html: row => d3.format(".1%")(row.in_zone_pct),
      className: tableCell
    },
    {
      label: "Swing%",
      html: row => d3.format(".1%")(row.swing_pct),
      className: tableCell
    },
    {
      label: "Miss%",
      html: row => d3.format(".1%")(row.miss_pct),
      className: tableCell
    },
    {
      label: "Z-Miss%",
      html: row => d3.format(".1%")(row.zone_miss_pct),
      className: tableCell
    },
    {
      label: "Chase%",
      html: row => d3.format(".1%")(row.chase_pct),
      className: tableCell
    },
    {
      label: "GB%",
      html: row => d3.format(".1%")(row.gb_pct),
      className: tableCell
    },
    {
      label: "FB%",
      html: row => d3.format(".1%")(row.fb_pct),
      className: tableCell
    },
    {
      label: "LD%",
      html: row => d3.format(".1%")(row.ld_pct),
      className: tableCell
    },
    {
      label: "IFFB%",
      html: row => d3.format(".1%")(row.pu_pct),
      className: tableCell
    },
    {
      label: "PQ",
      html: row => d3.format(".1f")(row.pq),
      className: tableCell
    },
  ];

  const tableStats = computeSummaryStats(
    byPitchType,
    pitches.length,
    constants,
    pq_pitch_type
  );
  
  populateDataTable(tableContainerId, tableStats, pitchTableColDefinition);
  populateDataTable(
    "#trackman-table-2-container",
    tableStats,
    resultsTableColDefinition
  );
}

function computeSummaryStats(byPitchType, totalPitches, constants, pq_pitch_type) {
  const summary = [];
  for (const pitchType in byPitchType) {
    let pitches = byPitchType[pitchType];
    let pq_pitches = pq_pitch_type[pitchType];
    let inPlay = _.filter(
      pitches,
      p => !["Bunt", "Undefined"].includes(p.hit_type)
    ).length;
    let swings = _.filter(pitches, p =>
      ["StrikeSwinging", "FoulBall", "InPlay"].includes(p.pitch_result)
    ).length;
    
    var avg_vert_break_temp = d3.mean(pitches.map(p=>p.induced_vert_break))
    var avg_horz_break_temp = d3.mean(pitches.map(p=>-1*p.horz_break))
    var pq =  _.filter(pq_pitches, p => p.pitch_type == pitchType).length!=0 ? _.filter(pq_pitches, p => p.pitch_type == pitchType)[0]["pq"] : NaN
    summary.push({
      pitchType,
      usage: byPitchType[pitchType].length / totalPitches,
      avg_velo: d3.mean(pitches.map(p => p.rel_speed)),
      max_velo: d3.max(pitches.map(p => p.rel_speed)),
      avg_spin_rate: d3.mean(pitches.map(p => p.spin_rate)),
      avg_v_mov: d3.mean(pitches.map(p => p.induced_vert_break)),
      avg_h_mov: d3.mean(pitches.map(p => 1 * p.horz_break)),
      avg_spin_eff: null,
      avg_spin_axis: get_spin_axis(avg_vert_break_temp, avg_horz_break_temp),
      tilt : get_tilt(get_spin_axis(avg_vert_break_temp, avg_horz_break_temp)),
      avg_rel_height: d3.mean(pitches.map(p => p.rel_height)),
      avg_rel_side: d3.mean(pitches.map(p => p.rel_side)),
      avg_ext: d3.mean(pitches.map(p => p.extension)),
      woba: woba(
        _.filter(
          pitches,
          p =>
            (p.pa_pitch && p.pitch_result != "BallIntentional") ||
            p.pitch_result == "HitByPitch"
        ),
        constants
      ),
      xwoba: null,
      in_zone_pct:
        _.filter(pitches, p => p.is_in_zone).length /
        byPitchType[pitchType].length,
      swing_pct: swings / byPitchType[pitchType].length,
      miss_pct: _.filter(pitches, p => p.is_miss).length / swings,
      zone_miss_pct:
        _.filter(pitches, p => p.is_miss && p.is_in_zone).length / swings,
      chase_pct:
        _.filter(pitches, p => p.is_miss && !p.is_in_zone).length / swings,
      gb_pct:
        _.filter(pitches, p => p.hit_type == "GroundBall").length / inPlay,
      fb_pct: _.filter(pitches, p => p.hit_type == "FlyBall").length / inPlay,
      ld_pct: _.filter(pitches, p => p.hit_type == "LineDrive").length / inPlay,
      pu_pct: _.filter(pitches, p => p.hit_type == "Popup").length / inPlay,
      pq: pq
    });
  }
  return _.orderBy(summary, ["usage"], ["desc"]);
}

function get_spin_axis(vert_break, horz_break){
  if(horz_break>0){
    return Math.round(270 - Math.atan(vert_break/horz_break)/Math.PI*180)
  }
  else{
    return Math.round(90 - Math.atan(vert_break/horz_break)/Math.PI*180)
  }
}
function get_tilt(spin_axis){
  if (spin_axis<210){
    return String(parseInt(spin_axis/30)+6) + ":" + String(Math.round(spin_axis%30*2)).padStart(2,0)
  }
  else{
    return parseInt(spin_axis/30)-6 + ":" + String(Math.round(spin_axis%30*2)).padStart(2,0)
  }
}

function woba(pitches, constants) {
  const weight = pitches.reduce((w, pitch) => {
    switch (pitch.pa_result) {
      case "Walk":
        return w + constants.w_bb;
      case "Single":
        return w + constants.w_1b;
      case "Double":
        return w + constants.w_2b;
      case "Triple":
        return w + constants.w_3b;
      case "HomeRun":
        return w + constants.w_hr;
      default:
        return pitch.pitch_result == "HitByPitch" ? w + constants.w_hbp : w;
    }
  }, 0);
  return weight / pitches.length;
}

function populateDataTable(tableContainerId, summary, colDefinition) {
  const table = d3.select(tableContainerId);

  table
    .select("thead")
    .selectAll("tr")
    .data([""])
    .enter()
    .append("tr")
    .selectAll("th")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("th")
    .html(cell => cell.label)
    .attr("class", cell => cell.className);

  table
    .select("tbody")
    .selectAll("tr")
    .data(summary)
    .enter()
    .append("tr")
    .attr("class", (r, i) => (i % 2 == 0 ? "bg-white" : "bg-gray-50"))
    .selectAll("td")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("td")
    .html(cell => cell.html)
    .attr("class", cell => cell.className);
  return;
}
