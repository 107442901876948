// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]
  initialize(){
    $(document).ready(function() {
      $('.total-review').each(function(){
        //scroll height가 client height의 길이를 뛰어넘는 지점에서
        // if (elem.clientHeight < elem.scrollHeight)
        if(this.clientHeight < this.scrollHeight){
          $(this).height(this.scrollHeight)
        }
      });
      $('.each-review').each(function(){
        //scroll height가 client height의 길이를 뛰어넘는 지점에서
        // if (elem.clientHeight < elem.scrollHeight)
        if(this.clientHeight < this.scrollHeight){
          $(this).height(this.scrollHeight)
        }
      });
    })
  }
  connect() {
    $(window).on('resize', function(){
      $('.total-review').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
      $('.each-review').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
    });
  }

  show_batter_review_sector(event){
    var button_batter_review = document.getElementById('button_sector_batter_review');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_review');

    if ($('#button_sector_batter_review').hasClass('bg-black text-white')==false){
      button_batter_review.classList.replace('bg-white', 'bg-black');
      button_batter_review.classList.replace('text-black', 'text-white');
    }
    button_pitcher_review.classList.replace('bg-black', 'bg-white');
    button_pitcher_review.classList.replace('text-white', 'text-black');

    var sector_batter_review = document.getElementById('sector_batter_review');
    sector_batter_review.style.display = 'block';
    var sector_pitcher_review = document.getElementById('sector_pitcher_review');
    sector_pitcher_review.style.display = 'none';    
  }

  show_pitcher_review_sector(event){
    var button_batter_review = document.getElementById('button_sector_batter_review');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_review');
    if ($('#button_sector_pitcher_review').hasClass('bg-black text-white')==false){
      button_pitcher_review.classList.replace('bg-white', 'bg-black');
      button_pitcher_review.classList.replace('text-black', 'text-white');
    }
    button_batter_review.classList.replace('bg-black', 'bg-white');
    button_batter_review.classList.replace('text-white', 'text-black');
    
    var sector_batter_review = document.getElementById('sector_batter_review');
    sector_batter_review.style.display = 'none';
    var sector_pitcher_review = document.getElementById('sector_pitcher_review');
    sector_pitcher_review.style.display = 'block';
  }

  getGames(event){
    var date = $('#game_date').val()
    this.fetchData(date)
  }

  fetchData(date) {
    const queryParams = [
      `game_date=${date}`,
      `game_year=${date.split('-')[0]}`
    ];

    fetch(`/residual_games?${queryParams.join("&")}`)
      .then(resp => resp.json())
      .then(data => {
        this.chartData = data;
        this.setSelectBox();
      });
  }
  setSelectBox(){
    $('#game_review_select_games').empty()
    for (let item of this.chartData['games']) {
        let option = `<option value=${item['game_id']}>(${item['game_type']}) ${item['away_team']} vs ${item['home_team']} - ${item['game_number']}차전</option>`;
        $('#game_review_select_games').append(option)
      }
  }

}