import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {
  let playerListForm = []
  let tick = false
  var ajax_team_player = new XMLHttpRequest();
  ajax_team_player.open("GET", `/lotte_player_indexing?`, true)
  ajax_team_player.onload = data => getPlayerListForm(data, ajax_team_player.responseText);
  ajax_team_player.send();

  $(document).on('click','input[id$="player_name"]',function(){
  	if ($(this).parent().attr('class') == 'awesomplete'){
  	}
  	else{
        setDropdownForm($(this).attr('id'))
  	};
  })

  $(document).on('focusout','input[id$="player_name"]',function(){
  	let current_input_id = $(this).attr('id').replace('player_name','')
  	var ajax_player = new XMLHttpRequest();
  	ajax_player.open("GET",`/lotte_players?id=${$(this).val()}`,true)
  	ajax_player.onload = function(data){
  		var player = JSON.parse(ajax_player.responseText)
  		$(`#${current_input_id + 'player_name'}`).val(player['player']['name_kor'])
  		$(`#${current_input_id + 'player_id'}`).val(player['player']['id'])
  		if(player['player']['position_number']>1){
  			$(`#${current_input_id + 'position'}`).val('야수')
  		}
  		else{
  			$(`#${current_input_id + 'position'}`).val('투수')
  		}
  	}
  	ajax_player.send();
  })

  function getPlayerListForm(data, response){
    var temp = JSON.parse(response);
    if (!temp.players){
      return;
    }
    temp.players.forEach(p=>{
    	if(p.name_kor != null){
        playerListForm.push([p.name_kor + ' (' + p.back_number + ')',p.player_id]);}
    });
  }

  function setDropdownForm(tag){
  	const input_tag = document.querySelector(`#${tag}`);
  	tick = tag;
    new Awesomplete(input_tag, {
      list: playerListForm,
      minChars: 0
    }); 
  }
  
});