import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
  var optionsMap = {
      'PRO': [
        '롯데자이언츠','두산베어스','LG트윈스','SSG랜더스','NC다이노스','한화이글스','키움히어로즈','기아타이거즈','삼성라이온즈','KT위즈'
      ],
      'INTERNATIONAL': [
        'ARI','ATL','BAL','BOS','CHC','CHW','CIN','CLE','COL','DET',
        'FLA','HOU','KAN','LAA','LAD','MIL','MIN','NYM','NYY','OAK','PHI',
        'PIT','SD','SF','SEA','STL','TB','TEX','TOR','WAS'
      ]
    };

  $(document).on('click','.eac-item',function(){
    let team_id = $("#report_report_player_attributes_team_id").val();
    let position_number = $("#report_report_player_attributes_position_number").val();
    if(position_number>1){put_position(position_number);}
    if(team_id!=''&&team_id<24){put_pro_team(team_id)}
    else if(team_id>23&&team_id<152){put_amateur_team(team_id)}
  })

  function put_position(position_number){
    const position = { 2:"C", 3:"1B", 4:"2B",
                  5:"SS", 6:"3B", 7:"LF", 8:"CF", 9:"RF", '':"UTL" };
    $("#report_report_player_attributes_position").val(position[position_number])
  }

  function put_pro_team(team_id){
    const pro_team = {1:"롯데자이언츠",2:"두산베어스",3:"한화이글스",4:"기아타이거즈",5:"키움히어로즈",
                    6:"KT위즈",7:"LG트윈스",8:"NC다이노스",9:"삼성라이온즈",23:"SSG랜더스"}
    $("#tier").val("PRO");
    $("#team").val(pro_team[team_id]);
  }

  function put_amateur_team(team_id){
    const amat_team = {24: '강릉고', 25: '강릉고', 26: '강원고', 27: '개성고', 28: '경기고', 
    29: '경기상업고', 30: '경기항공고', 31: '경남고', 32: '경동고', 33: '경북고', 34: '경주고', 
    35: '공주고', 36: '광주동성고', 37: '광주제일고', 38: '광주진흥고', 39: '군산상업고', 40: '김해고', 
    41: '나주광남고', 42: '대구고', 43: '대구상원고', 44: '대전고', 45: '대전제일고', 46: '덕수고', 
    47: '도개고', 48: '동산고', 49: '라온고', 50: '마산고', 51: '마산용마고', 52: '물금고', 53: '밀성고', 
    54: '배명고', 55: '배재고', 56: '백송고', 57: '부경고', 58: '부산고', 59: '부산공업고', 60: '부산정보고', 
    61: '부천고', 62: '북일고', 63: '비봉고', 64: '상우고', 65: '서울고', 66: '서울동산고', 67: '서울디자인고', 
    68: '서울컨벤션고', 69: '선린인터넷고', 70: '설악고', 71: '성남고', 72: '성지고', 73: '세광고', 74: '세현고', 
    75: '소래고', 76: '순천효천고', 77: '신일고', 78: '신흥고', 79: '안산공업고', 80: '야로BC', 81: '야탑고', 
    82: '여주ID베이스볼클럽', 83: '예일메디텍고', 84: '우성베이스볼AC', 85: '우신고', 86: '울산공고BC', 87: '원주고', 
    88: '유신고', 89: '율곡고', 90: '인상고', 91: '인창고', 92: '인천고', 93: '장안고', 94: '장충고', 95: '전주고', 
    96: '제물포고', 97: '제주고', 98: '중앙고', 99: '진영고', 100: '청담고', 101: '청원고', 102: '청주고', 
    103: '충암고', 104: '충훈고', 105: '포항제철고', 106: '한국K-POP고', 107: '화순고', 108: '휘문고', 
    109: '강릉영동대', 110: '건국대', 111: '경남대', 112: '경성대', 113: '경일대', 114: '경희대', 115: '계명대', 
    116: '고려대', 117: '구미대', 118: '단국대', 119: '동강대', 120: '동국대', 121: '동아대', 122: '동원과학기술대', 
    123: '동의과학대', 124: '동의대', 125: '부산과학기술대', 126: '사이버한국외대', 127: '서울대', 128: '서울문화예술대', 
    129: '성균관대', 130: '세경대', 131: '세한대', 132: '송원대', 133: '수성대', 134: '신성대', 135: '여주대', 
    136: '연세대', 137: '영남대', 138: '용인송담대', 139: '원광대', 140: '유원대', 141: '인하대', 142: '제주관광대', 
    143: '제주국제대', 144: '조선이공대', 145: '중앙대', 146: '한국골프대', 147: '한려대', 148: '한양대', 
    149: '한일장신대', 150: '호원대', 151: '홍익대'}
    $("#tier").val("AMATEUR");
    $("#team").val(amat_team[team_id])
  }

  jQuery('#tier').change(function() {
    var state = jQuery(this).val()
    if(state=="PRO"||state=="INTERNATIONAL"){
      $('#team_test').html('<label for="team" class="block lg:text-sm text-3xl font-medium leading-tight text-gray-700">팀</label><select id=team name=report[report_player_attributes][team] class="form-select block w-full transition duration-150 mt-1 ease-in-out leading-tight text-3xl lg:text-sm"></select>')
    var category = jQuery(this).val(),
        $subCategory = jQuery('#team'),
        newOptions = optionsMap[category];
    $subCategory.empty();
    $.each(newOptions, function() {
      $subCategory.append(jQuery("<option></option>").text(this));
    });}
    else{
      $('#team_test').html('<label for="team" class="block lg:text-sm text-3xl font-medium leading-tight text-gray-700">팀</label><input id=team name=report[report_player_attributes][team] class="form-input block w-full transition duration-150 mt-1 ease-in-out text-3xl leading-tight lg:text-sm"></input>')
    }
  })

  jQuery('#reports_search_kind').change(function(){
    var state = jQuery(this).val()
    if(state == "PLAYER" || state == "TEAM"){
      $('#reports_text_field').show()
      $('#reports_select_field').hide()
    }
    else{
      $('#reports_text_field').hide()
      $('#reports_select_field').show()
    }
  })
});