export const color_list = [
    'rgb(255, 0, 0)', //red
    'rgb(255, 165, 0)', //orange
    'rgb(0, 128, 0)', // green
    'rgb(0, 0, 255)', // blue
    'rgb(75, 0, 130)', // navy
    'rgb(238, 130, 238)' // purple
]


export const label_dict={
    // 'neck_fx' : 'Neck-FX',
    'neck_fx' : '목 굽힘',
    // 'neck_ext' : 'Neck-EXT',
    'neck_ext': '목 폄',
    // 'neck_l_r' : 'Neck(L->R)',    
    'neck_l_r': '목 회전(왼->오)',
    // 'neck_r_l' : 'Neck(R->L)',    
    'neck_r_l': '목 회전(오->왼)',

    // 'shoulder_left_internal_rotation': 'Shoulder Internal Rotation(L)',
    'shoulder_left_internal_rotation': '어깨 내회전(왼)',
    // 'shoulder_right_internal_rotation': 'Shoulder Internal Rotation(R)',
    'shoulder_right_internal_rotation': '어깨 내회전(오)',
    // 'shoulder_left_external_rotation': 'Shoulder External Rotation(L)',
    'shoulder_left_external_rotation': '어깨 외회전(왼)',
    // 'shoulder_right_external_rotation': 'Shoulder External Rotation(R)',
    'shoulder_right_external_rotation': '어깨 외회전(오)',

    // 'overhead_squat': 'Overhead Squat',
    'overhead_squat': '오버헤드 스쿼트',

    // 'thoracic_rotation_left': 'Thoracic Rotation(L)',
    'thoracic_rotation_left': '흉추회전(왼)',
    // 'thoracic_rotation_right': 'Thoracic Rotation(R)',
    'thoracic_rotation_right': '흉추회전(오)',

    // 'trunk_flexion': 'Trunk Flexion',
    'trunk_flexion': '몸통 굽힘',

    // 'single_leg_calf_raise_left': 'Single Leg Calf Raise(L)',
    'single_leg_calf_raise_left': '카프레이즈(왼)',
    // 'single_leg_calf_raise_right': 'Single Leg Calf Raise(R)',
    'single_leg_calf_raise_right': '카프레이즈(오)',

    // 'y_balance_left' : 'Y-Balance(L)',
    'y_balance_left' : 'Y-밸런스(왼)',
    // 'y_balance_right' : 'Y-Balance(R)',
    'y_balance_right' : 'Y-밸런스(오)',

    // 'hip_left_internal_rotation' : 'Internal Rotation(L)',
    'hip_left_internal_rotation' : '내회전(왼)',
    // 'hip_left_external_rotation' : 'External Rotation(L)',
    'hip_left_external_rotation' : '외회전(왼)',
    // 'hip_right_internal_rotation': 'Internal Rotation(R)',
    'hip_right_internal_rotation': '내회전(오)',
    // 'hip_right_external_rotation': 'External Rotation(R)',
    'hip_right_external_rotation': '외회전(오)',

    // 'hip_right_external_rotation': 'Internal Rotation(R)',
    'd_internal_rotation' : '내회전(주동)',
    // 'hip_right_external_rotation': 'External Rotation(R)',
    'd_external_rotation' : '외회전(주동)', 
    // 'hip_right_external_rotation': 'Internal Rotation(R)',
    'nd_internal_rotation' : '내회전(비주동)',
    // 'hip_right_external_rotation': 'External Rotation(R)',
    'nd_external_rotation' : '외회전(비주동)',
    
    // 'd_seated_external_rotation': 'Seated External Rotation(D)',
    'd_seated_external_rotation': '어깨 외회전<br>(앉은자세)',
    // 'd_full_can': 'Full Can(D)',
    'd_full_can': '극상근 기능',
    // 'd_prone_internal_rotation': 'Prone Internal Rotation(D)',
    'd_prone_internal_rotation': '어깨 내회전<br>(엎드린자세)',
    // 'd_prone_external_rotation': 'Prone External Rotation(D)',
    'd_prone_external_rotation': '어깨 외회전<br>(엎드린자세)',

    // 'nd_seated_external_rotation': 'Seated External Rotation(ND)',
    'nd_seated_external_rotation': '어깨 외회전<br>(앉은자세)',
    // 'nd_full_can': 'Full Can(ND)',
    'nd_full_can': '극상근 기능',
    // 'nd_prone_internal_rotation': 'Prone Internal Rotation(ND)',
    'nd_prone_internal_rotation': '어깨 내회전<br>(엎드린자세)',
    // 'nd_prone_external_rotation': 'Prone External Rotation(ND)',
    'nd_prone_external_rotation': '어깨 외회전<br>(엎드린자세)',
    
    // 'y_balance_left_front': 'Front(L)',
    'y_balance_left_front': '앞방향<br>(왼)',
    // 'y_balance_right_front': 'Front(R)',  
    'y_balance_right_front': '앞방향<br>(오)',    
    // 'y_balance_left_side': 'Side(L)',
    'y_balance_left_side': '옆방향<br>(왼)',
    // 'y_balance_right_side': 'Side(R)',
    'y_balance_right_side': '옆방향<br>(오)',
    // 'y_balance_left_cross': 'Cross(L)',
    'y_balance_left_cross': '교차방향<br>(왼)',
    // 'y_balance_right_cross': 'Cross(R)',
    'y_balance_right_cross': '교차방향<br>(오)',

    // 'left_hand': 'Left',
    'left_hand': '왼손',
    // 'right_hand': 'Right',
    'right_hand': '오른손',

    // 'counter_movement_jump': 'CMJ',
    'counter_movement_jump': '반동점프(CMJ)',
    // 'squat_jump': 'Sqaut Jump',
    'squat_jump': '무반동점프(SJ)',
    
    'force': 'FORCE',
    'rfd': 'RFD',
    
    // 'field_test_sprint_10': 'Front(L)',
    'field_test_sprint_10': '스프린트<br>(10m)',
    // 'field_test_sprint_30': 'Front(R)',  
    'field_test_sprint_30': '스프린트<br>(30m)',    
    // 'field_test_medball_throws': 'Side(L)',
    'field_test_medball_throws': '메디신볼 던지기',

    // 'field_test_run_276': 'Side(R)',
    'field_test_run_276': '276m달리기',

    // 'y_balance_left_cross': 'Cross(L)',
    'field_test_three_feet': '3피트<br>달리기',
    // 'y_balance_right_cross': 'Cross(R)',
    'field_test_first_to_second': '베이스런닝<br>(1루->2루)',
    // 'y_balance_left_cross': 'Cross(L)',
    'field_test_first_to_third': '베이스런닝<br>(1루->3루)',
    // 'y_balance_right_cross': 'Cross(R)',
    'field_test_t_run': 'T-Run',    

  }