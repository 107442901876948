import * as d3 from "d3";
import _ from "lodash";
import { Controller } from "stimulus";

import summary from "../viz/batter_summary";
import exitVelo from "../viz/batter_exit_velo";

const charts = [
  { id: "summary", drawFn: summary, title: "Summary" },
  { id: 'exitVelo', drawFn: exitVelo, title: "Exit Velocity"}
];

const defaultChartId = "summary";
const defaultSeasonId = "2024";
const commonStyle =
  "flex items-center px-3 py-2 text-sm font-medium rounded-md";
const selectedStyle = commonStyle + " bg-gray-100 text-gray-900";
const unselectedStyle =
  commonStyle + " text-gray-600 hover:bg-gray-50 hover:text-gray-900";

export default class extends Controller {
  static values = { batterId: String, season: String, chart: String };

  chartData = [];
  selectedChartId = defaultChartId;
  selectedSeasonId = defaultSeasonId;

  connect() {
    this.fetchData(defaultSeasonId);
  }

  fetchData(seasonId) {
    const queryParams = [
      `batter_id=${this.batterIdValue}`,
      `season=${seasonId}`
    ];

    fetch(`/batter_trackman_pitches?${queryParams.join("&")}`)
      .then(resp => resp.json())
      .then(data => {
        this.chartData = data;
        this.drawChart();
      });
  }

  changeSeason(event) {
    event.preventDefault();
    const seasonId = event.currentTarget.getAttribute("data-season-id");

    if (seasonId === this.selectedSeasonId) {
      return;
    }
    this.selectedSeasonId = seasonId;

    const menu = document.getElementById("batter-viz-season-select-menu");
    menu.innerText = seasonId;

    this.fetchData(seasonId);
  }

  drawChart(event) {
    event?.preventDefault();
    this.clearChart();

    const { batter_stats, pitches, projections } = this.chartData;
    const chartId =
      event?.currentTarget?.getAttribute("data-chart-id") ||
      this.selectedChartId;
    this.selectedChartId = chartId;
    const chart = _.find(charts, ["id", chartId]);

    this.setSidebarClasses(chartId);

    const title = document.getElementById("trackman-chart-title");
    title.innerText = chart.title;

    if (!pitches || pitches.length === 0) {
      return;
    }

    const byPitchType = _.groupBy(pitches, p => p.pitch_type);
    const byBatterPitchType = _.groupBy(batter_stats, p => p.pitch_type);
    chart.drawFn(
      "#batter-chart-container",
      "#batter-table-container",
      pitches,
      byPitchType,
      projections,
      batter_stats
    );
  }

  setSidebarClasses(selectedChartId) {
    const sidebar = document.getElementById("batter-viz-sidebar");
    Array.from(sidebar.children).forEach(chart => {
      if (chart.getAttribute("data-chart-id") == selectedChartId) {
        chart.className = selectedStyle;
      } else {
        chart.className = unselectedStyle;
      }
    });
  }

  clearChart() {
    d3.select("#batter-chart-container > svg").remove();
    document.getElementById("batter-chart-container").innerHTML = '';
    d3.select("#batter-table-container > thead > tr").remove();
    d3.select("#batter-table-2-container > thead > tr").remove();
    d3.selectAll("#batter-table-container > tbody > tr").remove();
    d3.selectAll("#batter-table-2-container > tbody > tr").remove();
  }
}
