import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {
  $('div[id^="scout_comment_check"]').on('click',function(){
      press_scout_comment_check($(this).data('rpid'))
      $(this).addClass('bg-green-100 text-green-800')
    })

  function press_scout_comment_check(comment_id){
    let comment_url = "/scout_comments/"+String(comment_id)+"/comment_checked"
    Rails.ajax({
      url: comment_url,
      type: 'PATCH'
    })
  }

  $('#scout_comment_set_scout_comments_attributes_0_location').on('change',function(){
    $('input[id$="_location"]').val($(this).val())
  })

  $('#scout_comment_set_scout_comments_attributes_0_date').on('change',function(){
    $('input[id$="_date"]').val($(this).val())
  })

  $('#scout_year_select_button').on('click',function(){
     $("#scout_year_select_menu").slideToggle(100)
  })

  let locationListForm = []
  var ajax_location = new XMLHttpRequest();
  if (document.getElementById("scout_comment_set_scout_comments_attributes_0_location")){
    ajax_location.open("GET",`/get_location?`,true)
    ajax_location.onload = data => getLocationListForm(data, ajax_location.responseText)
    ajax_location.send();
  }
  var tick = false;
  

  $(document).on('click','input[id$="_location"]',function(){
    const ScoutCommentElem = document.getElementById("scout_comment_set_scout_comments_attributes_0_location");
    if (!ScoutCommentElem) {
      return;
    }
    if($(this).parent().attr('class') == 'awesomplete'){}
    else{
      setDropdownForm($(this).attr('id'))
    }
  })

  function getLocationListForm(data, response){
    var temp = JSON.parse(response);
    if (!temp.get_location){
      return;
    }
    temp.get_location.forEach(p=>{
      if(p.location != null){
        locationListForm.push(p.location.trim());
      }
    })
  }

  function setDropdownForm(tag){
    const input_tag = document.querySelector(`#${tag}`);
    tick = tag;
    new Awesomplete(input_tag, {
      list: Array.from(new Set(locationListForm)),
      minChars: 0
    })
  }

  let playerListForm = []
  var ajax_amat_player = new XMLHttpRequest();
  if (document.getElementById("scout_comment_set_scout_comments_attributes_0_location") || document.getElementById("unnamed_scout_comment_player_id")){
    ajax_amat_player.open("GET",`/get_amateur_player?`,true)
    ajax_amat_player.onload = data => getPlayerListForm(data, ajax_amat_player.responseText)
    ajax_amat_player.send();
  }

  $(document).on('click','input[id$="_player_id"]',function(){
    const ScoutCommentElem = document.getElementById("scout_comment_set_scout_comments_attributes_0_location");
    const UnnamedScoutCommentElem = document.getElementById("unnamed_scout_comment_player_id");
    if (!(ScoutCommentElem || UnnamedScoutCommentElem)) {
      return;
    }
    if($(this).parent().attr('class') == 'awesomplete'){}
    else{
      setDropdownFormPlayer($(this).attr('id'))
    }
  })

  function getPlayerListForm(data, response){
    var temp = JSON.parse(response);
    if (!temp.players){
      return;
    }
    temp.players.forEach(p=>{
      if(p.player_id != null){
        playerListForm.push([p.name_kor+' (' + p.current_team_name + '- No.' + p.back_number + ')', p.player_id]);
        // playerListForm.push([p.name_kor+' (' + p.current_team_name + '-' + p.back_number + ')', p.player_id]);
      }
    })
    $("#load_player_in_scout_comments").hide()
  }

  function setDropdownFormPlayer(tag){
    const input_tag = document.querySelector(`#${tag}`);
    tick = tag;
    new Awesomplete(input_tag, {
      list: playerListForm,
      maxItems: 20,
      minChars: 0
    })
  }
});