import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {
  $('#advance_scout_table_select').on('click',function(){
      $('#advance_scout_table_menu').slideToggle(200)
    })

  $(document).on('click','a[id^="download_advance_scouting_"]',function(){
  		$('#advance_scout_table_menu').slideToggle(200)
    	$('div[id^="download_advance_scouting_table_"]').hide()
    	$('#download_advance_scouting_table_' + $(this).data('rpid')).show()
  	});
});