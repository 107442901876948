// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "template" ]
    initialize(){
        $(document).ready(function() {
          $('.player-select').select2({
            placeholder: "선수 선택",
            width: '100%',
          });
        })
        $('.evaluation-content').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
    }
    connect() {
        $(window).on('resize', function(){
            $('.evaluation-content').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
        });
    }

    add_player(event) {
        const content = this.templateTarget.innerHTML.replace(/NEW_PLAYER/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML("beforebegin", content)
        $('.player-select').select2({
            placeholder: "선수 선택",
            width: '100%',
            height: '100%'
        });
    }
    
    remove_player(event) {
        const wrapper = event.target.closest(".batter-month-evaluation")
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
        }
        else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = "none"
        }
    }

    setYearMonth(){
        const year_month = document.getElementById('year_month').value;
        document.getElementById('batter_month_evaluation_year').value = year_month.split('-')[0]
        document.getElementById('batter_month_evaluation_month').value = year_month.split('-')[1]
    }

    show_month_record() {

        const start_date_search = document.getElementById('start_date_search').value;
        const end_date_search = document.getElementById('end_date_search').value;

        if (start_date_search=='' | end_date_search==''){
            if (start_date_search==''){
                alert('시작 일을 선택해주세요.')
            }
            else{
                alert('마지막 일을 선택해주세요.')
            }
            return false
        }
        else{
            const queryParams = [
                `start_date=${start_date_search}`,
                `end_date=${end_date_search}`,
            ];

            fetch(`/month_evaluations/batter_month_record?${queryParams.join("&")}`)
                .then(resp => resp.json())
                .then(data => {
                    this.tableData = data;
                    this.drawTable();
                }
            );
        }
    }

    add_batter_point(event){
        const wrapper = event.target.closest(".batter-month-evaluation")
        let batting_eye = wrapper.querySelector(("input[name*='batting_eye']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='batting_eye']:checked")).value) : 0
        let batting_contact = wrapper.querySelector(("input[name*='batting_contact']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='batting_contact']:checked")).value) : 0
        let batting_breaking = wrapper.querySelector(("input[name*='batting_breaking']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='batting_breaking']:checked")).value) : 0
        let batting_power = wrapper.querySelector(("input[name*='batting_power']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='batting_power']:checked")).value) : 0

        let defense_catching = wrapper.querySelector(("input[name*='defense_catching']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='defense_catching']:checked")).value) : 0
        let defense_throwing = wrapper.querySelector(("input[name*='defense_throwing']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='defense_throwing']:checked")).value) : 0
        let defense_range = wrapper.querySelector(("input[name*='defense_range']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='defense_range']:checked")).value) : 0
        let defense_sense = wrapper.querySelector(("input[name*='defense_sense']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='defense_sense']:checked")).value) : 0

        let running_speed = wrapper.querySelector(("input[name*='running_speed']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='running_speed']:checked")).value) : 0
        let running_sense = wrapper.querySelector(("input[name*='running_sense']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='running_sense']:checked")).value) : 0

        let batting_evaluation = batting_eye+batting_contact+batting_breaking+batting_power
        let defense_evaluation = defense_catching+defense_throwing+defense_range+defense_sense
        let running_evaluation = running_speed+running_sense

        let evaluation = batting_evaluation+defense_evaluation+running_evaluation
        
        wrapper.querySelector(".batting-evaluation").innerHTML = `1.선수 기량 발전 평가 - ${evaluation}점`
    }

    add_make_up_point(event){
        const wrapper = event.target.closest(".batter-month-evaluation")
        let onground_training = wrapper.querySelector(("input[name*='onground_training']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_training']:checked")).value) : 0
        let onground_ongame = wrapper.querySelector(("input[name*='onground_ongame']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_ongame']:checked")).value) : 0
        let onground_learning = wrapper.querySelector(("input[name*='onground_learning']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_learning']:checked")).value) : 0
        let onground_leading = wrapper.querySelector(("input[name*='onground_leading']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_leading']:checked")).value) : 0
        let daily_selfcare = wrapper.querySelector(("input[name*='daily_selfcare']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_selfcare']:checked")).value) : 0
        let daily_cleanliness = wrapper.querySelector(("input[name*='daily_cleanliness']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_cleanliness']:checked")).value) : 0
        let daily_makeup = wrapper.querySelector(("input[name*='daily_makeup']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_makeup']:checked")).value) : 0
        let daily_relationship = wrapper.querySelector(("input[name*='daily_relationship']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_relationship']:checked")).value) : 0

        let pitching_make_up = onground_training+onground_ongame+onground_learning+onground_leading+daily_selfcare+daily_cleanliness+daily_makeup+daily_relationship

        wrapper.querySelector(".batting-make-up").innerHTML = `2.선수 메이크업/워크에식 평가 - ${pitching_make_up}점`
    }

    drawTable(){
        const recordsTable = document.getElementById('batter_month_records_table_body');
        recordsTable.innerHTML=''

        for(let [ index, batter_record ] of this.tableData['batter_month_records'].entries()) {

            var strHTML = `
                <tr class="">
                    <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center'>${batter_record['player_name']}#${batter_record['back_number']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['g']}(${batter_record['gs']})</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['pa']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['ab']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['hit']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['double']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['homerun']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['rbi']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['bb']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['k']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['sb']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['cs']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['avg']==null ? '0.000' : batter_record['avg']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['obp']==null ? '0.000' : batter_record['obp']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${batter_record['slg']==null ? '0.000' : batter_record['slg']}</td>
                </tr>
            `
            recordsTable.insertAdjacentHTML( 'beforeend', strHTML );
        }
    }
    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }
}