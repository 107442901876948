import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class shoulderMMT{
    
    // init
    constructor(chartData, div_id){
        this.chartData = chartData;
        this.shoulder_mmt_d_list=[
            'd_seated_external_rotation',
            'd_full_can',
            'd_prone_internal_rotation', 
            'd_prone_external_rotation',
        ];

        this.shoulder_mmt_nd_list=[
            'nd_seated_external_rotation', 
            'nd_full_can',  
            'nd_prone_internal_rotation', 
            'nd_prone_external_rotation'
        ];

        this.max_value_d_list=[
            this.chartData['screening_max_value']['d_seated_external_rotation'],
            this.chartData['screening_max_value']['d_full_can'],
            this.chartData['screening_max_value']['d_prone_internal_rotation'],
            this.chartData['screening_max_value']['d_prone_external_rotation'],
        ]
        this.max_value_nd_list=[
            this.chartData['screening_max_value']['nd_seated_external_rotation'],
            this.chartData['screening_max_value']['nd_full_can'],
            this.chartData['screening_max_value']['nd_prone_internal_rotation'],
            this.chartData['screening_max_value']['nd_prone_external_rotation'],
        ]
        this.div_id=div_id
        this.d_throws = this.chartData['player_info']['d_throws']=='R' ? '주동(오)' : '주동(왼)'
        this.nd_throws = this.chartData['player_info']['nd_throws']=='R' ? '비주동(오)' : '비주동(왼)'
        this.unit_annotations={
            xref: 'paper',
            yref: 'paper',
            y: 0,
            xanchor: 'left',
            x: 1,
            yanchor: 'top',
            text: '단위 : [kg]',
            showarrow: false,
            font:{
                size:12
            }
        }
    };

    //function
    drawChart(){
        var d_max_value = Math.max.apply(null,this.max_value_d_list)*1.2;
        var nd_max_value = Math.max.apply(null,this.max_value_nd_list)*1.2;
        var layout = {
            autosize:true,
            barmode: 'group',
            grid: {rows: 1, columns: 2},
            subplots:[['xy','x2y']],
            yaxis:{
                automargin:true,
                fixedrange:true,
            },
            xaxis:{
                automargin: true,
                fixedrange:true,
                // ticksuffix: 'kg',
                range: [0, d_max_value],
                type: 'linear',
                title: {
                    text:this.d_throws,
                    align:'center'
                },                
            },
            xaxis2:{
                automargin: true,
                fixedrange:true,
                // ticksuffix: 'kg',
                range: [0, nd_max_value],
                type: 'linear',
                title: {
                    text:this.nd_throws,
                    align:'center'
                },                     
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.3,
                yanchor:'top',
                xref:'paper',
                "orientation": "h",
                itemclick:false
            },
            annotations:[
                // {
                //     text: this.d_throws,
                //     font: {
                //         size: 16,
                //         color: 'Black',
                //     },
                //     showarrow: false,
                //     align: 'center',
                //     x: 0.13,
                //     y: 1.1,
                //     xref: 'paper',
                //     yref: 'paper',
                // },
                // {
                //     text: this.nd_throws,
                //     font: {
                //         size: 16,
                //         color: 'Black',
                //     },
                //     showarrow: false,
                //     align: 'center',
                //     x: 0.8,
                //     y: 1.1,
                //     xref: 'paper',
                //     yref: 'paper',
                // },
                // this.unit_annotations
            ]                 
        }
        var shoulder_mmt_d_trace_list = this.getDataset('shoulder_mmt_d')
        var shoulder_mmt_nd_trace_list = this.getDataset('shoulder_mmt_nd')
        const dataList = shoulder_mmt_d_trace_list.concat(shoulder_mmt_nd_trace_list);

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }
        Plotly.newPlot('result_shoulder_mmt_d', dataList, layout, config );
        var graphDiv = document.getElementById('result_shoulder_mmt_d');
        var shoulder_mmt_d_annotationList = this.getAverageAnnotations(graphDiv);
        var shoulder_mmt_nd_annotationList = this.getAverageAnnotations(graphDiv,"shoulder_mmt_nd");
        const annotationList = shoulder_mmt_d_annotationList.concat(shoulder_mmt_nd_annotationList);
        graphDiv.layout['shapes']=annotationList
        Plotly.redraw(graphDiv);
    };

    getDataset(category){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = [];
        var category_list = category=='shoulder_mmt_d' ? this.shoulder_mmt_d_list : this.shoulder_mmt_nd_list
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = category=='shoulder_mmt_d' ? this.getTrace(category_list, month, monthAverageData,  monthIndex) : this.getTrace(category_list, month, monthAverageData, monthIndex, 'shoulder_mmt_nd')
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData, monthIndex, screening_category='shoulder_mmt_d'){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let value = monthData[category]==null ? '' : parseInt(monthData[category])
            let averageValue = monthAverageData[category]==null ? '' : parseInt(monthAverageData[category])
            dataList.push(value)
            averageDataList.push(`평균 : ${averageValue}kg`)
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            var trace = {
                x:dataList,
                text:dataList,
                textposition:'outside',
                y:labelList,
                name : monthData['screening_month'],
                type:'bar',
                orientation:'h',
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                }                    
            };
            if (screening_category=='shoulder_mmt_nd'){
                trace['xaxis']='x2'
                trace['yaxis']='y2'
                trace['showlegend'] = false;
            }

            return trace
        }
        else{
            return null
        }

    };
    getAverageAnnotations(graph_div, screening_category='shoulder_mmt_d'){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        var category_list = screening_category=='shoulder_mmt_d' ? this.shoulder_mmt_d_list : this.shoulder_mmt_nd_list
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of category_list.entries()){
                var calcMonthIndex = category=='shoulder_mmt_d' ? monthIndex : monthIndex+this.chartData['other_months'].length;
                var x0 = graph_div.calcdata[calcMonthIndex][categoryIndex].p0;
                var x1 = graph_div.calcdata[calcMonthIndex][categoryIndex].p1;
                var averageValue = this.chartData['other_month_average'][monthIndex][category];
                var xrefValue = screening_category == 'shoulder_mmt_d' ? 'x' : 'x2'
                var yrefValue = screening_category == 'shoulder_mmt_d' ? 'y' : 'y2'
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        layer:'below',                        
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: averageValue,
                        x1: averageValue,
                        y0: x0,
                        y1: x1,
                        xref: xrefValue,
                        yref: yrefValue,
                        label: {
                            // text: `AVG : ${averageValue}`,
                            size:1,
                            textangle : -360,
                            xanchor:'right',
                            yanchor:'middle'
                        }
                    }   
                    annotation_list.push(annotation)
                }
            }
        }
        return annotation_list
    };
};