import Rails from "@rails/ujs";
import { range } from "lodash";

jQuery(document).on('turbolinks:load', function() {
  let default_scout = 'scout_comment_scout_name'

  $('div[id^="goal_physical_"]').on('click',function(){
  	$('#evaluation_title').val($(this).data('title'))
  	$('#goal_select').slideToggle(300)
  	$('#goal_reselect').slideToggle(300)
  })

  $('div[id^="goal_baseball_"]').on('click',function(){
  	$('#evaluation_title').val($(this).data('title'))
  	$('#goal_select').slideToggle(300)
  	$('#goal_reselect').slideToggle(300)
  })

  $('#goal_reselect').on('click',function(){
  	$('#goal_select').slideToggle(300)
  	$('#goal_reselect').slideToggle(300)
  })

  $('#player_plan_year_select').on('change',function(){
    let yearSelected = Number($('#player_plan_year_select').val());
    let currentYear = new Date().getFullYear();

    for (let year=2020; year<currentYear+1; year++){
      if (year != yearSelected){
        $(`#player_plan_${year}`).hide();
      };
    };
    $(`#player_plan_${yearSelected}`).show();
  })
});