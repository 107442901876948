jQuery(document).on('turbolinks:load', function() {
  $('#scout_trackman_record_input').on('change',() => {
    var names = [];
    for (var i = 0; i < $("#scout_trackman_record_input").get(0).files.length; ++i) {
        names.push($("#scout_trackman_record_input").get(0).files[i].name);
    }

    $("#scout_trackman_record_list").empty();
    for (var i=0; i<names.length; i++){
      $("#scout_trackman_record_list").append(
        "\
          <li class='py-4 flex'>\
            <div class='ml-3'>\
              <p class='text-sm text-gray-500'>"+names[i]+"</p>\
            </div>\
          </li>\
        ");
    }
  });
})