import Rails from "@rails/ujs";
import * as d3 from "d3";
import * as d3Lasso from "d3-lasso";
import { pitchColors } from "./pitch_info";

document.addEventListener("turbolinks:load", () => {
  
    if (document.getElementById("range_chart_8").length!=0){
      document.getElementById("range_chart_8").innerHTML=null
    }

    // Indicator Start
    var svg = d3.select("#range_chart_8")
                .append("svg")
                .attr("height","40")
                .attr("width","120");

    //svg.append("text").text(data['kind']).attr("x",60).attr("y",6).attr("font-size","12px").attr("dominant-baseline","middle").attr("text-anchor","middle")

    // 좌측 작은 점
    svg.append("circle")
        .attr("cx",10)
        .attr("cy",20)
        .attr("r",3)
        .attr("fill",'#BDC3C7');

    // 우측 작은 점
    svg.append("circle")
        .attr("cx",110)
        .attr("cy",20)
        .attr("r",3)
        .attr("fill",'#BDC3C7');

    // 중간 작은 점
    svg.append("circle")
        .attr("cx",60)
        .attr("cy",20)
        .attr("r",3)
        .attr("fill",'#BDC3C7');

    // 선
    svg.append("rect")
        .attr("x",8.5)
        .attr("y",18.5)
        .attr("width",100)
        .attr("height",3)
        .attr("fill",'#BDC3C7');

    svg.append("text").text('Poor').attr("x",0).attr("y",15).attr("font-size","11px").attr("fill","blue");
    svg.append("text").text('Avg').attr("x",50).attr("y",15).attr("font-size","11px");
    svg.append("text").text('Great').attr("x",90).attr("y",15).attr("font-size","11px").attr("fill","red");

    // Indicator End

    function drawChart(data){
      for (const num in data['profile_charts']){
        drawBarChart((num*1+1)+"", data['profile_charts'][num]);
      }
      drawTrendChart(data['speed_trends'],data['speed_league_trends'])
      drawBatterTrendChart(data['batter_trends'], data['batter_trend_avg'], data['batter_trend_min_max'])
    }

    function drawBatterTrendChart(batter_trends, avgs, min_max){
      drawBatterTop(_.groupBy(batter_trends, p=>p.end_date), 'k_rate', 'K_RATE', avgs['k_rate'], min_max['k_rate_min'], min_max['k_rate_max']);
      drawBatterTop(_.groupBy(batter_trends, p=>p.end_date), 'bb_rate', 'BB_RATE', avgs['bb_rate'], min_max['bb_rate_min'], min_max['bb_rate_max']);
      drawBatterTop(_.groupBy(batter_trends, p=>p.end_date), 'xraa', 'XRAA', avgs['xraa'], min_max['xraa_min'], min_max['xraa_max']);
      drawBatterBottom(_.groupBy(batter_trends, p=>p.end_date), 'xdamage', 'XDAMAGE', avgs['xdamage'], min_max['xdamage_min'], min_max['xdamage_max']);
    }

    function drawBatterTop(data_date, data_selected, title, avg, min, max){
      
      checkElement('batter_profile_chart_', title)
      
      var margin = {top:10, right:10, bottom:15, left:10},
      width = 300 - margin.left - margin.right,
      height = 50 - margin.top - margin.bottom

      var svg = d3.select("#batter_profile_chart_"+title)
                  .append("svg")
                  .attr("width", width + margin.left + margin.right)
                  .attr("height", height + margin.top + margin.bottom)
                  .append("g")
                  .attr("transform", "translate(" + margin.left + "," + margin.right + ")");

      var temp = [];
      var date_keys = [];
      var for_get_max = [];
      for (const date in data_date) {
        date_keys.push(d3.timeParse("%Y-%m-%d")(date));
        temp.push({'date':d3.timeParse("%Y-%m-%d")(date), 'value':data_date[date][0][data_selected]})
        for_get_max.push(data_date[date][0][data_selected])
      }

      svg.append("text")
        .attr("x", (width / 2))   
        .attr("y", 0)
        .attr("text-anchor", "middle")  
        .style("font-size", "10px") 
        .text(title);

      var x = d3.scaleTime()
                .domain(d3.extent(date_keys, function(d) { return d; }))
                .range([0, width]);

      var y = d3.scaleLinear()
                .domain([min, max])
                .range([height, 0]);

      var newline = d3.line()
                    .curve(d3.curveBundle.beta(0))
                    .x(d => x(d.date))
                    .y(d => y(d.value));

      var gradientColor = (p) => {
          if(p[0].value<=avg){
            return 'blue'
          }
          else{
            return 'red'
          }
        };

        var newdata = temp.map( (p, index) => index === temp.length - 1 ? [p] : [p, temp[index+1]]);
        
        svg.selectAll('path')
        .data(newdata)
        .enter().append('path')
        .attr('d', p => newline(p))
        .attr('stroke', p => gradientColor(p))
        .attr('stroke-width',1);

        svg.append("path")
            .datum(date_keys)
            .style("stroke-dasharray", ("2, 2"))
            .attr("stroke", 'gray')
            .attr("stroke-width",1)
            .attr("d",d3.line()
                        .x(function(d){return x(d)})
                        .y(function(d){return y(avg)})
                  )

      
    }

    function drawBatterBottom(data_date, data_selected, title, avg, min, max){

      checkElement('batter_profile_chart_', title)

      var margin = {top:10, right:10, bottom:20, left:10},
      width = 300 - margin.left - margin.right,
      height = 56 - margin.top - margin.bottom

      var svg = d3.select("#batter_profile_chart_"+title)
                  .append("svg")
                  .attr("width", width + margin.left + margin.right)
                  .attr("height", height + margin.top + margin.bottom)
                  .append("g")
                  .attr("transform", "translate(" + margin.left + "," + margin.right + ")");

      var temp = [];
      var date_keys = [];
      var for_get_max = [];
      for (const date in data_date) {
        date_keys.push(d3.timeParse("%Y-%m-%d")(date));
        temp.push({'date':d3.timeParse("%Y-%m-%d")(date), 'value':data_date[date][0][data_selected]})
        for_get_max.push(data_date[date][0][data_selected])
      }

      var x = d3.scaleTime()
                .domain(d3.extent(date_keys, function(d) { return d; }))
                .range([0, width]);

      svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(3));

      var y = d3.scaleLinear()
                .domain([min, max])
                .range([height, 0]);

      var newline = d3.line()
                    .curve(d3.curveBundle.beta(0))
                    .x(d => x(d.date))
                    .y(d => y(d.value));
      svg.append("text")
        .attr("x", (width / 2))   
        .attr("y", 0)
        .attr("text-anchor", "middle")  
        .style("font-size", "10px") 
        .text(title);

      var gradientColor = (p) => {
          if(p[0].value<=avg){
            return 'blue'
          }
          else{
            return 'red'
          }
        };

        var newdata = temp.map( (p, index) => index === temp.length - 1 ? [p] : [p, temp[index+1]]);
        
        svg.selectAll('path')
        .data(newdata)
        .enter().append('path')
        .attr('d', p => newline(p))
        .attr('stroke', p => gradientColor(p))
        .attr('stroke-width', 1);

      svg.append("path")
            .datum(date_keys)
            .style("stroke-dasharray", ("2, 2"))
            .attr("stroke", 'gray')
            .attr("stroke-width",1)
            .attr("d",d3.line()
                        .x(function(d){return x(d)})
                        .y(function(d){return y(avg)})
                  )

      svg.append("text")
        .attr("x", x(date_keys[1]))   
        .attr("y", y(avg))
        .attr("text-anchor", "middle")  
        .style("font-size", "8px") 
        .text('LG AVG');

    }

    function drawTrendChart(five_games, league){

      checkElement('pitcher_profile_chart')
      var margin = {top:20, right:20, bottom:20, left:25},
      width = 275 - margin.left - margin.right,
      height = 250 - margin.top - margin.bottom;

      var svg = d3.select("#pitcher_profile_chart")
                  .append("svg")
                  .attr("width", width + margin.left + margin.right)
                  .attr("height", height + margin.top + margin.bottom)
                  .append("g")
                  .attr("transform", "translate(" + margin.left + "," + margin.right + ")");

      var by_pitch_type = _.groupBy(five_games, p => p.pitch_type);
      var by_game_date = _.groupBy(five_games, p => p.game_date);
      var date_keys = [];
      var speed = [];
      for (const sp in _.groupBy(five_games,p=>p.avg_speed)){speed.push(parseFloat(sp));}
      for (const date in by_game_date){date_keys.push(d3.timeParse("%Y-%m-%d")(date));}

      svg.append("text")
        .attr("x", (width / 2))             
        .attr("y", 0)
        .attr("text-anchor", "middle")  
        .style("font-size", "14px") 
        .text("2024 Speed Trends");

      var x = d3.scaleTime()
                .domain(d3.extent(date_keys, function(d) { return d; }))
                .range([0, width]);

      svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(3));

      var y = d3.scaleLinear()
                .domain([Math.min.apply(Math, speed)-2, Math.max.apply(Math, speed)+2])
                .range([height, 0]);
      svg.append("g").call(d3.axisLeft(y).ticks(5));

      for (const pitchType in by_pitch_type){
          var temp_avg_speed_x = []
          for (const each in by_pitch_type[pitchType]){
            temp_avg_speed_x.push(parseFloat(by_pitch_type[pitchType][each]["avg_speed"]))
          }
          const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length

          svg.append("path")
            .datum(date_keys)
            .style("stroke-dasharray", ("3, 3"))
            .attr("stroke", pitchColors[pitchType]["color"])
            .attr("stroke-width",1.5)
            .attr("d",d3.line()
                        .x(function(d){return x(d)})
                        .y(function(d){return y(arrAvg(temp_avg_speed_x))})
                  )

          svg.append("path")
            .datum(by_pitch_type[pitchType])
            .attr("fill","none")
            .attr("stroke", d => pitchColors[pitchType]["color"])
            .attr("stroke-width",1.5)
            .attr("d",d3.line()
                        .curve(d3.curveBundle.beta(0.3))
                        .x(function(d){return x(d3.timeParse("%Y-%m-%d")(d.game_date))})
                        .y(function(d){return y(d.avg_speed)})
                  )
      }

      var Tooltip = d3.select("#pitcher_profile_chart")
                      .append("div")
                      .style("opacity", 0)
                      .style("position", "absolute")
                      .style("background-color", "white")
                      .style("border", "solid")
                      .style("border-width", "2px")
                      .style("border-radius", "5px")
                      .style("padding", "3px")
                      .style("font-size", "10px")

      var mouseover = function(d) {
                        Tooltip
                          .style("opacity", 1)
                        d3.select(this)
                          .style("stroke", "black")
                          .style("opacity", 1)
                      }
      var mousemove = function(d) {
                        Tooltip
                          .html("구종: " + d.pitch_type + " " + "평균구속: " + parseInt(d.avg_speed) + " " + "날짜: " + d.game_date)
                          .style("left", (d3.mouse(document.body)[0]+20) + "px")
                          .style("top", (d3.mouse(document.body)[1]) + "px")
                      }
      var mouseleave = function(d) {
                        Tooltip
                          .style("opacity", 0)
                          .style("top", (d3.mouse(document.body)[1]+500) + "px")

                        d3.select(this)
                          .style("stroke", "none")
                          .style("opacity",0.7)
                          
                      }

      for (const pitchType in by_pitch_type){
          svg.append("g")
              .selectAll("dot")
              .data(by_pitch_type[pitchType])
              .enter()
              .append("circle")
              .attr("cx",function(d){return x(d3.timeParse("%Y-%m-%d")(d.game_date))})
              .attr("cy",function(d){return y(d.avg_speed)})
              .attr("r",2.3)
              .style("fill", d => pitchColors[d.pitch_type]["color"])
              .style("opacity", 0.7)
              .on("mouseover", mouseover)
              .on("mousemove", mousemove)
              .on("mouseleave", mouseleave);
      };
    }

    function drawBarChart(num, data) {

      checkElement('range_chart_', num)
      var svg = d3.select("#range_chart_"+num)
                .append("svg")
                .attr("height","40")
                .attr("width","120");

      svg.append("text").text(data['kind']).attr("x",60).attr("y",6).attr("font-size","12px").attr("dominant-baseline","middle").attr("text-anchor","middle")

      // 좌측 작은 점
      svg.append("circle")
          .attr("cx",10)
          .attr("cy",20)
          .attr("r",3)
          .attr("fill",'#BDC3C7');

      // 우측 작은 점
      svg.append("circle")
          .attr("cx",110)
          .attr("cy",20)
          .attr("r",3)
          .attr("fill",'#BDC3C7');

      // 중간 작은 점
      svg.append("circle")
          .attr("cx",60)
          .attr("cy",20)
          .attr("r",3)
          .attr("fill",'#BDC3C7');

      // 선
      svg.append("rect")
          .attr("x",8.5)
          .attr("y",18.5)
          .attr("width",100)
          .attr("height",3)
          .attr("fill",'#BDC3C7');

      // 상태 표시 원
      svg.append("circle")
          .attr("cx",10 + data['rate']*1)
          .attr("cy",20)
          .attr("r",10)
          .attr("fill",data['color'])
          .attr("stroke","#000000")
          .attr("stroke-width","1");

      // 텍스트
      if(data['rate']*1<10){
        svg.append("text").attr("x",data['rate']*1+7).attr("y",24).text(data['rate'])
        .attr("stroke",data['text_color'])
        .attr("font-size", "10px");
      }
      else{
        svg.append("text").attr("x",data['rate']*1+4).attr("y",24).text(data['rate'])
        .attr("stroke",data['text_color'])
        .attr("font-size", "10px");
      }
      
    }

    function checkElement(id, parameter){
      id = parameter!= undefined ? id + parameter : id
      if (document.getElementById(id)!=null){
        if (document.getElementById(id).length!=0){
          document.getElementById(id).innerHTML=null
        }
      }
    }

    const queryParams = [
      `player_id=${$("#chart_player_id_for_chart").val()}`
    ]
    fetch(`/profile_bar_charts?${queryParams.join("&")}`)
      .then(resp => resp.json())
      .then(data => {drawChart(data);});
  }
)