import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element.querySelector('#pref_united_reports_list'),{
      group: 'shared',
      animation: 200,
      onEnd: this.newReport.bind(this)
    });

    this.sortable_pref = Sortable.create(this.element.querySelector('#pref_united_sortable'),{
      group: 'shared',
      animation: 200,
      onEnd: this.newReport.bind(this)
    });
  }

  newReport(event){
    for (let n of $("#pref_united_reports_list").children()){
      let formdata = new FormData()
      formdata.append("player_id",$(n).data("rpid").toString())
      formdata.append("new_ranking","0")
      $(n).find('span:eq(0)').text($(n).index()+1);
      Rails.ajax({
        url: "/nominates/1/newplace_united",
        type: "PATCH",
        data: formdata
      })
    }
    for (let n of $("#pref_united_sortable").children()){
      let formdata = new FormData()
      formdata.append("player_id",$(n).data("rpid").toString())
      formdata.append("new_ranking",($(n).index()+1).toString())
      console.log(($(n).index()+1).toString())
      $(n).find('span:eq(0)').text($(n).index()+1);
      Rails.ajax({
        url: "/nominates/1/newplace_united",
        type: "PATCH",
        data: formdata
      })
    }
  }
}
