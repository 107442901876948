import * as d3 from "d3";

import { pitchColors } from "./pitch_info";

export default function extension(
  chartContainerId,
  tableContainerId,
  pitches,
  byPitchType,
  percentiles,
  constants,
  byLeageAvgs
) {
  const dimensions = {
    margin: { top: 30, right: 30, bottom: 30, left: 60 },
    width: 460,
    height: 400
  };

  const canonicalPitches = [];
  const leagueAvgPitches = [];
  for (const pitchType in byPitchType) {
    canonicalPitches.push({
      pitch_type: pitchType,
      extension: d3.mean(byPitchType[pitchType].map(d => d.extension)),
      rel_height: d3.mean(byPitchType[pitchType].map(d => d.rel_height)),
      rel_side: d3.mean(byPitchType[pitchType].map(d => d.rel_side)),
      numPitches: byPitchType[pitchType].length
    });

    leagueAvgPitches.push({
      pitch_type: pitchType,
      extension: byLeageAvgs[pitchType][0]['extension'],
      rel_height: byLeageAvgs[pitchType][0]['rel_height'],
      rel_side: byLeageAvgs[pitchType][0]['rel_side'],
      numPitches: 1
    })
  }

  const svg = drawCanvas(chartContainerId, dimensions);
  const svg_2 = drawCanvas(chartContainerId, dimensions);

  const scales = getScales(dimensions);
  const scalesES = getScalesES(dimensions);

  drawAxes(svg, dimensions, scales);
  drawAxesES(svg_2, dimensions, scalesES);

  plotPitches(svg, scales, pitches, false);
  plotPitches(svg, scales, canonicalPitches, true);
  plotAvgs(svg, scales, leagueAvgPitches)

  plotPitchesHS(svg_2, scalesES, pitches, false);
  plotPitchesHS(svg_2, scalesES, canonicalPitches, true);
  plotAvgsHS(svg_2, scalesES, leagueAvgPitches)

  writeLabels(svg, dimensions);
  writeLabelsHS(svg_2, dimensions);

  const tableStats = computeSummaryStats(canonicalPitches, percentiles);
  populateDataTable(tableContainerId, tableStats);
}

function computeSummaryStats(canonicalPitches, percentiles) {
  return _.orderBy(
    canonicalPitches.map(pitch => ({
      pitchType: pitch.pitch_type,
      avg_ext: pitch.extension,
      avg_relh: pitch.rel_height,
      avg_rels: pitch.rel_side,
      num_pitches: pitch.numPitches
    })),
    "num_pitches",
    "desc"
  );
}

function populateDataTable(tableContainerId, summary) {
  const tableCell =
    "px-2 py-2 bg-white text-xs text-right text-gray-500 leading-4 font-medium tracking-wider";
  const colDefinition = [
    {
      label: "Pitch Type",
      html: row => row.pitchType,
      className: row =>
        `${tableCell} text-${row.pitchType}`.replace("text-right", "text-left")
    },
    {
      label: "Extension",
      html: row => d3.format(".2f")(row.avg_ext),
      className: tableCell
    },
    {
      label: "Rel. Height",
      html: row => d3.format(".2f")(row.avg_relh),
      className: tableCell
    },
    {
      label: "Rel. Side",
      html: row => d3.format(".2f")(row.avg_rels),
      className: tableCell
    }
  ];
  const table = d3.select(tableContainerId);

  table
    .select("thead")
    .selectAll("tr")
    .data([""])
    .enter()
    .append("tr")
    .selectAll("th")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("th")
    .html(cell => cell.label)
    .attr("class", cell => cell.className);

  table
    .select("tbody")
    .selectAll("tr")
    .data(summary)
    .enter()
    .append("tr")
    .selectAll("td")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("td")
    .html(cell => cell.html)
    .attr("class", cell => cell.className);
  return;
}

function drawCanvas(containerId, dimensions) {
  // append the svg object to the body of the page
  const { height, width, margin } = dimensions;
  return d3
    .select(containerId)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
}

function getScales(dimensions) {
  const { height, width, margin } = dimensions;

  const xScale = d3
    .scaleLinear()
    .domain([120, 240])
    .range([0, width - margin.left - margin.right]);
    //Extension
  const yScale = d3
    .scaleLinear()
    .domain([120, 220])
    .range([height - margin.top - margin.bottom, 0]);
    //Release Side

  return { x: xScale, y: yScale };
}

function getScalesES(dimensions) {
  const { height, width, margin } = dimensions;

  const xScale = d3
    .scaleLinear()
    .domain([100, -100])
    .range([0, width - margin.left - margin.right]);
    //Extension
  const yScale = d3
    .scaleLinear()
    .domain([120, 220])
    .range([height - margin.top - margin.bottom, 0]);
    //Release Side

  return { x: xScale, y: yScale };
}

function drawAxes(svg, dimensions, scales) {
  const { height, width, margin } = dimensions;

  // Define the axes
  const tickValues = [140, 160, 180, 200, 220];
  const tickValues_y = [130, 150, 170, 190, 210]
  const xAxis = d3.axisBottom(scales.x).tickValues(tickValues);
  const yAxis = d3.axisLeft(scales.y).tickValues(tickValues_y);

  // Plot the x-axis
  const xAxisPlot = svg
    .append("g")
    .attr(
      "transform",
      "translate(0," + (height - margin.top - margin.bottom) + ")"
    )
    .call(xAxis.tickSize(0, 0, 0));

  // Plot the y-axis
  const yAxisPlot = svg.append("g").call(yAxis.tickSize(0, 0, 0));

  // Add the x-axis lines/ticks - black at origin only
  xAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("y1", 0)
    .attr("y2", -height + margin.top + margin.bottom);

  // Add the y-axis lines/ticks - black at origin only
  yAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("x1", 0)
    .attr("x2", width - margin.left - margin.right);
}

function drawAxesES(svg, dimensions, scales) {
  const { height, width, margin } = dimensions;

  // Define the axes
  const tickValues_y = [130, 150, 170, 190, 210];
  const tickValues_x = [80, 40, 0, -40, -80];
  const xAxis = d3.axisBottom(scales.x).tickValues(tickValues_x);
  const yAxis = d3.axisLeft(scales.y).tickValues(tickValues_y);

  // Plot the x-axis
  const xAxisPlot = svg
    .append("g")
    .attr(
      "transform",
      "translate(0," + (height - margin.top - margin.bottom) + ")"
    )
    .call(xAxis.tickSize(0, 0, 0));

  // Plot the y-axis
  const yAxisPlot = svg.append("g").call(yAxis.tickSize(0, 0, 0));

  // Add the x-axis lines/ticks - black at origin only
  xAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("y1", 0)
    .attr("y2", -height + margin.top + margin.bottom);

  // Add the y-axis lines/ticks - black at origin only
  yAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("x1", 0)
    .attr("x2", width - margin.left - margin.right);
}

function plotPitches(svg, scales, pitches, isCanonical) {
  svg
    .append("g")
    .selectAll("dot")
    .data(pitches)
    .enter()
    .append("circle")
    .attr("cx", d => scales.x(d.extension * 100))
    .attr("cy", d => scales.y(d.rel_height * 100))
    .attr("r", isCanonical ? 3.5 : 2.5)
    .attr("opacity", isCanonical ? 0.9 : 0.3)
    .attr("stroke", isCanonical ? "black" : "none")
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function plotAvgs(svg, scales, pitches) {
  svg
    .append("g")
    .selectAll("rect")
    .data(pitches)
    .enter()
    .append("rect")
    .attr("x", d => scales.x(d.extension * 100) - 3.5)
    .attr("y", d => scales.y(d.rel_height * 100) - 3.5)
    .attr("width", 7)
    .attr("height", 7)
    .attr("opacity", 1)
    .attr("stroke", "black")
    .attr("stroke-width",1)
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function writeLabels(svg, dimensions){
  const { height, width, margin } = dimensions;
  svg.append("text")             
      .attr("transform",
            "translate(" + (width*3/4) + " ," + 
                           (height-35) + ")")
      .attr("class","text-sm text-gray-500")
      .style("text-anchor", "middle")
      .text("Extension(cm)");

  svg.append("text")             
      .attr("transform","rotate(-90)")
      .attr("y","-20")
      .attr("x","-40")
      .attr("class","text-sm text-gray-500")
      .style("text-anchor", "middle")
      .text("Release Height(cm)");
}

function plotPitchesHS(svg, scales, pitches, isCanonical) {
  svg
    .append("g")
    .selectAll("dot")
    .data(pitches)
    .enter()
    .append("circle")
    .attr("cx", d => scales.x(d.rel_side * 100))
    .attr("cy", d => scales.y(d.rel_height * 100))
    .attr("r", isCanonical ? 3.5 : 2.5)
    .attr("opacity", isCanonical ? 0.9 : 0.3)
    .attr("stroke", isCanonical ? "black" : "none")
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function plotAvgsHS(svg, scales, pitches) {
  svg
    .append("g")
    .selectAll("rect")
    .data(pitches)
    .enter()
    .append("rect")
    .attr("x", d => scales.x(d.rel_side * 100) - 3.5)
    .attr("y", d => scales.y(d.rel_height * 100) - 3.5)
    .attr("width", 7)
    .attr("height", 7)
    .attr("opacity", 1)
    .attr("stroke", "black")
    .attr("stroke-width",1)
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function writeLabelsHS(svg, dimensions){
  const { height, width, margin } = dimensions;
  svg.append("text")             
      .attr("transform",
            "translate(" + (width*3/4 - 30) + " ," + 
                           (height-35) + ")")
      .attr("class","text-sm text-gray-500")
      .style("text-anchor", "middle")
      .text("Release Side(cm)");

  svg.append("text")             
      .attr("transform","rotate(-90)")
      .attr("y","-20")
      .attr("x","-40")
      .attr("class","text-sm text-gray-500")
      .style("text-anchor", "middle")
      .text("Release Height(cm)");
}