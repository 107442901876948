import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]
  initialize(){
    $(document).ready(function() {
      $('.player-select').select2({
        placeholder: "선수 선택",
        width: '100%',
      });        
    })

  }
  connect() {
    $('#screening_player_form').on('submit',function(event){
      var current_player_name = $('#screening_player_name').val()
      var current_screening_date = $('#screening_screening_date').val()
  
      if (current_player_name=='' | current_screening_date==''){
        if (current_screening_date==''){
          alert('날짜를 선택해주세요.')
        }
        else{
          alert('선수명을 입력해주세요.')
        }
        return false
      }
    })    
  }

  add_body_information(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_BODY_INFORMATION/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove_body_information(event) {
    const wrapper = event.target.closest(".body_information")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    }
    else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }
}

jQuery(document).on(function() {
  let playerListForm = []
  let tick = false
  var ajax_team_player = new XMLHttpRequest();
  ajax_team_player.open("GET", `/lotte_players?`, true)
  ajax_team_player.onload = data => getPlayerListForm(data, ajax_team_player.responseText)
  ajax_team_player.send()

  $(document).on('click','input[id$="player_name"]',function(){
  	if ($(this).parent().attr('class') == 'awesomplete'){}
  	else{
        setDropdownForm($(this).attr('id'))
  	};
  })

  $(document).on('focusout','input[id$="player_name"]',function(){
  	let current_input_id = $(this).attr('id').replace('player_name','')
  	var ajax_player = new XMLHttpRequest();
  	ajax_player.open("GET",`/lotte_players?id=${$(this).val()}`,true);
  	ajax_player.onload = function(data){
  		var player = JSON.parse(ajax_player.responseText)
  		$(`#${current_input_id + 'player_name'}`).val(player['player']['name_kor'])
  		$(`#${current_input_id + 'player_id'}`).val(player['player']['id'])
  	};
  	ajax_player.send();
  })

  function getPlayerListForm(data, response){
    var temp = JSON.parse(response);
    if (!temp.players){
      return;
    }
    temp.players.forEach(p=>{
    	if(p.name_kor != null){
        playerListForm.push([p.name_kor + ' (' + p.back_number + ')',p.player_id]);}
    });
  }

  function setDropdownForm(tag){
  	const input_tag = document.querySelector(`#${tag}`);
  	tick = tag;
    new Awesomplete(input_tag, {
      list: playerListForm,
      minChars: 0
    }); 
  }

})