import * as d3 from "d3";
import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {
  let year = $("#injury_years").val();
  let type = $("#injury_type").val();
  let playerList = []
  let injuryList = []
  let nameplete

  var ajax = new XMLHttpRequest();
  ajax.open("GET", `/lotte_player_indexing?`, true);
  ajax.onload = data => getPlayerList(data, ajax.responseText);
  ajax.send();

  if(type){setDropdown(type);}

  $("#injury_year").on("change", () => {
    year = $("#injury_year").val();

  });

  $("#injury_type").on("change", () => {
    type = $("#injury_type").val();
    nameplete.destroy()
    setDropdown(type);
  });

  function getPlayerList(data, response){
    var temp = JSON.parse(response);
    if (!temp.players){
      return;
    }
    temp.players.forEach(player=>{
        playerList.push([player.name_kor + '(' + player.back_number + ')' ,player.player_id]);
    });
  }

  function setDropdown(listType){
    const input = document.querySelector("#injury_search");
    let dropList = []
    if(listType=='Site'){
      dropList = ['어깨 충돌증후군','어깨 관절와순 손상','어깨 회전근개','어깨 기타 병변','팔꿈치 충돌증후군','팔꿈치 내측 인대 파열','팔꿈치 기타 병변','허리','복사근','햄스트링','내전근','종아리','발목','손목(손가락)','무릎','골반','목','기타']
    }
    else if(listType=='Player'){
      let tempList = []
      for (const [key, value] of playerList){
        if(tempList.includes([key,value])){
        }
        else{
          tempList.push([key,value])
        }
      }
      dropList = tempList;
    };
  nameplete = new Awesomplete(input, {
    list: dropList,
    minChars: 0
  }); 
  }
  
});