import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {

  function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
  }
  
  if (document.getElementById("advanced_scout_edit_div")){

      var timer2 = "9:59";
      var interval = setInterval(function() {
        var timer = timer2.split(':');
        //by parsing integer, I avoid all extra string processing
        var minutes = parseInt(timer[0], 10);
        var seconds = parseInt(timer[1], 10);
        --seconds;
        minutes = (seconds < 0) ? --minutes : minutes;
        seconds = (seconds < 0) ? 59 : seconds;
        seconds = (seconds < 10) ? '0' + seconds : seconds;
        //minutes = (minutes < 10) ?  minutes : minutes;
        $('#autosave_time').html(minutes);
        $('#autosave_second').html(seconds)
        if (minutes < 0) clearInterval(interval);
        //check if both minutes and seconds are 0
        if ((seconds <= 0) && (minutes <= 0)) clearInterval(interval);
        timer2 = minutes + ':' + seconds;
      }, 1000);

      $('html, body').animate({
        scrollTop: localStorage.getItem("last_location")
      }, 1000)
      setInterval(function(){
        $('#advanced_scout_edit_div').addClass("bg-gray-50 opacity-25 z-10")
      },597000)
      
      setInterval(function(){
        $("#advanced_scouting_report_params").submit()
      },600000)

      $(window).scroll(function (event) {
        localStorage.setItem("last_location", $(window).scrollTop());
      });

      setTimeout(function() {
          $('#save_success').fadeOut('fast');
        }, 4000);
      }

/*
60000 -> 1분
*/
});