import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]
  
  initialize(){
    $('.player-select').select2({
        width: '100%',
        ajax: {
          url: '/scouts/makeup_reports/get_amateur_players',
          dataType: 'json',
          delay: 250,
          data: function (params) {
            var query = {
                search: params.term
            }
            return query;
          },
          processResults: function (data) {
            return {
              results: data.players,
            };
          },
          cache: true
        },
    });
    $('.middle-team-select').select2({
      width: '100%',
    });
    $('.high-team-select').select2({
      width: '100%',
    });
    $('.univ-team-select').select2({
      width: '100%',
    });
    $('.position-select').select2({
      width: '100%',
    });
    $('.future-position-select').select2({
      width: '100%',
    });
    $('.makeup-content').each(function(){
      //scroll height가 client height의 길이를 뛰어넘는 지점에서
      // if (elem.clientHeight < elem.scrollHeight)
      if(this.clientHeight < this.scrollHeight){
        $(this).height(this.scrollHeight)
      }
    })
  }
  
  connect() {
    $(window).on('resize', function(){
      $('.makeup-content').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
    });
    $('#makeup_report_form').on('submit',function(event){
      var current_player = $('#tendency_report_tendency_player_attributes_player_id').val()
      if (current_player==""){
        alert('선수의 이름을 입력해주세요.')
        return false
      }
    });
    $('.player-select').on("select2:select", function(event)  {
      var player_id = $(event.target).find(':selected').val();
      $('#tendency_report_player_id').val(player_id);
    })
  }

  setHeight(event){
    const wrapper = event.target
    wrapper.style.height = 'auto'
    wrapper.style.height=wrapper.scrollHeight+"px"
  }

  setPlayerId(event){
    var player_id = $('.player-select').val()
    $('#tendency_report_player_id').val(player_id)
  }
}