$(document).on('ready', function() {
    $('.team-select').select2({
        dropdownAutoWidth:true
    });
})

import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {

    show_game_record_sector(event){
        var button_batter_review = document.getElementById('button_sector_game_record');
        var button_pitcher_review = document.getElementById('button_sector_pitcher_speed');

        if ($('#button_sector_game_record').hasClass('bg-black text-white')==false){
        button_batter_review.classList.replace('bg-white', 'bg-black');
        button_batter_review.classList.replace('text-black', 'text-white');
        }
        button_pitcher_review.classList.replace('bg-black', 'bg-white');
        button_pitcher_review.classList.replace('text-white', 'text-black');

        var sector_batter_review = document.getElementById('sector_game_record');
        sector_batter_review.style.display = 'block';
        var sector_pitcher_review = document.getElementById('sector_pitcher_speed');
        sector_pitcher_review.style.display = 'none';    
    }

  show_pitcher_speed_sector(event){
    var button_batter_review = document.getElementById('button_sector_game_record');
    var button_pitcher_review = document.getElementById('button_sector_pitcher_speed');
    if ($('#button_sector_pitcher_speed').hasClass('bg-black text-white')==false){
      button_pitcher_review.classList.replace('bg-white', 'bg-black');
      button_pitcher_review.classList.replace('text-black', 'text-white');
    }
    button_batter_review.classList.replace('bg-black', 'bg-white');
    button_batter_review.classList.replace('text-white', 'text-black');
    
    var sector_batter_review = document.getElementById('sector_game_record');
    sector_batter_review.style.display = 'none';
    var sector_pitcher_review = document.getElementById('sector_pitcher_speed');
    sector_pitcher_review.style.display = 'block';
  }
}