import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class yBalance{
    
    // init
    constructor(chartData, div_id){
        this.chartData = chartData;
        this.y_balance_list=[
            'y_balance_left_front',
            'y_balance_right_front', 
            'y_balance_left_side', 
            'y_balance_right_side', 
            'y_balance_left_cross', 
            'y_balance_right_cross'
        ]
        this.height = this.chartData['player_info']['height']

        this.label_y_balance_list=[
            // 'y_balance_left_front': 'Front(L)',
            '앞방향(왼)',
            // 'y_balance_right_front': 'Front(R)',  
            '앞방향(오)',    
            // 'y_balance_left_side': 'Side(L)',
            '옆방향(왼)',
            // 'y_balance_right_side': 'Side(R)',
            '옆방향(오)',
            // 'y_balance_left_cross': 'Cross(L)',
            '교차방향(왼)',
            // 'y_balance_right_cross': 'Cross(R)',
            '교차방향(오)',
        ]
        this.max_value_list=[
            (this.chartData['screening_max_value']['y_balance_left_front']/this.height)*100,
            (this.chartData['screening_max_value']['y_balance_right_front']/this.height)*100,
            (this.chartData['screening_max_value']['y_balance_left_side']/this.height)*100,
            (this.chartData['screening_max_value']['y_balance_right_side']/this.height)*100,
            (this.chartData['screening_max_value']['y_balance_left_cross']/this.height)*100,
            (this.chartData['screening_max_value']['y_balance_right_cross']/this.height)*100,
        ]
        this.unit_annotations={
            xref: 'paper',
            yref: 'paper',
            x: 0.02,
            xanchor: 'right',
            y: 1.04,
            yanchor: 'bottom',
            text: '단위 : [%]',
            showarrow: false,
            font:{
                size:12
            }
        }
        this.div_id=div_id
    };

    //function
    drawChart(){
        var layout = {
            barmode: 'group',
            yaxis:{
                fixedrange:true,
                ticksuffix: '%',
                range: [0, 100],
                type: 'linear'
            },
            xaxis:{  
                fixedrange:true,
                min:0,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },
            automargin:true,
            autosize:true,
            annotations:[
                // this.unit_annotations
            ],
        }
        var data_list = this.getDataset()

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }
        
        Plotly.newPlot('result_y_balances', data_list, layout, config );
        var graphDiv = document.getElementById('result_y_balances')
        var annotationList = this.getAverageAnnotations(graphDiv)
        // graphDiv.layout['shapes']=annotationList
        Plotly.relayout(graphDiv,{
            'shapes':annotationList
        });
    };

    getDataset(){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = this.y_balance_list
        for(let [ monthIndex, monthData ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = this.getTrace(category_list, monthData, monthAverageData, monthIndex)
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData, monthIndex){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let averageValue = monthAverageData[category]==null ? '' : parseInt(monthAverageData[category])
            let value = monthData[category]==null ? '' : parseInt(parseFloat(monthData[category]/this.height)*100)
            dataList.push(value)
            averageDataList.push(`평균 : ${averageValue}%`)
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                name : monthData['screening_month'],
                type:'bar',
                legendgroup:monthData['screening_month'],
                hovertext: averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };

            return trace
        }
        else{
            return null
        }

    };
    getAverageAnnotations(graph_div){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of this.y_balance_list.entries()){
                var x0 = graph_div.calcdata[monthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[monthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        layer:'below',                        
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            // text: `AVG : ${averageValue}`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    annotation_list.push(annotation)
                }

            }
        }
        return annotation_list
    }
};