import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class pushUp{
    
    // init
    constructor(chartData){
        this.chartData = chartData;
        this.forceList=[
            'force' 
        ]
        this.rfdList=[            
            'rfd'
        ]
        // this.unit_annotations=[{
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.09,
        //     xanchor: 'right',
        //     y: 1.05,
        //     yanchor: 'bottom',
        //     text: '단위 : [%BW]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // },
        // {
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.90,
        //     xanchor: 'left',
        //     y: 1.05,
        //     yanchor: 'bottom',
        //     text: '단위 : [%BW/s]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // }]
        this.rankCounts = this.chartData['player_info']['position_number']==1 ? this.chartData['screening_rank_counts']['screening_pitcher_counts'] : this.chartData['screening_rank_counts']['screening_fielder_counts']
        this.shapeList = [];
        this.rankMonthDict={};
        this.playerRankMonthDict={};
        this.getRankList()        
    };

    //function
    drawChart(){
        var force_max_value = this.chartData['screening_max_value']['force']*1.2;
        var rfd_max_value = this.chartData['screening_max_value']['rfd']*1.2;
        var layout = {
            barmode: 'group',
            grid:{
                rows:1,
                columns:2,
                pattern:'independent'
            },
            xaxis:{  
                fixedrange:true,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            xaxis2:{  
                fixedrange:true,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            yaxis:{
                fixedrange:true,    
                ticksuffix: '%BW',          
                range: [0.0, force_max_value],
                type: 'linear',
                minallowed:0,
                tickfont:{
                    size:10
                }
            },
            yaxis2:{
                fixedrange:true,
                ticksuffix: '%BW/s',
                range: [0.0, rfd_max_value],
                type: 'linear',
                side: 'right',
                tickfont:{
                    size:10
                }
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },
            annotations:[],
        }
        var force_list = this.getDataset('force')
        var rfd_list = this.getDataset('rfd')
        const dataList = force_list.concat(rfd_list);

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }
        Plotly.newPlot('result_push_up', dataList, layout, config );
        var graphDiv = document.getElementById('result_push_up')
        var forceAnnotationList = this.getAverageAnnotations(graphDiv);
        var rfdAnnotationList = this.getAverageAnnotations(graphDiv,"rfd");
        var forceShapeList = this.getRankShapes(graphDiv);
        var rfdShapeList = this.getRankShapes(graphDiv,"rfd");

        this.shapeList = this.shapeList.concat(forceAnnotationList, rfdAnnotationList, forceShapeList, rfdShapeList);
        graphDiv.layout['shapes']=this.shapeList
        Plotly.redraw(graphDiv);

    };

    getDataset(category){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = category=='force' ? this.forceList : this.rfdList
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = category=='force' ? this.getTrace(category_list, month, monthAverageData, monthIndex) : this.getTrace(category_list, month, monthAverageData, monthIndex, 'rfd')
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData, monthIndex, screening_category='force'){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        var column = screening_category=='force' ? 0 : 1
        for (let category of category_list){
            let value = monthData[screening_category]==null ? '' : parseFloat(monthData[screening_category]).toFixed(2)
            dataList.push(value)
            let averageValue = monthAverageData[category]==null ? '' : parseFloat(monthAverageData[category]).toFixed(2)
            let averageText = screening_category=='force' ? `평균 : ${averageValue}%BW` : `평균 : ${averageValue}%BW/s`
            averageDataList.push(averageText)
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                domain:{
                    row:0,
                    column:column
                },
                name : monthData['screening_month'],
                type:'bar',
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };
            if (screening_category=='rfd'){
                trace['yaxis']='y2'
                trace['xaxis']='x2'
                trace['showlegend'] = false;
            }
            return trace
        }
        else{
            return null
        }
    };

    getAverageAnnotations(graph_div, screening_category='force'){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        var category_list = screening_category=='force' ? this.forceList : this.rfdList
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of category_list.entries()){
                var calcMonthIndex = screening_category=='force' ? monthIndex : monthIndex+this.chartData['other_months'].length;
                var x0 = graph_div.calcdata[calcMonthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[calcMonthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                var legendMonth = this.chartData['other_month_average'][monthIndex]['screening_month']
                var xrefValue = screening_category == 'force' ? 'x' : 'x2'
                var yrefValue = screening_category == 'force' ? 'y' : 'y2'
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        layer: 'below',
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: xrefValue,
                        yref: yrefValue,
                        label: {
                            // text: `AVG:${averageValue}`,
                            font:{
                                size:10
                            }
                        },
                        legendgroup:legendMonth
                    }   
                    annotation_list.push(annotation)
                }
            }
        }
        return annotation_list
    }

    getRankList(){
        for (let month of this.rankCounts) {
            var screeningMonth = month['screening_month']
            this.rankMonthDict[screeningMonth] = month
        }
        for (let month of this.chartData['player_rank']){
            var screeningMonth = month['screening_month']
            this.playerRankMonthDict[screeningMonth] = month
        }
    }

    getRankShapes(graphDiv,screening_category='force'){
        var shapeList = [];
        var category_list = screening_category=='force' ? this.forceList : this.rfdList
        var rankMonths = Object.keys(this.rankMonthDict);
        var playerRankMonths = Object.keys(this.playerRankMonthDict);
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var screeningMonth = graphDiv.calcdata[monthIndex][0]['trace']['name']
            var calcMonthIndex = screening_category=='force' ? monthIndex : monthIndex+this.chartData['other_months'].length;
            var xref = screening_category=='force' ? 'x' : 'x2'
            var yref = screening_category=='force' ? 'y' : 'y2'
            if (rankMonths.includes(screeningMonth) & playerRankMonths.includes(screeningMonth)){
                for (let [categoryIndex, category] of category_list.entries()){
                    var x0 = graphDiv.calcdata[calcMonthIndex][categoryIndex].p0+0.01;
                    var x1 = graphDiv.calcdata[calcMonthIndex][categoryIndex].p1-0.01;
                    var screeningCount = this.rankMonthDict[screeningMonth][category]
                    var playerRank = this.playerRankMonthDict[screeningMonth][category]==null ? '' : this.playerRankMonthDict[screeningMonth][category]
                    const shape = {
                        type:'line',                
                        layer:'above',
                        line:{
                            width:0,
                            color: 'rbga(0,0,0,1)'
                        },
                        opacity: 1,
                        x0: x0,
                        x1: x1,
                        y0: 0,
                        y1: 0,
                        xref: xref,
                        yref: yref,
                        label: {
                            text: `(${playerRank}/${screeningCount})`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    shapeList.push(shape)
                }
            }
        }
        return shapeList
    }

};