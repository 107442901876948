import * as d3 from "d3";

export const pitchTypes = [
  "ChangeUp",
  "Curveball",
  "Cutter",
  "Fastball",
  "Knuckleball",
  "Other",
  "Sinker",
  "Slider",
  "Splitter",
  "Sweeper",
  "Undefined"
];

export const pitchColors = {
  changeup: { color: d3.color("rgb(147, 115, 216)") },
  curveball: { color: d3.color("rgb(75, 205, 252)") },
  cutter: { color: d3.color("rgb(245, 91, 31)") },
  fastball: { color: d3.color("rgb(176, 36, 40)") },
  knuckleball: { color: d3.color("#808080") },
  other: { color: d3.color("#000000") },
  sinker: { color: d3.color("rgb(252, 51, 57)") },
  slider: { color: d3.color("rgb(68, 109, 222)") },
  splitter: { color: d3.color("rgb(135, 19, 141)") },
  sweeper: { color: d3.color("#194066") },
  undefined: { color: d3.color("#000000") }
};
