import Rails from "@rails/ujs";

jQuery(document).on("turbolinks:load", function() {
  $("#league").on("change", () => {
    let selectedLeague = $("#league").val();
    if (selectedLeague[0] == "1") {
      selectedLeague = "first";
    } else if (selectedLeague[0] == "2") {
      selectedLeague = "second";
    } else {
      selectedLeague = "recovery";
    }

    var ajax = new XMLHttpRequest();
    let queryParams = [];
    if (selectedLeague != "") {
      queryParams.push(`league=${selectedLeague}`);
    }
    ajax.open("GET", `/yesterday_report?${queryParams.join("&")}`, true);
    ajax.onload = data => populateInjuryData(data, ajax.responseText);
    ajax.send();
  });

  $('#injury_form').on('submit',function(){
    var is_return = true;
    var current_input_date = $('#injury_date').val()
    var current_input_league = $('#league').val()
    var current_input_location = $('#location').val()

    if (current_input_date=="" | current_input_league=="" | current_input_location==""){
        if (current_input_date==""){
          alert('날짜를 선택해주세요.')
        }
        else if(current_input_league==""){
          alert('리그를 입력해주세요.')
        }
        else{
          alert('장소를 입력해주세요.')
        }        
        is_return = false
    }else{
      $('.injury-contents').each(function(index){
        
        var current_injury_player = $('.injury-player').eq(index).val()
        var current_injury_date = $('.injury-date').eq(index).val()
        var current_injury_position = $('.injury-position').eq(index).val()
        
        if (current_injury_player=="" | current_injury_date=="" | current_injury_position==""){
          if (current_injury_player==""){
            alert(`${index+1}번 선수의 이름을 입력해주세요.`)
          }
          else if(current_injury_date==""){
            alert(`${index+1}번 선수의 부상날짜를 확인해주세요.`)
          }
          else{
            alert(`${index+1}번 선수의 포지션을 확인해주세요.`)
          }
          is_return=false
        }
      });
    }
    return is_return
  })
});




const populateInjuryData = (data, response) => {
  var reportData = JSON.parse(response);
  // Don't do anything if there is no report
  if (!reportData.yesterday_report) {
    return;
  }

  // Don't do anything if there are no players
  const players = reportData.yesterday_report.injury_players;
  if (players.length === 0) {
    return;
  }

  // Remove default empty template -- it will be replaced in loop below
  $(".injurys").remove();

  const linkTarget = $("#injury-links")[0];
  var injury_count = 10
  // Loop through all the players and fill in the form data
  $('#location').val(reportData.yesterday_report.location)
  reportData.yesterday_report.injury_players.forEach(player => {
    //const now = new Date().getTime();
    const idTag = `injury_report_injury_players_attributes_${String(injury_count)}`;
    const content = $("#injury-template-target")[0].innerHTML.replace(
      /NEW_INJURY/g,
      String(injury_count)
    );
    linkTarget.insertAdjacentHTML("beforebegin", content);

    $(`#${idTag}_position`).val(player.position);
    $(`#${idTag}_player_name`).val(player.player_name);
    $(`#${idTag}_player_id`).val(player.player_id);
    $(`#${idTag}_injury_date`).val(player.injury_date);
    $(`#${idTag}_injury_field`).val(player.injury_field);
    $(`#${idTag}_injury_field_explain`).val(player.injury_field_explain);
    $(`#${idTag}_current_state`).val(player.current_state);
    $(`#${idTag}_sore_before`).val(player.sore_before);
    $(`#${idTag}_sore_after`).val(player.sore_after);
    $(`#${idTag}_training_batting`).val(player.training_batting);
    $(`#${idTag}_training_defense`).val(player.training_defense);
    $(`#${idTag}_training_catchball`).val(player.training_catchball);
    $(`#${idTag}_training_bullpen`).val(player.training_bullpen);
    $(`#${idTag}_training_running`).val(player.training_running);
    $(`#${idTag}_training_etc`).val(player.training_etc);
    injury_count = injury_count + 1
  });
};