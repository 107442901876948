import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
  $('#search_kind').on('change',() => {
    let value = $('#search_kind').val()
    if(value == "PLAYER"){
      $('#coach').hide()
      $('#player').show()
    }
    else{
      $('#player').hide()
      $('#coach').show()
    }
  })

  $('p[id^="acquisition_"]').on('click',function(){
    $('#'+$(this).attr('id')+'_modal').fadeIn();
  })

  $('button[id^="acquisition_modal_close"').on('click',function(){
    console.log($(this).parent().parent().attr('id'))
    $('#'+$(this).parent().parent().attr('id')).fadeOut();
  })
});