import * as d3 from "d3";

import { pitchColors } from "./pitch_info";

export default function movement(
  chartContainerId,
  tableContainerId,
  pitches,
  byPitchType,
  percentiles,
  constants,
  byLeageAvgs
) {
  const dimensions = {
    margin: { top: 10, right: 30, bottom: 30, left: 60 },
    width: 560,
    height: 500
  };

  const canonicalPitches = [];
  const leagueAvgPitches = [];
  for (const pitchType in byPitchType) {
    canonicalPitches.push({
      pitch_type: pitchType,
      horz_break: d3.mean(byPitchType[pitchType].map(d => d.horz_break)),
      induced_vert_break: d3.mean(
        byPitchType[pitchType].map(d => d.induced_vert_break)
      ),
      numPitches: byPitchType[pitchType].length
    });
    leagueAvgPitches.push({
      pitch_type: pitchType,
      horz_break: byLeageAvgs[pitchType][0]['horz_break'],
      induced_vert_break: byLeageAvgs[pitchType][0]['vert_break'],
      numPitches: 1
    })
  }

  const svg = drawCanvas(chartContainerId, dimensions);
  const scales = getScales(dimensions);
  drawAxes(svg, dimensions, scales);

  plotPitches(svg, scales, pitches, false);
  plotPitches(svg, scales, canonicalPitches, true);
  plotAvgs(svg, scales, leagueAvgPitches);

  const tableStats = computeSummaryStats(canonicalPitches, percentiles);
  populateDataTable(tableContainerId, tableStats);
}

function computeSummaryStats(canonicalPitches, percentiles) {
  return _.orderBy(
    canonicalPitches.map(pitch => ({
      pitchType: pitch.pitch_type,
      h_mov: -1 * pitch.horz_break,
      v_mov: pitch.induced_vert_break,
      h_mov_percentile: _.find(percentiles, [
        "metric",
        `hmov_${pitch.pitch_type}`
      ])?.percentile,
      v_mov_percentile: _.find(percentiles, [
        "metric",
        `vmov_${pitch.pitch_type}`
      ])?.percentile,
      num_pitches: pitch.numPitches
    })),
    "num_pitches",
    "desc"
  );
}

function populateDataTable(tableContainerId, summary) {
  const tableCell =
    "px-2 py-2 bg-gray-50 text-xs text-right text-gray-500 leading-4 font-medium tracking-wider";
  const colDefinition = [
    {
      label: "Pitch Type",
      html: row => row.pitchType,
      className: row =>
        `${tableCell} text-${row.pitchType}`.replace("text-right", "text-left")
    },
    {
      label: "Vert. Mov.",
      html: row => d3.format(".2f")(row.v_mov),
      className: tableCell
    },
    {
      label: "Horz. Mov.",
      html: row => d3.format(".2f")(row.h_mov),
      className: tableCell
    },
    {
      label: "Vert. Mov. %ile",
      html: row =>
        row.v_mov_percentile === undefined
          ? "--"
          : d3.format(".0%")(row.v_mov_percentile),
      className: tableCell
    },
    {
      label: "Horz. Mov. %ile",
      html: row =>
        row.h_mov_percentile === undefined
          ? "--"
          : d3.format(".0%")(row.h_mov_percentile),
      className: tableCell
    }
  ];
  const table = d3.select(tableContainerId);

  table
    .select("thead")
    .selectAll("tr")
    .data([""])
    .enter()
    .append("tr")
    .selectAll("th")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("th")
    .html(cell => cell.label)
    .attr("class", cell => cell.className);

  table
    .select("tbody")
    .selectAll("tr")
    .data(summary)
    .enter()
    .append("tr")
    .selectAll("td")
    .data((row, i) => {
      return colDefinition.map(col => {
        const cell = {};
        for (const c in col) {
          cell[c] = typeof col[c] === "function" ? col[c](row, i) : col[c];
        }
        return cell;
      });
    })
    .enter()
    .append("td")
    .html(cell => cell.html)
    .attr("class", cell => cell.className);
  return;
}

function drawCanvas(containerId, dimensions) {
  // append the svg object to the body of the page
  const { height, width, margin } = dimensions;
  return d3
    .select(containerId)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
}

function getScales(dimensions) {
  const { height, width, margin } = dimensions;

  const xScale = d3
    .scaleLinear()
    .domain([-65, 65])
    .range([0, width - margin.left - margin.right]);
  const yScale = d3
    .scaleLinear()
    .domain([-65, 65])
    .range([height - margin.top - margin.bottom, 0]);

  return { x: xScale, y: yScale };
}

function drawAxes(svg, dimensions, scales) {
  const { height, width, margin } = dimensions;

  // Define the axes
  const tickValues = [-50, -25, 0, 25, 50];
  const xAxis = d3.axisBottom(scales.x).tickValues(tickValues);
  const yAxis = d3.axisLeft(scales.y).tickValues(tickValues);

  // Plot the x-axis
  const xAxisPlot = svg
    .append("g")
    .attr(
      "transform",
      "translate(0," + (height - margin.top - margin.bottom) + ")"
    )
    .call(xAxis.tickSize(0, 0, 0));

  // Plot the y-axis
  const yAxisPlot = svg.append("g").call(yAxis.tickSize(0, 0, 0));

  // Add the x-axis lines/ticks - black at origin only
  xAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("y1", 0)
    .attr("y2", -height + margin.top + margin.bottom);

  // Add the y-axis lines/ticks - black at origin only
  yAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("x1", 0)
    .attr("x2", width - margin.left - margin.right);
}

function plotPitches(svg, scales, pitches, isCanonical) {
  svg
    .append("g")
    .selectAll("dot")
    .data(pitches)
    .enter()
    .append("circle")
    .attr("cx", d => scales.x(-1 * d.horz_break))
    .attr("cy", d => scales.y(d.induced_vert_break))
    .attr("r", isCanonical ? 3.5 : 2.5)
    .attr("opacity", isCanonical ? 0.8 : 0.6)
    .attr("stroke", isCanonical ? "black" : "none")
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function plotAvgs(svg, scales, pitches) {
  svg
    .append("g")
    .selectAll("rect")
    .data(pitches)
    .enter()
    .append("rect")
    .attr("x", d => scales.x(-1 * d.horz_break) - 3.5)
    .attr("y", d => scales.y(d.induced_vert_break) - 3.5)
    .attr("width", 7)
    .attr("height", 7)
    .attr("opacity", 1)
    .attr("stroke", "black")
    .attr("stroke-width",1)
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}
