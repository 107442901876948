import { Controller } from "stimulus"

export default class extends Controller {

    initialize(){
        $('.player-select').select2({
            width: '100%',
            ajax: {
                url: '/scouts/international_scout_comments/get_international_players',
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    var query = {
                        search: params.term
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                    results: data.players,
                    };
                },
                cache: true
            },
        });
        $('.year-select').select2({
            width: '100%',
        });
        $('.international-scout-comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
    }
    connect() { 
        $(window).on('resize', function(){
            $('.international-scout-comment').each(function(){
                $(this).css('height', 'auto' );
                $(this).height(this.scrollHeight)
            })
        });
    }
    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
      }
}