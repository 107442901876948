import Rails from "@rails/ujs";
import videojs from "video.js";
import videojsPlaylistPlugin from "videojs-playlist";

jQuery(document).on('turbolinks:load', function() {
	
	let player;
	var clicked_video;
	player = videojs("pr-vjs", {
	    controls: true,
	    autoplay: true,
	    preload: "auto"
	  });
	$('#pr_whole')
    .css('opacity', 0)  
    .animate({opacity: 1}, {queue: false, duration: 2000});

	$("#pr_pre_workout_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_pre_workout_content").slideToggle(800)
		$("#pr_pre_workout_done").addClass('hidden')
		$("#pr_pre_workout_retry").toggleClass('hidden',[2000])
	})

	$("#pr_pre_workout_retry").on('click',function(){
		$("#pr_pre_workout_content").slideToggle(800)
	})

	$("#pr_sixty_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_sixty_done_check").toggleClass('hidden',[2000])
		$("#pr_sixty_done").addClass('hidden')
	})

	$("#pr_athleticthrow_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_athleticthrow_done_check").toggleClass('hidden',[2000])
		$("#pr_athleticthrow_done").addClass('hidden')
	})

	$("#pr_seventytoeightyfive_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_seventytoeightyfive_done_check").toggleClass('hidden',[2000])
		$("#pr_seventytoeightyfive_done").addClass('hidden')
	})

	$("#pr_eightytoninety_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_eightytoninety_done_check").toggleClass('hidden',[2000])
		$("#pr_eightytoninety_done").addClass('hidden')
	})

	$("#pr_ninety_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_ninety_done_check").toggleClass('hidden',[2000])
		$("#pr_ninety_done").addClass('hidden')
	})

	$("#pr_ninetytoninetyfive_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_ninetytoninetyfive_done_check").toggleClass('hidden',[2000])
		$("#pr_ninetytoninetyfive_done").addClass('hidden')
	})

	$("#pr_ninetytohundred_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_ninetytohundred_done_check").toggleClass('hidden',[2000])
		$("#pr_ninetytohundred_done").addClass('hidden')
	})

	$("#pr_bullpens_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_bullpens_done_check").toggleClass('hidden',[2000])
		$("#pr_bullpens_done").addClass('hidden')
	})

	$("#pr_pull_downs_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_pull_downs_done_check").toggleClass('hidden',[2000])
		$("#pr_pull_downs_done").addClass('hidden')
	})

	$("#pr_arm_care_done").on('click',function(){
		press_done($(this).data('uid'),$(this).data('date'),$(this).data('kind'))
		$("#pr_arm_care_done_check").toggleClass('hidden',[2000])
		$("#pr_arm_care_done").addClass('hidden')
	})

	$("#pr_recovery_button").on('click',function(){
		if($("#pr_video").is(":visible")){}
		else{
			$("#pr_video").slideToggle(1000)
			$("#pr_stop").slideToggle(1000)
		}
		player.src({
			src: $(this).data('vurl'),
			type: "video/mp4"
		})
	})

	$("#pr_bday_button").on('click',function(){
		if($("#pr_video").is(":visible")){}
		else{
			$("#pr_video").slideToggle(1000)
			$("#pr_stop").slideToggle(1000)
		}
		player.src({
			src: $(this).data('vurl'),
			type: "video/mp4"
		})
	})

	$("#pr_athrow_button").on('click',function(){
		if($("#pr_video").is(":visible")){}
		else{
			$("#pr_video").slideToggle(1000)
			$("#pr_stop").slideToggle(1000)
		}
		player.src({
			src: $(this).data('vurl'),
			type: "video/mp4"
		})
	})

	$("#pr_scbo_button").on('click',function(){
		if($("#pr_video").is(":visible")){}
		else{
			$("#pr_video").slideToggle(1000)
			$("#pr_stop").slideToggle(1000)
		}
		player.src({
			src: $(this).data('vurl'),
			type: "video/mp4"
		})
	})

	$("#pr_rc_button").on('click',function(){
		if($("#pr_video").is(":visible")){}
		else{
			$("#pr_video").slideToggle(1000)
			$("#pr_stop").slideToggle(1000)
		}
		player.src({
			src: $(this).data('vurl'),
			type: "video/mp4"
		})
	})

	$("#pr_stop").on('click',function(){
		player.pause();
		$("#pr_video").slideToggle(1000)
		$("#pr_stop").slideToggle(1000)
	})

	$(document).on('click','div[id$="pr_terms_button"]',function(){
		document.getElementById('pr_video_ask').style.display= 'none' ;
		document.getElementById('pr_feet_meter').style.display= 'none' ;
		document.getElementById('pr_contact_info').style.display= 'none' ;
		$("#pr_terms").show()
	})

	$(document).on('click','div[id$="pr_video_ask_button"]',function(){
		document.getElementById('pr_terms').style.display= 'none' ;
		document.getElementById('pr_feet_meter').style.display= 'none' ;
		document.getElementById('pr_contact_info').style.display= 'none' ;
		$("#pr_video_ask").show()
	})

	$(document).on('click','div[id$="pr_contact_info_button"]',function(){
		document.getElementById('pr_video_ask').style.display= 'none' ;
		document.getElementById('pr_feet_meter').style.display= 'none' ;
		document.getElementById('pr_terms').style.display= 'none' ;
		$("#pr_contact_info").show()
	})

	$(document).on('click','div[id$="pr_feet_meter_button"]',function(){
		document.getElementById('pr_video_ask').style.display= 'none' ;
		document.getElementById('pr_terms').style.display= 'none' ;
		document.getElementById('pr_contact_info').style.display= 'none' ;
		$("#pr_feet_meter").show()
	})

	$(document).on('click','div[id$="video_not_seen"]',function(){
		var element = document.querySelector("#pr_video_ask_button");
		element.scrollIntoView({ behavior: 'smooth', block: 'end'});
	})

	function press_done(user_id, date_of_press, kind){
		let url = `pitcher_recommendations/press_done?user_id=${user_id}&date=${date_of_press}&kind=${kind}`
		Rails.ajax({
			url: url,
			data: $.param({ user_id: user_id }),
			type: 'PATCH'
		})
	}
})