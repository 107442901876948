// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  initialize(){
    $(document).ready(function() {
      $('.player-select').select2({
        width: '100%',
      });
    })
    $(document).ready(function() {
      $('.team-select').select2({
        width: '100%',
      });
    })
    $(document).ready(function() {
      $('.location-select').select2({
        width: '100%',
      });
    })    
  }

  // connect() {

  //   $('.team-select').on("select2:select", function(event)  {
  //     const wrapper = event.target.closest(".comments");
  //     var team_id = $(event.target).find(':selected').val();
  //     const queryParams = [`team_id=${team_id}`];
  //     fetch(`/get_amateur_players?${queryParams.join("&")}`)
  //     .then(resp => resp.json())
  //     .then(data => {
  //       setPlayerOptions(wrapper, data);
  //     })
  //   })

  // }


  connect() {}

  // getAmaPlayer(event){
  //   const wrapper = event.target.closest(".comments");
  //   var team_id = $(event.target).find(':selected').val();
  //   fetchData(team_id, event);    
  //   const queryParams = [`team_id=${team_id}`];
  //   fetch(`/get_amateur_players?${queryParams.join("&")}`)
  //     .then(resp => resp.json())
  //     .then(data => {
  //       // this.playerList=data;
  //       this.setPlayerOptions(wrapper, data);
  //     }
  //   );
  // }

  add_scout_comments(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_SCOUT_COMMENTS/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
    $('.player-select').select2({
      width: '100%',
    });
    $('.team-select').select2({
      width: '100%',
    });
    $('.team-select').on("select2:select", function(event)  {
      const wrapper = event.target.closest(".comments");
      var team_id = $(event.target).find(':selected').val();
      const queryParams = [`team_id=${team_id}`];
      fetch(`/get_amateur_players?${queryParams.join("&")}`)
      .then(resp => resp.json())
      .then(data => {
        setPlayerOptions(wrapper, data);
      })
    })   
    $('.location-select').select2({
      width: '100%',
    });
  }

  remove_scout_comment(event) {
    const wrapper = event.target.closest(".comments")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    }
    else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }
}

function setPlayerOptions(wrapper, playerList){
  let player_select = wrapper.querySelector('.player-select')
  player_select.innerHTML = ''
  for (let item of playerList['players']) {

      var opt = document.createElement('option');
      opt.value = item['player_id'];
      opt.innerHTML = `NO.${item['back_number']} - ${item['name_kor']}(${item['throws']}투${item['bats']}타)`;
    
      player_select.appendChild(opt)
  }
}