import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
  $('#review_search_kind').on('change',() => {
    let value = $('#review_search_kind').val()
    if(value == "날짜"){
      $('#review_name').hide()
      $('#review_date').show()
    }
    else if(value == "이름"){
      $('#review_date').hide()
      $('#review_name').show()
    }
  })
});