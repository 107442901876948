// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"



export default class extends Controller {
    static targets = [ "links", "template" ]

    initialize(){
      $(document).ready(function() {
        $('.player-select').select2({
          placeholder: "선수 선택",
          width: '100%',
        });
        $('.total-review').each(function(){
          //scroll height가 client height의 길이를 뛰어넘는 지점에서
          // if (elem.clientHeight < elem.scrollHeight)
          if(this.clientHeight < this.scrollHeight){
            $(this).height(this.scrollHeight)
          }
        });
        $('.each-review').each(function(){
          //scroll height가 client height의 길이를 뛰어넘는 지점에서
          // if (elem.clientHeight < elem.scrollHeight)
          if(this.clientHeight < this.scrollHeight){
            $(this).height(this.scrollHeight)
          }
        });
      })
    }

    connect() {
      $(window).on('resize', function(){
        $('.total-review').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
        $('.each-review').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
      });
      $('.button_kind_video').on('click',function(event) {

        var myTarget = $(event.target).find('.kind_video')
        if (myTarget.is(":visible")==true){
          myTarget.fadeOut()
          myTarget.hide() 
        }
        else{
          myTarget.fadeIn()
          myTarget.show()
        }
        
      })

      $('#pitcher_game_review_form').on('submit',function(){
        var is_return = true;
        var current_input_game_id = $('#game_review_select_games').val()

        if (current_input_game_id=="" | current_input_game_id==null){
            alert('경기를 입력해주세요.')
            is_return = false
        }else{
            $('.pitcher-review').each(function(index){
                var current_player_id = $('.player-select').eq(index).val()
                
                if (current_player_id=="" | current_player_id==null){
                    if (current_player_id=="" || current_player_id==null){
                        alert(`선수의 이름을 확인해주세요.`)
                        is_return=false
                    }
                }
            });
        }
        return is_return
    });

    }

    add_player(event) {
        const content = this.templateTarget.innerHTML.replace(/NEW_PLAYER/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML("beforebegin", content)
        $('.player-select').select2({
          placeholder: "선수 선택",
          width: '100%',
      });        
    }
    
    remove_player(event) {
        const wrapper = event.target.closest(".pitcher-review")
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
        }
        else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = "none"
        }
    }

    setHeight(event){
      const wrapper = event.target
      wrapper.style.height = 'auto'
      wrapper.style.height=wrapper.scrollHeight+"px"
    }
}