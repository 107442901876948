// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "template" ]
    initialize(){
        $(document).ready(function() {
          $('.player-select').select2({
            placeholder: "선수 선택",
            width: '100%',
          });
        })
        $('.evaluation-content').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })
    }
    connect() {
        $(window).on('resize', function(){
            $('.evaluation-content').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
        });
    }

    add_player(event) {
        const content = this.templateTarget.innerHTML.replace(/NEW_PLAYER/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML("beforebegin", content)
        $('.player-select').select2({
            placeholder: "선수 선택",
            width: '100%',
            height: '100%'
        });
    }

    remove_player(event) {
        const wrapper = event.target.closest(".pitcher-month-evaluation")
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
        }
        else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = "none"
        }
    }

    setYearMonth(){
        const year_month = document.getElementById('year_month').value;
        document.getElementById('pitcher_month_evaluation_year').value = year_month.split('-')[0]
        document.getElementById('pitcher_month_evaluation_month').value = year_month.split('-')[1]
        console.log(document.getElementById('pitcher_month_evaluation_month').value)
    }

    show_month_record() {

        const start_date_search = document.getElementById('start_date_search').value;
        const end_date_search = document.getElementById('end_date_search').value;

        if (start_date_search=='' | end_date_search==''){
            if (start_date_search==''){
                alert('시작 일을 선택해주세요.')
            }
            else{
                alert('마지막 일을 선택해주세요.')
            }
            return false
        }
        else{
            const queryParams = [
                `start_date=${start_date_search}`,
                `end_date=${end_date_search}`,
            ];

            fetch(`/month_evaluations/pitcher_month_record?${queryParams.join("&")}`)
                .then(resp => resp.json())
                .then(data => {
                    this.tableData = data;
                    this.drawTable();
                }
            );
        }
    }

    add_pitcher_point(event){
        const wrapper = event.target.closest(".pitcher-month-evaluation")
        let pitching_command = wrapper.querySelector(("input[name*='pitching_command']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='pitching_command']:checked")).value) : 0
        let pitching_speed = wrapper.querySelector(("input[name*='pitching_speed']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='pitching_speed']:checked")).value) : 0
        let pitching_breaking = wrapper.querySelector(("input[name*='pitching_breaking']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='pitching_breaking']:checked")).value) : 0
        let defense_pickoff = wrapper.querySelector(("input[name*='defense_pickoff']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='defense_pickoff']:checked")).value) : 0
        let defense_pfp = wrapper.querySelector(("input[name*='defense_pfp']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='defense_pfp']:checked")).value) : 0

        let pitching_evaluation = pitching_command+pitching_speed+pitching_breaking+defense_pickoff+defense_pfp
        
        wrapper.querySelector(".pitching-evaluation").innerHTML = `1.선수 기량 발전 평가 - ${pitching_evaluation}점`
    }

    add_make_up_point(event){
        const wrapper = event.target.closest(".pitcher-month-evaluation")
        let onground_training = wrapper.querySelector(("input[name*='onground_training']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_training']:checked")).value) : 0
        let onground_ongame = wrapper.querySelector(("input[name*='onground_ongame']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_ongame']:checked")).value) : 0
        let onground_learning = wrapper.querySelector(("input[name*='onground_learning']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_learning']:checked")).value) : 0
        let onground_leading = wrapper.querySelector(("input[name*='onground_leading']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_leading']:checked")).value) : 0
        let daily_selfcare = wrapper.querySelector(("input[name*='daily_selfcare']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_selfcare']:checked")).value) : 0
        let daily_cleanliness = wrapper.querySelector(("input[name*='daily_cleanliness']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_cleanliness']:checked")).value) : 0
        let daily_makeup = wrapper.querySelector(("input[name*='daily_makeup']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_makeup']:checked")).value) : 0
        let daily_relationship = wrapper.querySelector(("input[name*='daily_relationship']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='daily_relationship']:checked")).value) : 0

        let pitching_make_up = onground_training+onground_ongame+onground_learning+onground_leading+daily_selfcare+daily_cleanliness+daily_makeup+daily_relationship

        wrapper.querySelector(".pitching-make-up").innerHTML = `2.선수 메이크업/워크에식 평가 - ${pitching_make_up}점`
    }


    drawTable(){
        const recordsTable = document.getElementById('pitcher_month_records_table_body');
        recordsTable.innerHTML=''

        for(let [ index, pitcher_record ] of this.tableData['pitcher_month_records'].entries()) {

            var strHTML = `
                <tr class="">
                    <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center'>${pitcher_record['player_name']}#${pitcher_record['back_number']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['g']}(${pitcher_record['gs']})</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['win']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['loss']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['hold']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['save']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['ip']==null ? '0.0' : pitcher_record['ip']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['era']==null ? '0.00' : pitcher_record['era']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['hit']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['run']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['er']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['bb']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['k']}</td>
                    <td class='border whitespace-no-wrap text-center px-6 py-2'>${pitcher_record['whip']}</td>
                </tr>
            `
            recordsTable.insertAdjacentHTML( 'beforeend', strHTML );
        }
    }

    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }
}