import * as d3 from "d3";
import _ from "lodash";

import { pitchColors } from "./pitch_info";


jQuery(document).on('turbolinks:load', function() {
	const dimensions = {
	    margin: { top: 10, right: 10, bottom: 10, left: 20 },
	    width: 360,
	    height: 360
	  };
	const dimension_zones = {
	    margin: { top: 10, right: 10, bottom: 10, left: 10 },
	    width: 260,
	    height: 260
	  };
	const dimension_zones_mobile = {
	    margin: { top: 10, right: 10, bottom: 10, left: 10 },
	    width: 180,
	    height: 180
	  };

	const tick_values = {
		'movement':[-65,65,-65,65],
		'movement_tick':[-60, -30, 0, 30, 60],
		'movement_legend':[30, -40, 35, -40],
		'height_side':[-75,75,100,225],
		'height_side_tick_x':[-50,-25,0,25,50],
		'height_side_tick_y':[125,150,175,200,225],
		'height_side_legend':[30, 125, 35, 125],
		'height_extension':[125,225,125,225],
		'height_extension_tick':[125,150,175,200,225],
		'height_extension_legend':[200, 145, 202, 145],
		'strikezone':[-25,25,0,50]
	}

	$('#trackman_portable_select_player').on('click', ()=>{
		$('#trackman_portable_select_player_menu').slideToggle()
	})
	$('#trackman_portable_select_date').on('click', ()=>{
		$('#trackman_portable_select_date_menu').slideToggle()
	})

	var ajax = new XMLHttpRequest();
	let queryParams = [""];
	queryParams.push(`date=${$('#date_input_portable').val()}`);
	queryParams.push(`player_id=${$('#player_input_portable').val()}`);
	ajax.open("GET", `/portable_pitches?${queryParams.join("&")}`, true);
	ajax.onload = function(){
		const pitches = JSON.parse(ajax.responseText)['pitches']
	  const canonicalPitches = [];
	  const zones = JSON.parse(ajax.responseText)['layouts']
	  console.log(zones)
	  const byPitchType = _.groupBy(pitches, p => p.pitch_type);
	  for (const pitchType in byPitchType) {
	    canonicalPitches.push({
	      pitch_type: pitchType,
	      horz_break: d3.mean(byPitchType[pitchType].map(d => d.horz_break)),
	      induced_vert_break: d3.mean(
	        byPitchType[pitchType].map(d => d.induced_vert_break)
	      ),
	      rel_height: d3.mean(
	        byPitchType[pitchType].map(d => d.rel_height)
	      ),
	      rel_side: d3.mean(
	        byPitchType[pitchType].map(d => d.rel_side)
	      ),
	      extension: d3.mean(
	        byPitchType[pitchType].map(d => d.extension)
	      ),
	      numPitches: byPitchType[pitchType].length
	    });
	  }

	  for (const pitchType in byPitchType){
	  	//DESKTOP
	  	var { height, width, margin } = dimension_zones;
	  	const zone_svg = d3
			.select('#'+pitchType)
   	 	.append("svg")
   	 	.attr("width", width)
    	.attr("height", height)
    	.attr("class","mx-auto")
    	.append("g")
    	.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    	var scales_strike = getScales(dimension_zones,tick_values['strikezone']);

    	zone_svg
	    .append("g")
	    .selectAll("dot")
	    .data(byPitchType[pitchType])
	    .enter()
	    .append("circle")
	    .attr("cx", d => scales_strike.x(d.plate_loc_side * 39.3701))
	    .attr("cy", d => scales_strike.y(d.plate_loc_height * 39.3701))
	    .attr("r", 6)
	    .attr("opacity", 0.8)
	    .attr("stroke", "none")
	    .style("fill", d => pitchColors[d.pitch_type]["color"]);

	    zone_svg
	    .append("g")
	    .selectAll("dot")
	    .data(zones)
	    .enter()
	    .append("circle")
	    .attr("cx", d => scales_strike.x(d.plate_loc_side * 39.3701))
	    .attr("cy", d => scales_strike.y(d.plate_loc_height * 39.3701))
	    .attr("r", 1)
	    .attr("opacity", 0.8)
	    .attr("stroke", "none")
	    .style("fill", 'black');
	  }

	  for (const pitchType in byPitchType){
	  	//MOBILE
	  	var { height, width, margin } = dimension_zones_mobile;
	  	const zone_svg = d3
			.select('#'+pitchType+'_mobile')
   	 	.append("svg")
   	 	.attr("width", width)
    	.attr("height", height)
    	.attr("class","mx-auto")
    	.append("g")
    	.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    	var scales_strike = getScales(dimension_zones_mobile,tick_values['strikezone']);

    	zone_svg
	    .append("g")
	    .selectAll("dot")
	    .data(byPitchType[pitchType])
	    .enter()
	    .append("circle")
	    .attr("cx", d => scales_strike.x(d.plate_loc_side * 39.3701))
	    .attr("cy", d => scales_strike.y(d.plate_loc_height * 39.3701))
	    .attr("r", 5)
	    .attr("opacity", 0.8)
	    .attr("stroke", "none")
	    .style("fill", d => pitchColors[d.pitch_type]["color"]);

	    zone_svg
	    .append("g")
	    .selectAll("dot")
	    .data(zones)
	    .enter()
	    .append("circle")
	    .attr("cx", d => scales_strike.x(d.plate_loc_side * 39.3701))
	    .attr("cy", d => scales_strike.y(d.plate_loc_height * 39.3701))
	    .attr("r", 0.5)
	    .attr("opacity", 0.8)
	    .attr("stroke", "none")
	    .style("fill", 'black');
	  }

	  //For Movement Plot
	  const svg = drawCanvas('#movement_portable', dimensions);
	  const scales = getScales(dimensions,tick_values['movement']);
	  drawAxes(svg, dimensions, scales, tick_values['movement_tick'], tick_values['movement_tick']);
	  drawLegends(svg, dimensions, scales, tick_values['movement_legend']);
	  plotPitches(svg, scales, pitches, false);
	  plotPitches(svg, scales, canonicalPitches, true);

	  const svg_1 = drawCanvas('#height_side_portable', dimensions);
	  const scales_1 = getScales(dimensions, tick_values['height_side']);
	  drawAxes(svg_1, dimensions, scales_1, tick_values['height_side_tick_x'], tick_values['height_side_tick_y']);
	  drawLegends(svg_1, dimensions, scales_1, tick_values['height_side_legend']);
	  plotPitchesHeightSide(svg_1, scales_1, pitches, false);
	  plotPitchesHeightSide(svg_1, scales_1, canonicalPitches, true);
	  

	  const svg_2 = drawCanvas('#extension_portable', dimensions);
	  const scales_2 = getScales(dimensions,tick_values['height_extension']);
	  drawAxes(svg_2, dimensions, scales_2, tick_values['height_extension_tick'], tick_values['height_extension_tick']);
	  drawLegends(svg_2, dimensions, scales_2, tick_values['height_extension_legend']);
	  plotPitchesExtension(svg_2, scales_2, pitches, false);
	  plotPitchesExtension(svg_2, scales_2, canonicalPitches, true);

	  function drawLegends(svg, dimensions, scales, offset){

		const legendPitches = [];

	    for (const pitchType in byPitchType){legendPitches.push(pitchType);}
	    	svg.selectAll("dot")
		  .data(legendPitches)
		  .enter()
		  .append("circle")
		    .attr("cx", d => scales.x(offset[0]))
		    .attr("cy", function(d,i){ return scales.y(offset[1] - i*5)})
		    .attr("r", 5)
		    .style("fill", d=>pitchColors[d]["color"]);

		// Add one dot in the legend for each name.
		svg.selectAll("mylabels")
		  .data(legendPitches)
		  .enter()
		  .append("text")
		    .attr("x", scales.x(offset[2]))
		    .attr("y", function(d,i){ return scales.y(offset[3] - i*5)})
		    .style("fill", function(d){ return 'black'})
		    .text(function(d){ return d})
		    .attr("text-anchor", "left")
			.style("alignment-baseline", "middle")
			.style("font-size","x-small")
	    }
	};
    ajax.send();

})

function drawCanvas(containerId, dimensions) {
  // append the svg object to the body of the page
  const { height, width, margin } = dimensions;
  return d3
    .select(containerId)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
}
    

function getScales(dimensions, offset) {
  const { height, width, margin } = dimensions;

  const xScale = d3
    .scaleLinear()
    .domain([offset[0], offset[1]])
    .range([0, width - margin.left - margin.right]);
  const yScale = d3
    .scaleLinear()
    .domain([offset[2], offset[3]])
    .range([height - margin.top - margin.bottom, 0]);

  return { x: xScale, y: yScale };
}

function drawAxes(svg, dimensions, scales, offset_x, offset_y) {
  const { height, width, margin } = dimensions;

  // Define the axes
  const tickValues_x = offset_x;
  const tickValues_y = offset_y;
  const xAxis = d3.axisBottom(scales.x).tickValues(tickValues_x);
  const yAxis = d3.axisLeft(scales.y).tickValues(tickValues_y);

  // Plot the x-axis
  const xAxisPlot = svg
    .append("g")
    .attr(
      "transform",
      "translate(0," + (height - margin.top - margin.bottom) + ")"
    )
    .call(xAxis.tickSize(0, 0, 0));

  // Plot the y-axis
  const yAxisPlot = svg.append("g").call(yAxis.tickSize(0, 0, 0));

  // Add the x-axis lines/ticks - black at origin only
  xAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("y1", 0)
    .attr("y2", -height + margin.top + margin.bottom);

  // Add the y-axis lines/ticks - black at origin only
  yAxisPlot
    .selectAll(".tick line")
    .attr("stroke", d => (d == 0 ? "black" : "silver"))
    .attr("x1", 0)
    .attr("x2", width - margin.left - margin.right);
}

function plotPitches(svg, scales, pitches, isCanonical) {
  var filteredPitches = [];
  for (var pitch in pitches){
  	if (pitches[pitch]['horz_break'] === undefined || pitches[pitch]['induced_vert_break'] === undefined){}
  	else if (pitches[pitch]['horz_break'] === null || pitches[pitch]['induced_vert_break'] === null){}
  	else{
  		filteredPitches.push(pitches[pitch])
  	}
  }
  svg
    .append("g")
    .selectAll("dot")
    .data(filteredPitches)
    .enter()
    .append("circle")
    .attr("cx", d => scales.x(d.horz_break * 100))
    .attr("cy", d => scales.y(d.induced_vert_break * 100))
    .attr("r", isCanonical ? 6 : 4)
    .attr("opacity", isCanonical ? 0.8 : 0.6)
    .attr("stroke", isCanonical ? "black" : "none")
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function plotPitchesHeightSide(svg, scales, pitches, isCanonical) {

  svg
    .append("g")
    .selectAll("dot")
    .data(pitches)
    .enter()
    .append("circle")
    .attr("cx", d => scales.x(d.rel_side * 100))
    .attr("cy", d => scales.y(d.rel_height * 100))
    .attr("r", isCanonical ? 6 : 4)
    .attr("opacity", isCanonical ? 0.8 : 0.6)
    .attr("stroke", isCanonical ? "black" : "none")
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function plotPitchesExtension(svg, scales, pitches, isCanonical) {
  svg
    .append("g")
    .selectAll("dot")
    .data(pitches)
    .enter()
    .append("circle")
    .attr("cx", d => scales.x(d.extension * 100))
    .attr("cy", d => scales.y(d.rel_height * 100))
    .attr("r", isCanonical ? 6 : 4)
    .attr("opacity", isCanonical ? 0.8 : 0.6)
    .attr("stroke", isCanonical ? "black" : "none")
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}

function plotAvgs(svg, scales, pitches) {
  svg
    .append("g")
    .selectAll("rect")
    .data(pitches)
    .enter()
    .append("rect")
    .attr("x", d => scales.x(-1 * d.horz_break * 100) - 3.5)
    .attr("y", d => scales.y(d.induced_vert_break * 100) - 3.5)
    .attr("width", 7)
    .attr("height", 7)
    .attr("opacity", 1)
    .attr("stroke", "black")
    .attr("stroke-width",1)
    .style("fill", d => pitchColors[d.pitch_type]["color"]);
}