import Team1 from "../images/team_logos/1.png";
import Team2 from "../images/team_logos/2.png";
import Team3 from "../images/team_logos/3.png";
import Team4 from "../images/team_logos/4.png";
import Team5 from "../images/team_logos/5.png";
import Team6 from "../images/team_logos/6.png";
import Team7 from "../images/team_logos/7.png";
import Team8 from "../images/team_logos/8.png";
import Team9 from "../images/team_logos/9.png";
import Team10 from "../images/team_logos/10.png";
import Team21 from "../images/team_logos/21.png";
import Team22 from "../images/team_logos/22.png";
import Team23 from "../images/team_logos/23.png";
import Team25 from "../images/team_logos/25.png";
import Team26 from "../images/team_logos/26.png";
import Team27 from "../images/team_logos/27.png";
import Team28 from "../images/team_logos/28.png";
import Team29 from "../images/team_logos/29.png";
import Team30 from "../images/team_logos/30.png";
import Team31 from "../images/team_logos/31.png";
import Team32 from "../images/team_logos/32.png";
import Team33 from "../images/team_logos/33.png";
import Team34 from "../images/team_logos/34.png";
import Team35 from "../images/team_logos/35.png";
import Team36 from "../images/team_logos/36.png";
import Team37 from "../images/team_logos/37.png";
import Team38 from "../images/team_logos/38.png";
import Team39 from "../images/team_logos/39.png";
import Team40 from "../images/team_logos/40.png";
import Team41 from "../images/team_logos/41.png";
import Team42 from "../images/team_logos/42.png";
import Team43 from "../images/team_logos/43.png";
import Team44 from "../images/team_logos/44.png";
import Team45 from "../images/team_logos/45.png";
import Team46 from "../images/team_logos/46.png";
import Team47 from "../images/team_logos/47.png";
import Team48 from "../images/team_logos/48.png";
import Team49 from "../images/team_logos/49.png";
import Team50 from "../images/team_logos/50.png";
import Team51 from "../images/team_logos/51.png";
import Team52 from "../images/team_logos/52.png";
import Team53 from "../images/team_logos/53.png";
import Team54 from "../images/team_logos/54.png";
import Team55 from "../images/team_logos/55.png";
import Team56 from "../images/team_logos/56.png";
import Team57 from "../images/team_logos/57.png";
import Team58 from "../images/team_logos/58.png";
import Team59 from "../images/team_logos/59.png";
import Team60 from "../images/team_logos/60.png";
import Team61 from "../images/team_logos/61.png";
import Team62 from "../images/team_logos/62.png";
import Team63 from "../images/team_logos/63.png";
import Team64 from "../images/team_logos/64.png";
import Team65 from "../images/team_logos/65.png";
import Team66 from "../images/team_logos/66.png";
import Team67 from "../images/team_logos/67.png";
import Team68 from "../images/team_logos/68.png";
import Team69 from "../images/team_logos/69.png";
import Team70 from "../images/team_logos/70.png";
import Team71 from "../images/team_logos/71.png";
import Team72 from "../images/team_logos/72.png";
import Team73 from "../images/team_logos/73.png";
import Team74 from "../images/team_logos/74.png";
import Team75 from "../images/team_logos/75.png";
import Team76 from "../images/team_logos/76.png";
import Team77 from "../images/team_logos/77.png";
import Team78 from "../images/team_logos/78.png";
import Team79 from "../images/team_logos/79.png";
import Team81 from "../images/team_logos/81.png";
import Team83 from "../images/team_logos/83.png";
import Team85 from "../images/team_logos/85.png";
import Team86 from "../images/team_logos/86.png";
import Team87 from "../images/team_logos/87.png";
import Team88 from "../images/team_logos/88.png";
import Team89 from "../images/team_logos/89.png";
import Team90 from "../images/team_logos/90.png";
import Team91 from "../images/team_logos/91.png";
import Team92 from "../images/team_logos/92.png";
import Team93 from "../images/team_logos/93.png";
import Team94 from "../images/team_logos/94.png";
import Team95 from "../images/team_logos/95.png";
import Team96 from "../images/team_logos/96.png";
import Team97 from "../images/team_logos/97.png";
import Team98 from "../images/team_logos/98.png";
import Team99 from "../images/team_logos/99.png";
import Team100 from "../images/team_logos/100.png";
import Team101 from "../images/team_logos/101.png";
import Team102 from "../images/team_logos/102.png";
import Team103 from "../images/team_logos/103.png";
import Team104 from "../images/team_logos/104.png";
import Team105 from "../images/team_logos/105.png";
import Team106 from "../images/team_logos/106.png";
import Team107 from "../images/team_logos/107.png";
import Team108 from "../images/team_logos/108.png";
import Team109 from "../images/team_logos/109.png";
import Team110 from "../images/team_logos/110.png";
import Team111 from "../images/team_logos/111.png";
import Team112 from "../images/team_logos/112.png";
import Team113 from "../images/team_logos/113.png";
import Team114 from "../images/team_logos/114.png";
import Team115 from "../images/team_logos/115.png";
import Team116 from "../images/team_logos/116.png";
import Team117 from "../images/team_logos/117.png";
import Team118 from "../images/team_logos/118.png";
import Team119 from "../images/team_logos/119.png";
import Team120 from "../images/team_logos/120.png";
import Team121 from "../images/team_logos/121.png";
import Team122 from "../images/team_logos/122.png";
import Team123 from "../images/team_logos/123.png";
import Team124 from "../images/team_logos/124.png";
import Team125 from "../images/team_logos/125.png";
import Team126 from "../images/team_logos/126.png";
import Team127 from "../images/team_logos/127.png";
import Team128 from "../images/team_logos/128.png";
import Team129 from "../images/team_logos/129.png";
import Team130 from "../images/team_logos/130.png";
import Team131 from "../images/team_logos/131.png";
import Team132 from "../images/team_logos/132.png";
import Team133 from "../images/team_logos/133.png";
import Team134 from "../images/team_logos/134.png";
import Team135 from "../images/team_logos/135.png";
import Team136 from "../images/team_logos/136.png";
import Team137 from "../images/team_logos/137.png";
import Team138 from "../images/team_logos/138.png";
import Team139 from "../images/team_logos/139.png";
import Team140 from "../images/team_logos/140.png";
import Team141 from "../images/team_logos/141.png";
import Team142 from "../images/team_logos/142.png";
import Team143 from "../images/team_logos/143.png";
import Team144 from "../images/team_logos/144.png";
import Team145 from "../images/team_logos/145.png";
import Team146 from "../images/team_logos/146.png";
import Team147 from "../images/team_logos/147.png";
import Team148 from "../images/team_logos/148.png";
import Team149 from "../images/team_logos/149.png";
import Team150 from "../images/team_logos/150.png";
import Team151 from "../images/team_logos/151.png";
import KBO from "../images/team_logos/kbo.png";

const template = {
  type: "custom",
  method: function(value, item) {
    let img = "";
    if (item.team_id !== undefined) {
      let src = "";
      if (item.team_id === 1) src = Team1;
      else if (item.team_id === 2) src = Team2;
      else if (item.team_id === 3) src = Team3;
      else if (item.team_id === 4) src = Team4;
      else if (item.team_id === 5) src = Team5;
      else if (item.team_id === 6) src = Team6;
      else if (item.team_id === 7) src = Team7;
      else if (item.team_id === 8) src = Team8;
      else if (item.team_id === 9) src = Team9;
      else if (item.team_id === 10) src = Team10;
      else if (item.team_id === 11) src = Team1;
      else if (item.team_id === 12) src = Team2;
      else if (item.team_id === 13) src = Team3;
      else if (item.team_id === 14) src = Team4;
      else if (item.team_id === 15) src = Team5;
      else if (item.team_id === 16) src = Team6;
      else if (item.team_id === 17) src = Team7;
      else if (item.team_id === 18) src = Team8;
      else if (item.team_id === 19) src = Team9;
      else if (item.team_id === 20) src = Team23;
      else if (item.team_id === 21) src = Team21;
      else if (item.team_id === 22) src = Team22;
      else if (item.team_id === 23) src = Team23;
      else if (item.team_id === 24) src = Team24;
      else if (item.team_id === 25) src = Team25;
      else if (item.team_id === 26) src = Team26;
      else if (item.team_id === 27) src = Team27;
      else if (item.team_id === 28) src = Team28;
      else if (item.team_id === 29) src = Team29;
      else if (item.team_id === 30) src = Team30;
      else if (item.team_id === 31) src = Team31;
      else if (item.team_id === 32) src = Team32;
      else if (item.team_id === 33) src = Team33;
      else if (item.team_id === 34) src = Team34;
      else if (item.team_id === 35) src = Team35;
      else if (item.team_id === 36) src = Team36;
      else if (item.team_id === 37) src = Team37;
      else if (item.team_id === 38) src = Team38;
      else if (item.team_id === 39) src = Team39;
      else if (item.team_id === 40) src = Team40;
      else if (item.team_id === 41) src = Team41;
      else if (item.team_id === 42) src = Team42;
      else if (item.team_id === 43) src = Team43;
      else if (item.team_id === 44) src = Team44;
      else if (item.team_id === 45) src = Team45;
      else if (item.team_id === 46) src = Team46;
      else if (item.team_id === 47) src = Team47;
      else if (item.team_id === 48) src = Team48;
      else if (item.team_id === 49) src = Team49;
      else if (item.team_id === 50) src = Team50;
      else if (item.team_id === 51) src = Team51;
      else if (item.team_id === 52) src = Team52;
      else if (item.team_id === 53) src = Team53;
      else if (item.team_id === 54) src = Team54;
      else if (item.team_id === 55) src = Team55;
      else if (item.team_id === 56) src = Team56;
      else if (item.team_id === 57) src = Team57;
      else if (item.team_id === 58) src = Team58;
      else if (item.team_id === 59) src = Team59;
      else if (item.team_id === 60) src = Team60;
      else if (item.team_id === 61) src = Team61;
      else if (item.team_id === 62) src = Team62;
      else if (item.team_id === 63) src = Team63;
      else if (item.team_id === 64) src = Team64;
      else if (item.team_id === 65) src = Team65;
      else if (item.team_id === 66) src = Team66;
      else if (item.team_id === 67) src = Team67;
      else if (item.team_id === 68) src = Team68;
      else if (item.team_id === 69) src = Team69;
      else if (item.team_id === 70) src = Team70;
      else if (item.team_id === 71) src = Team71;
      else if (item.team_id === 72) src = Team72;
      else if (item.team_id === 73) src = Team73;
      else if (item.team_id === 74) src = Team74;
      else if (item.team_id === 75) src = Team75;
      else if (item.team_id === 76) src = Team76;
      else if (item.team_id === 77) src = Team77;
      else if (item.team_id === 78) src = Team78;
      else if (item.team_id === 79) src = Team79;
      else if (item.team_id === 81) src = Team81;
      else if (item.team_id === 83) src = Team83;
      else if (item.team_id === 85) src = Team85;
      else if (item.team_id === 86) src = Team86;
      else if (item.team_id === 87) src = Team87;
      else if (item.team_id === 88) src = Team88;
      else if (item.team_id === 89) src = Team89;
      else if (item.team_id === 90) src = Team90;
      else if (item.team_id === 91) src = Team91;
      else if (item.team_id === 92) src = Team92;
      else if (item.team_id === 93) src = Team93;
      else if (item.team_id === 94) src = Team94;
      else if (item.team_id === 95) src = Team95;
      else if (item.team_id === 96) src = Team96;
      else if (item.team_id === 97) src = Team97;
      else if (item.team_id === 98) src = Team98;
      else if (item.team_id === 99) src = Team99;
      else if (item.team_id === 100) src = Team100;
      else if (item.team_id === 101) src = Team101;
      else if (item.team_id === 102) src = Team102;
      else if (item.team_id === 103) src = Team103;
      else if (item.team_id === 104) src = Team104;
      else if (item.team_id === 105) src = Team105;
      else if (item.team_id === 106) src = Team106;
      else if (item.team_id === 107) src = Team107;
      else if (item.team_id === 108) src = Team108;
      else if (item.team_id === 109) src = Team109;
      else if (item.team_id === 110) src = Team110;
      else if (item.team_id === 111) src = Team111;
      else if (item.team_id === 112) src = Team112;
      else if (item.team_id === 113) src = Team113;
      else if (item.team_id === 114) src = Team114;
      else if (item.team_id === 115) src = Team115;
      else if (item.team_id === 116) src = Team116;
      else if (item.team_id === 117) src = Team117;
      else if (item.team_id === 118) src = Team118;
      else if (item.team_id === 119) src = Team119;
      else if (item.team_id === 120) src = Team120;
      else if (item.team_id === 121) src = Team121;
      else if (item.team_id === 122) src = Team122;
      else if (item.team_id === 123) src = Team123;
      else if (item.team_id === 124) src = Team124;
      else if (item.team_id === 125) src = Team125;
      else if (item.team_id === 126) src = Team126;
      else if (item.team_id === 127) src = Team127;
      else if (item.team_id === 128) src = Team128;
      else if (item.team_id === 129) src = Team129;
      else if (item.team_id === 130) src = Team130;
      else if (item.team_id === 131) src = Team131;
      else if (item.team_id === 132) src = Team132;
      else if (item.team_id === 133) src = Team133;
      else if (item.team_id === 134) src = Team134;
      else if (item.team_id === 135) src = Team135;
      else if (item.team_id === 136) src = Team136;
      else if (item.team_id === 137) src = Team137;
      else if (item.team_id === 138) src = Team138;
      else if (item.team_id === 139) src = Team139;
      else if (item.team_id === 140) src = Team140;
      else if (item.team_id === 141) src = Team141;
      else if (item.team_id === 142) src = Team142;
      else if (item.team_id === 143) src = Team143;
      else if (item.team_id === 144) src = Team144;
      else if (item.team_id === 145) src = Team145;
      else if (item.team_id === 146) src = Team146;
      else if (item.team_id === 147) src = Team147;
      else if (item.team_id === 148) src = Team148;
      else if (item.team_id === 149) src = Team149;
      else if (item.team_id === 150) src = Team150;
      else if (item.team_id === 151) src = Team151;
      else src = KBO;
      img += `<img class='h-6 w-6 mr-2 inline-block' src='${src}' />`;
    }
    return img + (item.name_kor || "-") + `(${item.back_number||'-'})` + "<div>" + item.name_full + "</div>";
  }
};

document.addEventListener("turbolinks:load", function() {
  const $input = $(".search");
  const options = {
    getValue: "name_kor",
    url: function(phrase) {
      return "/search.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "players",
        maxNumberOfElements: 40,
      }
    ],
    list: {
      onChooseEvent: function() {
        var url = $input.getSelectedItemData().url;
        $input.val("");
        Turbolinks.visit(url);
      },
      maxNumberOfElements: 40,
      match: {
          enabled: true
      }
    },
    template: template,
  };
  $input.easyAutocomplete(options);
  $('.easy-autocomplete-container ul').css({
    "max-height":"160px",
    "overflow-y":"scroll"
  })
});

document.addEventListener("turbolinks:load", function() {
  const $input = $(".report-search");
  const options = {
    getValue: "name_kor",
    url: function(phrase) {
      return "/search.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "players",
        maxNumberOfElements: 40
      }
    ],
    list: {
      onChooseEvent: function() {
        const data = $input.getSelectedItemData();
        console.log(data);
        [
          "player_id",
          "throws",
          "bats",
          "height",
          "weight",
          "birth_date",
          "team_id",
          "position_number"
        ].forEach(property =>
          $(`#report_report_player_attributes_${property}`).val(data[property])
        );
      },
      maxNumberOfElements: 40,
      match: {
          enabled: true
      }
    },
    template: template
  };

  $input.easyAutocomplete(options);
  $('.easy-autocomplete-container ul').css({
    "max-height":"160px",
    "overflow-y":"scroll"
  });  
});