import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", () => {
  $("#leaderboard").tablesorter();
  $("#leaderboard_label").html($("#leaderboard_kind").data('lbtype'));

  $('#leaderboard_dropdown').on('click', function(){
    $('#leaderboard_menus').toggle(100)
  })

  $('#place_appearance_1').on('click',function(){
    $('#pa_1').val(446)
    $('#pa_2').val('')
  })
 })