import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class shoulderRom{
    
    // init
    constructor(chartData){
        this.chartData = chartData;
        this.shoulder_rom_d_list=[
            'd_internal_rotation',
            'd_external_rotation',
        ]

        this.shoulder_rom_nd_list=[
            'nd_internal_rotation',
            'nd_external_rotation',
        ]

        this.label_rom_d_list=[
            '내회전(주동)',
            '외회전(주동)',
        ]

        this.label_rom_nd_list=[ 
            '내회전(비주동)',
            '외회전(비주동)',
        ]
        this.ultimateColors = ['rgba(255, 0, 0, 0.4)', 'rgba(0, 0, 255, 0.4)', 'rgba(255, 255, 255, 1)']
        this.fontColors = ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 1)', 'rgba(255, 255, 255, 1)']
        this.columnsCount=null;
        this.columnsList=[];
        this.d_throws = this.chartData['player_info']['d_throws']=='R' ? '주동(오)' : '주동(왼)'
        this.nd_throws = this.chartData['player_info']['nd_throws']=='R' ? '비주동(오)' : '비주동(왼)'
    };

    //function
    drawChart(){
        var div_shoulder_roms = document.getElementById("div_shoulder_roms")
        div_shoulder_roms.innerHTML=null;

        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var traceList = [];
            var rowIndex = 0

            div_shoulder_roms.insertAdjacentHTML(
                'beforeend',
                `<div class='inline-flex items-center justify-center w-full'>
                    <h2 class="text-2xl md:text-xl lg:text-2xl">${month['screening_month']}</h1>
                </div>
                <div class='h-2'></div>
                `
            );

            var month_shoulder_rom = document.createElement('div');
            month_shoulder_rom.setAttribute("id", `div_shoulder_rom_${monthIndex}`);
            month_shoulder_rom.setAttribute("class", 'grid grid-cols-2');



            var month_shoulder_rom_d_throws = document.createElement('div');
            month_shoulder_rom_d_throws.setAttribute("id", `div_shoulder_rom_${monthIndex}_d_throws`);

            
            var month_shoulder_rom_nd_throws = document.createElement('div');
            month_shoulder_rom_nd_throws.setAttribute("id", `div_shoulder_rom_${monthIndex}_nd_throws`);
            // month_shoulder_rom_nd_throws.setAttribute("class", 'col-span-1 w-full');

            month_shoulder_rom.appendChild(month_shoulder_rom_d_throws)
            month_shoulder_rom.appendChild(month_shoulder_rom_nd_throws)

            div_shoulder_roms.appendChild(month_shoulder_rom)

            if (month['d_internal_rotation']!=null & month['d_external_rotation']!=null){

                var rom_d_trace = this.getTrace(month, 'rom_d')
                traceList.push(rom_d_trace)

            }

            if (month['nd_internal_rotation']!=null & month['nd_external_rotation']!=null){

                var rom_nd_trace = this.getTrace(month, 'rom_nd')
                traceList.push(rom_nd_trace)

                // rowIndex+=1
            }

            // var height = width-width*0.2
            // this.getColumnsInfo()
            // var data_list = this.getDataset()
            var layout = {
                // title: {
                //     text:month['screening_month'],
                //     font: {
                //       size: 24
                //     },
                //     align:'center'
                // },
                // autosize:true,
                // automargin: true,
                dragmode:false,
                // legend:{
                //     x:1,
                //     xref:'paper',
                //     xanchor: 'right',
                //     y: 1.2,
                //     "orientation": "h",
                //     itemclick:false
                // },
                margin: {
                    l: 30,
                    r: 0,
                    b: 30,
                    t: 0,
                },                
            }
    
            var annotationList = [
                {
                    text: this.d_throws,
                    font: {
                        size: 16,
                        color: 'Black',
                    },
                    showarrow: false,
                    align: 'center',
                    x: 0.27,
                    y: 0.5,
                    xref: 'paper',
                    yref: 'paper',
                },
                {
                    text: this.nd_throws,
                    font: {
                        size: 16,
                        color: 'Black',
                    },
                    showarrow: false,
                    align: 'center',
                    x: 0.84,
                    y: 0.5,
                    xref: 'paper',
                    yref: 'paper',
                }
            ]
    
            var config = {
                responsive: true,
                displayModeBar: false,
                clickmode: 'select'
            }
            // var monthAnnotationList = this.getMonthAnnotations()
            // annotationList = annotationList.concat(monthAnnotationList)
            // layout['annotations']=annotationList
            Plotly.newPlot(`div_shoulder_rom_${monthIndex}_d_throws`, [rom_d_trace], layout, config);
            Plotly.newPlot(`div_shoulder_rom_${monthIndex}_nd_throws`, [rom_nd_trace], layout, config);

        }
        // return traceList

        


    };

    getColumnsInfo(){
        for(let month of this.chartData['other_months']) {
            if (month['d_internal_rotation']!=null & month['d_external_rotation']!=null & month['nd_internal_rotation']!=null & month['nd_external_rotation']!=null){
                this.columnsCount+=1
                this.columnsList.push(month['screening_month'])
            }
        }
    };

    getDataset(){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };

        var traceList = [];
        var rowIndex = 0
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {

            if (month['d_internal_rotation']!=null & month['d_external_rotation']!=null & month['nd_internal_rotation']!=null & month['nd_external_rotation']!=null){

                var rom_d_trace = this.getTrace(month, rowIndex, 'rom_d')
                traceList.push(rom_d_trace)

                var rom_nd_trace = this.getTrace(month, rowIndex, 'rom_nd')
                traceList.push(rom_nd_trace)

                rowIndex+=1
            }
        }
        return traceList

    };

    // example 
    // polar2: {
    //     sector: [190, 45], # calculate sector
    //     internal_rotation_sector : 90-a
    //     external_rotation_sector : 90+a
    //     domain: { # set domain for grid
    //       column: 1,
    //       row: 0
    //     },
    //     radialaxis: {
    //       showgrid:false,        
    //       showline:false,
    //       range:[0,6]
    //     },
    //     angularaxis: {
    //       showgrid:false,
    //       showline:false,
    //       direction:'clockwise'
    //     },

    // 3 rows - [1,0.69], [0.69,0.33], [0.33,0]

    // example
    // {
    //     type: "scatterpolar",
    //     r: 180, # calculate r
    //     theta: 0, # calculate theta
    //     fill:'toself',
    //     subplot: "polar2" # set after polar
    //   }

    // algorithm
    // first. set sector in polar chart
    // second. get linespace for polar chart

    getTrace(monthData, screening_category=null){

        if (screening_category=='rom_d'){
            var internalRotation = monthData['d_internal_rotation']
            var externalRotation = monthData['d_external_rotation']
            var empty = 360 - externalRotation - internalRotation

            
        }
        else{
            var internalRotation = monthData['nd_internal_rotation']
            var externalRotation = monthData['nd_external_rotation']
            var empty = 360 - externalRotation - internalRotation           

        }
        // var coordList = this.getCoords(true)
        // var column = screening_category=='rom_d' ? 0 : 1
        var trace_title = screening_category=='rom_d' ? this.d_throws : this.nd_throws
        var trace = {
            title: {
                text:trace_title,
                font: {
                    size: 14
                  },
            },
            values: [internalRotation, externalRotation, empty],
            labels: ['내회전', '외회전', ' '],
            text: [`${parseInt(internalRotation)}°`, `${parseInt(externalRotation)}°`, empty],
            type: 'pie',
            name: '',
            marker: {
                colors: this.ultimateColors
            },
            insidetextfont:{
                color:this.fontColors
            },
            showlegend:false,

            sort:false,
            hole:.5,
            hoverinfo:'none',
            textposition:'inside',
            textinfo:'text',
        }
        return trace
    };

    // linspace(startValue, stopValue, cardinality){
    //     var arr = [];
    //     var step = (stopValue - startValue) / (cardinality - 1);
    //     for (var i = 0; i < cardinality; i++) {
    //       arr.push(startValue + (step * i));
    //     }
    //     return arr;
    // }

    // getCoords(reverse=false){
    //     var coordList = [];
    //     var spacingList = this.linspace(0,1,this.columnsList.length+1)
    //     for (let [spaceIndex, spacing] of spacingList.reverse().entries()){
    //         if (spacingList.length-1==spaceIndex){
    //             break
    //         }
    //         else{
    //             if (reverse==false){
    //                 coordList.push([spacing, spacingList[spaceIndex+1]])
    //             }
    //             else{
    //                 coordList.push([spacingList[spaceIndex+1], spacing])
    //             }
                
    //         }
    //     }
    //     return coordList
    // }


    // getMonthAnnotations(){
    //     var annotationList = [];
    //     var coordList = this.getCoords()
    //     for (let [monthIndex, screeningMonth] of this.columnsList.entries()) {
    //         if (monthIndex==0){
    //             var y = parseFloat((coordList[monthIndex][0] + coordList[monthIndex][1])/2)+0.03    
    //         }
    //         else if (monthIndex==this.columnsList.length-1){
    //             var y = parseFloat((coordList[monthIndex][0] + coordList[monthIndex][1])/2)-0.02
    //         }
    //         else{
    //             var y = parseFloat((coordList[monthIndex][0] + coordList[monthIndex][1])/2)
    //         }
            
    //         const annotation = {
    //             text:screeningMonth,
    //             font: {
    //                 size: 16,
    //                 color: 'Black',
    //             },
    //             showarrow: false,
    //             align: 'center',
    //             x: -0.05,
    //             y: y,
    //             xref: 'paper',
    //             yref: 'paper',
    //         }   
    //         annotationList.push(annotation)                
    //     }
    //     return annotationList
    // }
}