import { Controller } from "stimulus";
export default class extends Controller {

    static targets = [ "links", "template" ]
    initialize(){
      $(document).ready(function() {
        $('.player-select').select2({
        width: '100%',
        });
        $('.transaction-comment').each(function(){
          //scroll height가 client height의 길이를 뛰어넘는 지점에서
          // if (elem.clientHeight < elem.scrollHeight)
          if(this.clientHeight < this.scrollHeight){
            $(this).height(this.scrollHeight)
          }
        })
      })
    }
    connect() {
      $(window).on('resize', function(){
        $('.transaction-comment').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
      });
      // $('#transactions_form').on('submit',function(event){
      //   var current_input_date = $('#transaction_date').val()
      //   var current_input_call_type = $('#transaction_call_type').val()

      //   $('.player-transaction').each(function(index){
      //     var is_return = true;
      //     var transaction_player = $('.transaction-player').eq(index).val()
      //     var call_type = $('.call-type').eq(index).val()
      //     var transaction_date = $('.transaction-date').eq(index).val()
          
      //     if (transaction_player=="" | call_type=="" | transaction_date==""){
      //       if (transaction_player==""){
      //         alert(`${index+1}번 선수의 이름을 입력해주세요.`)
      //         event.preventDefault();
      //       }
      //       else if(call_type==""){
      //         alert(`${index+1}번 선수의 등/말소 여부를 확인해주세요.`)
      //         event.preventDefault();
      //       }
      //       else{
      //         alert(`${index+1}번 선수의 등말소 일정을 확인해주세요.`)
      //         event.preventDefault();
      //       }
      //     }

      //     if(is_return == true){
      //       $.ajax({
      //         url: '/transaction/check_duplicate',
      //         method: 'POST',
      //         data: { "player_id": player_id, "transaction_date": transaction_date, "call_type":call_type},
      //         success: function (data){
      //           if(data['duplicate']>=1){
      //             alert('같은 날짜의 등말소 내역이 존재합니다.')
      //             event.preventDefault()
      //           }else{
      //             alert('작성 완료')
      //           }
      //         },
      //         async: true
      //       }); 
      //     }
      //   });
      // })
    }
  
    add_player_transactions(event) {
      const content = this.templateTarget.innerHTML.replace(/NEW_TRANSACTION/g, new Date().getTime())
      this.linksTarget.insertAdjacentHTML("beforebegin", content)
      $('.player-select').select2({
        width: '100%',
      });      
    }
  
    remove_player_transactions(event) {
      const wrapper = event.target.closest(".player-transaction")
      if (wrapper.dataset.newRecord == "true") {
        wrapper.remove()
      }
      else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = "none"
      }
    }

    check_data(event){
      var isReturn = true;
      $('.player-transaction').each(function(index){
        
        var transaction_player = $('.transaction-player').eq(index).val()
        var call_type = $('.call-type').eq(index).val()
        var transaction_date = $('.transaction-date').eq(index).val()
        
        if (transaction_player=="" | call_type=="" | transaction_date==""){
          if (transaction_player==""){
            alert(`${index+1}번 선수의 이름을 입력해주세요.`)
            isReturn=false
          }
          else if(call_type==""){
            alert(`${index+1}번 선수의 등/말소 여부를 확인해주세요.`)
            isReturn=false
          }
          else{
            alert(`${index+1}번 선수의 등말소 일정을 확인해주세요.`)
            isReturn=false
          }
          
        } else {
          $.ajax({
            url: '/transaction/checkTransactionDuplicate',
            method: 'POST',
            async: false,
            data: { "player_id": transaction_player, "transaction_date": transaction_date, "call_type":call_type},
            success: function (data){
              if(data['duplicate']>=1){
                alert(`${index+1}번 선수 : 같은 날짜의 등말소 내역이 존재합니다.`)
                isReturn=false
              }
            },
          });
        }
      });
      if (isReturn==false){
        event.preventDefault()
      }
      else{
        alert('이력 등록 완료')
      }
    
    }
}

function handleData(data){
  
}