import html2canvas from 'html2canvas';

function PrintDiv(div){
	div = div[0];
	html2canvas(div, {scrollY: -window.scrollY, scale:2}).then(function(canvas){
		var myImage = canvas.toDataURL();
		downloadURI(myImage, "DownloadReport.png");
	});
}

function downloadURI(uri, name){
	var link = document.createElement("a");
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
}

jQuery(document).on('turbolinks:load', function() {
  $('#blobButton').on('click',function() {
  	console.log("clicked")
  	PrintDiv($("#injuryDiv"));
  })
});