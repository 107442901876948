// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() { }

  add_for_pitcher(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_COMMENT/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove_player(event) {
    const wrapper = event.target.closest(".injurys")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    }
    else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }
}
