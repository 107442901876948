import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]
  initialize(){
    $('.player-select').select2({
      width: '100%',
      ajax: {
        url: '/scouts/international_scout_trackman_reports/get_international_players',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          var query = {
              search: params.term
          }
          return query;
        },
        processResults: function (data) {
          return {
            results: data.players,
          };
        },
        cache: true
      },
    });
    $('.year-select').select2({
      width: '100%',
    });
    $('.kind-select').select2({
      width: '100%',
    });
    
    // this.resizeHeight()


    // var url = $('#report-url').attr('src');
    // var thePdf = null;
    // var scale = 1;

    // pdfjsLib.getDocument(url).promise.then(function(pdf){
    //     thePdf = pdf;
    //     var viewer = document.getElementById('pdf-viewer');
        
    //     for(var page = 1; page <= pdf.numPages; page++) {
    //       var canvas = document.createElement("canvas");    
    //       canvas.className = 'pdf-page-canvas';         
    //       viewer.appendChild(canvas);            
    //       renderPage(page, canvas, thePdf, scale);
    //     }
    // });
  
  }

  resizeHeight(){
    console.log(document.getElementById('inlineFrameExample').contentWindow.document.body.scrollHeight)
    console.log(document.getElementById('inlineFrameExample').contentWindow.document.getElementsByTagName('body')[0].scrollHeight);
    document.getElementById("inlineFrameExample").height = document.getElementById("inlineFrameExample").contentWindow.document.body.scrollHeight + 'px';
    // $('#inlineFrameExample').height = $('#inlineFrameExample').contentWindow.document.body.offsetHeight + 'px';
  }

  connect() {
    // $(window).on('resize', function(){

    //   var elements = document.getElementsByClassName('pdf-page-canvas')

    //   for (var element of elements){
    //     element.width = window.innerWidth-50;
    //   };

    //   // $('.pdf-page-canvas').each(function(){
    //   //   $(this).css('width','auto');
    //   //   $(this).width(window.innerWidth-50);
    //   // })
    // });

    $('#scouts_international_scout_trackman_reports_form').on('submit',function(event){
      var isReturn=true

      var current_player = $('.player').val()
      var current_season = $('.year-select').val()
      var current_kind = $('.kind-select').val()
      var current_report = $('#report-file').attr('filename')

      if (current_player=="" || current_season=="" || current_season==undefined || current_report==undefined){
        if (current_player==""){
          alert(`선수의 이름을 입력해주세요.`)
          event.preventDefault();
        }
        else if(current_season=="" || current_season==undefined){
          alert(`리포트 시즌을 입력해주세요.`)
          event.preventDefault();
        }
        else if(current_kind=="" || current_kind==undefined){
          alert(`리포트 분류를 입력해주세요.`)
          event.preventDefault();
        }
        else if(current_report=="" || current_report==undefined){
          alert(`리포트 파일을 확인해주세요.`)
          event.preventDefault();
        }
        isReturn=false
      }else{
        $.ajax({
          url: '/scouts/international_scout_trackman_reports/check_duplicate',
          method: 'POST',
          async: false,
          data: { "player_id": current_player, "season": current_season},
          success: function (data){
            if(data['duplicate']>=1){
              alert('같은 시즌의 리포트가 이미 존재합니다.')
              event.preventDefault()
              isReturn=false
            }else{
              $(this).attr('disabled', 'disabled');
              $('#scouts_international_scout_trackman_reports_form').addClass('cursor-wait');
              $('#scouts_international_scout_trackman_reports_form').addClass('animate-pulse');
              $('#scouts_international_scout_trackman_reports_form').removeClass('hover:bg-blue-500');
              $(this).val('업로드중...');
            }
          },
        }); 
      }

      return isReturn
    })
  }

  upload_trigger(event){
    var multiUploadInput = document.querySelector("#report-upload-input-temp");
    multiUploadInput.click(); // this will trigger the click event
  }

  displayFile(){
    var reportUploadInput = document.querySelector("#report-upload-input");
    var reportUploadInputTemp = document.querySelector("#report-upload-input-temp");
    var div_uploadReport  = document.querySelector("#upload-report");
    var multiUploadFiles = reportUploadInput.files;
    var multiUploadTempFiles = reportUploadInputTemp.files;

    var existsFileList = []
    var fileBuffer = new DataTransfer();
    Object.keys(multiUploadFiles).forEach(function (key, index) {
      let file = multiUploadFiles[key];
      fileBuffer.items.add(file);
      existsFileList.push(file.name)
    })

    Object.keys(multiUploadTempFiles).forEach(function (key, index) {
      let file = multiUploadTempFiles[key];
      if (existsFileList.includes(file.name)==false){
        fileBuffer.items.add(file);
      }
    })

    reportUploadInput.files = fileBuffer.files;

    if (reportUploadInput.files.length>0) {

      div_uploadReport.innerHTML=null

      let files = reportUploadInput.files;
      Object.keys(files).forEach(function (key, index) {
        let file = files[key];
        var strHTML = `
                      <div id='report-file' filename='${file.name}' class="border border-gray-300 flex items-center">
                        <span id="report-upload-text" class="px-2 text-lg">${file.name}</span>
                        <button id="report-upload-delete" class="grid place-items-center" type='button' data-action='click->international-scout-trackman-report#removeMultiUpload'>
                          <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-red-700 w-5 h-5"
                                viewBox="0 0 320 512">
                              <path
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                          </svg>
                        </button>
                      </div>
                      `
        div_uploadReport.insertAdjacentHTML('beforeend', strHTML );
      })
    }
  }

  removeMultiUpload(event) {
    var reportList = document.getElementById('upload-report')
    var eachFile = document.getElementById("report-file")
    var filename = eachFile.getAttribute('filename')
    
    var multiUploadFiles = document.getElementById('report-upload-input').files;
    var fileBuffer = new DataTransfer();

    Object.keys(multiUploadFiles).forEach(function (key, index) {
      let file = multiUploadFiles[key];
      if (file.name!=filename){
        fileBuffer.items.add(file);
      }
    })
    document.querySelector("#report-upload-input").files = fileBuffer.files;
    reportList.removeChild(eachFile);
  }

  // edit
  edit_trigger(event){
    var editUploadInput = document.getElementById("edit-upload-input");
    editUploadInput.click(); // this will trigger the click event
  }

  displayChangedFile(event){
    var changedFileInput = document.getElementById("edit-upload-input");
    var divChangedFiletext = document.getElementById("edit-upload-text");
    var deleteButton = document.getElementById("edit-upload-delete");
    var changedFile = changedFileInput.files;

    var fileBuffer = new DataTransfer();
    Object.keys(changedFile).forEach(function (key, index) {
      let file = changedFile[key];
      fileBuffer.items.add(file);
    })
    changedFileInput.files = fileBuffer.files;

    if (changedFileInput.files.length>0) {
      divChangedFiletext.innerHTML=changedFileInput.files[0].name
      deleteButton.classList.remove("hidden");
    }
  }

  removeChangedFile(event){
    var divChangedFiletext = document.getElementById("edit-upload-text");
    var deleteButton = document.getElementById("edit-upload-delete");

    divChangedFiletext.innerHTML=null
    document.getElementById("edit-upload-input").value = null;
    deleteButton.classList.add("hidden");
  }

  newWindowReport(event){
    const reportSrc = document.getElementById('divOpenReport').getAttribute("src")
    const reportId = document.getElementById('divOpenReport').getAttribute("report-id")
    
    $.ajax({
      url: '/scouts/international_scout_trackman_reports/open_reports',
      method: 'GET',
      async: false,
      data: { "international_scout_trackman_report_id": reportId},
      success: function (data){
        window.open(reportSrc, '_blank'); 
      },
    });
  }

  showReport(event){
    const season = event.target.id
    const reportId = event.target.getAttribute("report-id")

    if ($(`#report_${season}`).is(":visible")==true){
      $(`#report_${season}`).fadeOut()
      $(`#report_${season}`).hide() 
    }
    else{
      $(`#report_${season}`).fadeIn()
      $(`#report_${season}`).show()
    }

    $.ajax({
      url: '/scouts/international_scout_trackman_reports/open_reports',
      method: 'GET',
      async: false,
      data: { "international_scout_trackman_report_id": reportId},
    });
  }

}
// function setPlayerOptions(wrapper, playerList){
//   let player_select = wrapper.querySelector('.player-select')
//   player_select.innerHTML = ''
//   for (let item of playerList['players']) {

//       var opt = document.createElement('option');
//       opt.value = item['player_id'];
//       if (['None', null].includes(item['back_number'])){
//         opt.innerHTML = `${item['name_full_eng']}(${item['birthdate']})`;
//       } else {
//         opt.innerHTML = `#${item['back_number']} - ${item['name_full_eng']}(${item['birthdate']})`;
//       }
      
    
//       player_select.appendChild(opt)
//   }
// }