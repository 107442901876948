import { Controller } from "stimulus";
import Plyr from "plyr";

export default class extends Controller {
  static targets = ["playlist"];

  connect() {
    this.player = new Plyr("#player");
    this.nextIndex = 0;
  }

  disconnect() {
    this.clearPlaylist();
    this.stop();
    this.player.destroy();
  }

  open(playlist) {
    const listContainer = this.playlistTarget;
    this.clearPlaylist();
    playlist.forEach((_, i) => {
      listContainer.appendChild(
        this.createPlaylistItem(playlist, i),
        this.playlistTarget
      );
    });
    this.player.on("ended", () => {
      if (this.nextIndex != -1) {
        this.setSource(playlist, this.nextIndex);
      }
    });
    this.setSource(playlist, 0);
  }

  stop() {
    this.player.stop();
  }

  setSource(playlist, index) {
    if (this.nextIndex > playlist.length) {
      this.nextIndex = -1;
      return;
    }
    this.nextIndex = index + 1;

    const video = playlist[index];
    this.setActivePlaylistItem(this.playlistTarget.children.item(index));
    this.player.source = {
      type: "video",
      sources: [
        {
          src: video.url,
          type: "video/mp4"
        }
      ]
    };
    this.player.play();
  }

  createPlaylistItem(playlist, index) {
    const video = playlist[index];
    const title = `${video.game_date}: ${video.awayTeam} @ ${video.homeTeam}`;
    const span = document.createElement("span");
    span.setAttribute("class", "truncate");
    span.appendChild(document.createTextNode(title));

    const listItem = document.createElement("a");
    listItem.appendChild(span);
    listItem.setAttribute("href", "javascript:void(0)");
    listItem.addEventListener("click", () => {
      this.setSource(playlist, index);
      // this.prefetchVideo(nextVideo);
    });
    return listItem;
  }

  prefetchVideo(video) {
    if (video === null) return;

    fetch(video.url, { mode: "no-cors" }).then(resp => console.log(resp));
  }

  setActivePlaylistItem(listItem) {
    const inactiveStyle =
      "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150";
    const activeStyle =
      "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-100 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";
    const parent = document.getElementById("video-playlist");
    parent.childNodes.forEach(child =>
      child.setAttribute("class", inactiveStyle)
    );
    if (listItem !== null) {
      listItem.setAttribute("class", activeStyle);
    }
  }

  clearPlaylist() {
    const parent = document.getElementById("video-playlist");
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }
}
