import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class jump{
    
    // init
    constructor(chartData){
        this.chartData = chartData;
        this.jump_list=[
            'counter_movement_jump', 
            'squat_jump', 
        ]

        this.label_hip_list=[
            '반동점프(CMJ)',
            '무반동점프(SJ)'
        ]
        this.max_value_list=[
            this.chartData['screening_max_value']['counter_movement_jump'],
            this.chartData['screening_max_value']['squat_jump'],
        ]
        // this.unit_annotations={
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.05,
        //     xanchor: 'right',
        //     y: 1.04,
        //     yanchor: 'bottom',
        //     text: '단위 : [cm]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // }
        this.rankCounts = this.chartData['player_info']['position_number']==1 ? this.chartData['screening_rank_counts']['screening_pitcher_counts'] : this.chartData['screening_rank_counts']['screening_fielder_counts']
        this.annotationList=[
            // this.unit_annotations
        ]
        this.rankMonthDict={};
        this.playerRankMonthDict={};
        this.getRankList()
    };

    //function
    drawChart(){
        var force_max_value = this.chartData['screening_max_value']['counter_movement_jump']*1.2;
        var rfd_max_value = this.chartData['screening_max_value']['squat_jump']*1.2;
        var layout = {
            barmode: 'group',
            yaxis:{
                fixedrange:true,
                ticksuffix: 'cm',
                range: [0, force_max_value],
                type: 'linear'
            },
            yaxis2:{
                fixedrange:true,
                // ticksuffix: 'cm',
                range: [0, rfd_max_value],
                type: 'linear'
            },
            xaxis:{  
                fixedrange:true,
                min:0,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },
            automargin:true,
            autosize:true
        }
        var data_list = this.getDataset()

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }
        Plotly.newPlot('result_jump', data_list, layout, config );
        var graphDiv = document.getElementById('result_jump')
        var shapeList = this.getAverageShape(graphDiv)
        var rankShapeList = this.getRankShapes(graphDiv)
        shapeList = shapeList.concat(rankShapeList)
        graphDiv.layout['shapes']=shapeList
        graphDiv.layout['annotations'] = this.annotationList
        Plotly.redraw(graphDiv);

    };

    getDataset(){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = this.jump_list
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = this.getTrace(category_list, month, monthAverageData, monthIndex)
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };

    getTrace(category_list, monthData, monthAverageData, monthIndex){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let value = monthData[category]==null ? '' : parseFloat(monthData[category]).toFixed(1)
            dataList.push(value)
            let averageValue = monthAverageData[category]==null ? '' : parseFloat(monthAverageData[category]).toFixed(1)
            averageDataList.push(`평균 : ${averageValue}cm`)
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                name : monthData['screening_month'],
                type:'bar',
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };

            return trace
        }
        else{
            return null
        }

    };

    getAverageShape(graph_div){
        var annotation_list = [];
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of this.jump_list.entries()){
                var x0 = graph_div.calcdata[monthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[monthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                if (averageValue!=null){
                    const annotation = {
                        type:'line',
                        layer:'below',                        
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            // text: `AVG:${averageValue}`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    annotation_list.push(annotation)
                }

            }
        }
        return annotation_list
    }

    getRankList(){
        for (let month of this.rankCounts) {
            var screeningMonth = month['screening_month']
            this.rankMonthDict[screeningMonth] = month
        }
        for (let month of this.chartData['player_rank']){
            var screeningMonth = month['screening_month']
            this.playerRankMonthDict[screeningMonth] = month
        }
    }

    getRankShapes(graphDiv){
        var shapeList = [];
        var rankMonths = Object.keys(this.rankMonthDict);
        var playerRankMonths = Object.keys(this.playerRankMonthDict);
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var screeningMonth = graphDiv.calcdata[monthIndex][0]['trace']['name']
            if (rankMonths.includes(screeningMonth) & playerRankMonths.includes(screeningMonth)){
                for (let [categoryIndex, category] of this.jump_list.entries()){
                    var x0 = graphDiv.calcdata[monthIndex][categoryIndex].p0+0.01;
                    var x1 = graphDiv.calcdata[monthIndex][categoryIndex].p1-0.01;
                    var screeningCount = this.rankMonthDict[screeningMonth][category]
                    var playerRank = this.playerRankMonthDict[screeningMonth][category]
                    const annotation = {
                        type:'line',                
                        layer:'above',
                        line:{
                            width:0,
                            color: 'rbga(0,0,0,1)'
                        },
                        opacity: 1,
                        x0: x0,
                        x1: x1,
                        y0: 0,
                        y1: 0,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            text: `(${playerRank}/${screeningCount})`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    shapeList.push(annotation)
                }
            }
        }
        return shapeList
    }
};