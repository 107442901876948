import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Controller } from "stimulus";
import html2canvas from 'html2canvas';
import Plotly from 'plotly.js-dist';

export default class extends Controller {

    static targets = [ "links", "template" ]

    add_opp_team_info(event) {
        const content = this.templateTarget.innerHTML.replace(/NEW_OPP_TEAM_INFO/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML("beforebegin", content)
    }

    remove_opp_team_info(event) {
        const wrapper = event.target.closest(".series-team-info")
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
        }
        else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = "none"
        }
    }

    newWindowReport(event){
        const reportTagId = event.target.id
        const reportSrc = document.getElementById(reportTagId).getAttribute("src")
        const reportId = document.getElementById(reportTagId).getAttribute("report-id")
        window.open(reportSrc, '_blank'); 
        // $.ajax({
        //     url: '/scouts/international_scout_trackman_reports/open_reports',
        //     method: 'GET',
        //     async: false,
        //     data: { "international_scout_trackman_report_id": reportId},
        //     success: function (data){
        //     window.open(reportSrc, '_blank'); 
        //     },
        // });
    }

    show_total_report_sector(event){
        var sector_total_report = document.getElementById('sector_total_report');
        sector_total_report.style.display = 'block';
        var sector_pitcher_report = document.getElementById('sector_pitcher_report');
        sector_pitcher_report.style.display = 'none';
        var sector_batter_report = document.getElementById('sector_batter_report');
        sector_batter_report.style.display = 'none';        
    }

    show_pitcher_report_sector(event){
        var sector_total_report = document.getElementById('sector_total_report');
        sector_total_report.style.display = 'none';
        var sector_pitcher_report = document.getElementById('sector_pitcher_report');
        sector_pitcher_report.style.display = 'block';
        var sector_batter_report = document.getElementById('sector_batter_report');
        sector_batter_report.style.display = 'none';        
    }

    show_batter_report_sector(event){
        var sector_total_report = document.getElementById('sector_total_report');
        sector_total_report.style.display = 'none';
        var sector_pitcher_report = document.getElementById('sector_pitcher_report');
        sector_pitcher_report.style.display = 'none';
        var sector_batter_report = document.getElementById('sector_batter_report');
        sector_batter_report.style.display = 'block';        
    }
};