// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
  initialize(){
    $('.comment').each(function(){
      //scroll height가 client height의 길이를 뛰어넘는 지점에서
      // if (elem.clientHeight < elem.scrollHeight)
      if(this.clientHeight < this.scrollHeight){
        $(this).height(this.scrollHeight)
      }
    })
  }

  connect() {
    $(window).on('resize', function(){
        $('.comment').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
    });
  }

  show_batting_coach_month_evaluation(event){
    var button_batter_review = document.getElementById('button_sector_batting_coach_month_evaluation');
    var button_pitcher_review = document.getElementById('button_sector_pitching_coach_month_evaluation');

    if ($('#button_sector_batting_coach_month_evaluation').hasClass('bg-black text-white')==false){
      button_batter_review.classList.replace('bg-white', 'bg-black');
      button_batter_review.classList.replace('text-black', 'text-white');
    }
    button_pitcher_review.classList.replace('bg-black', 'bg-white');
    button_pitcher_review.classList.replace('text-white', 'text-black');

    var sector_batter_review = document.getElementById('sector_batting_coach_month_evaluation');
    sector_batter_review.style.display = 'block';
    var sector_pitcher_review = document.getElementById('sector_pitching_coach_month_evaluation');
    sector_pitcher_review.style.display = 'none';

    $('.comment').each(function(){
      //scroll height가 client height의 길이를 뛰어넘는 지점에서
      // if (elem.clientHeight < elem.scrollHeight)
      if(this.clientHeight < this.scrollHeight){
        $(this).height(this.scrollHeight)
      }
    })
  }
  
  show_pitching_coach_month_evaluation(event){
    var button_batter_review = document.getElementById('button_sector_batting_coach_month_evaluation');
    var button_pitcher_review = document.getElementById('button_sector_pitching_coach_month_evaluation');
    if ($('#button_sector_pitching_coach_month_evaluation').hasClass('bg-black text-white')==false){
      button_pitcher_review.classList.replace('bg-white', 'bg-black');
      button_pitcher_review.classList.replace('text-black', 'text-white');
    }
    button_batter_review.classList.replace('bg-black', 'bg-white');
    button_batter_review.classList.replace('text-white', 'text-black');
    
    var sector_batter_review = document.getElementById('sector_batting_coach_month_evaluation');
    sector_batter_review.style.display = 'none';
    var sector_pitcher_review = document.getElementById('sector_pitching_coach_month_evaluation');
    sector_pitcher_review.style.display = 'block';

    $('.comment').each(function(){
      //scroll height가 client height의 길이를 뛰어넘는 지점에서
      // if (elem.clientHeight < elem.scrollHeight)
      if(this.clientHeight < this.scrollHeight){
        $(this).height(this.scrollHeight)
      }
    })
  }
    
  setHeight(event){
    const wrapper = event.target
    wrapper.style.height = 'auto'
    wrapper.style.height=wrapper.scrollHeight+"px"
  }

}