import { Controller } from "stimulus";
export default class extends Controller {
  initialize(){
    $('.transaction-comment').each(function(){
      //scroll height가 client height의 길이를 뛰어넘는 지점에서
      // if (elem.clientHeight < elem.scrollHeight)
      if(this.clientHeight < this.scrollHeight){
        $(this).height(this.scrollHeight)
      }
    })
  }

  connect() { 
    $(window).on('resize', function(){
      $('.transaction-comment').each(function(){
        $(this).css('height', 'auto' );
        $(this).height(this.scrollHeight)
      })
    });
  }

  setHeight(event){
    const wrapper = event.target
    wrapper.style.height = 'auto'
    wrapper.style.height=wrapper.scrollHeight+"px"
  }
  check_data(event){
    var transaction_opinion_kind = $('#transaction_opinion_kind').val()
    if(transaction_opinion_kind==''){
      alert('코멘트 항목을 입력해주세요')
      event.preventDefault()
    } else {
      alert('코멘트 등록 완료')
    }
  }
  updateComment(event){
    var transaction_opinion_kind = $('#transaction_opinion_kind').val()
    if(transaction_opinion_kind==''){
      alert('코멘트 확인해주세요')
      event.preventDefault()
    } else {
      alert('코멘트 수정 완료')
    }
  }
}
