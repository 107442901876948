// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("local-time").start();
require("channels");
require("typeface-inter");
require("alpinejs");
require("stylesheets/application.scss");
require("jquery");
require("awesomplete");
require("pickadate");
require("tablesorter");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
// const imagePath = name => images(name, true);

import "easy-autocomplete/dist/jquery.easy-autocomplete";
import "chartkick/chart.js";
import "controllers";

import "../viz/pitch_scatter";
import "../viz/velo_density";
import "../viz/mobile_video";
import "../search/player_search";
import "../viz/daily_comment";
import "../viz/rookie_comment";
import "../viz/download_image";
import "../viz/pref_search";
import "../viz/nominates_search";
import "../viz/biomechanics";
import "../viz/scout_comment";
import "../viz/scout_calender";
import "../viz/portable_trackman";
import "../viz/advance_report_table_switch";
import "../viz/videos_by_games";
import "../viz/advance_scout_report_autosave";
import "../viz/profile_bar_chart";
import "../viz/player_plan_players";
import "../viz/pr_dropdowns";
import "../viz/leaderboard_sort";

// import "../transactions/transactions"
// variables