import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
  let default_inning = ''

  $('div[id^="menu_videos_by_games_"]').on('click',function(){
    $('#menu_videos_by_games_' + default_inning).toggleClass('bg-giants-blue text-white')
    var inning_selected = $(this).data('inning')
    $('#menu_videos_by_games_' + inning_selected).toggleClass('bg-giants-blue text-white')
    $('#videos_by_games_'+default_inning+'_content').hide()
    default_inning = inning_selected
    $('#videos_by_games_'+inning_selected+'_content').show()
  })

  $('button[id="videos_by_games_open"]').on('click',function(){
    $(this).parent().parent().next().slideToggle(200)
    $(this).children().toggle()
  })

  const dimensions = {
    margin: {top: 10, right: 10, bottom: 10, left: 10},
    width: 180,
    height: 180
  }
});