import * as d3 from "d3";
import _ from "lodash";

export default function exitVelo(
  chartContainerId,
  tableContainerId,
  pitches,
  byPitchType,
  projections,
  batter_stats
){

	const dimensions = {
    margin: { top: 10, right: 30, bottom: 30, left: 60 },
    width: 400,
    height: 400
  };
  	const byExitVelo = _.groupBy(pitches, p=>p.exit_velo);
	var svg = drawCanvas(chartContainerId, dimensions);
	var scales = getScale(svg, dimensions, pitches);
	var temp = Array()

	for (const pitch in pitches){
		if(pitches[pitch].exit_speed==null){}
		else{
			temp.push(pitches[pitch])
		}
	}

	const kde = kernelDensityEstimator(kernelEpanechnikov(2), scales.x.ticks(100));
  	const density = kde(temp.map(d => d.exit_speed));
	plotDensity(svg, scales.x, scales.y, density)

	console.log(d3.max(density.map(d => d[1])))
	function drawCanvas(chartContainerId, dimensions){
		const { height, width, margin } = dimensions;
		console.log(chartContainerId)
		var svg = d3.select(chartContainerId)
					.append("svg")
					.attr("width", width + margin.left + margin.right)
					.attr("height", height + margin.top + margin.bottom)
					.append("g")
					.attr("transform",
							"translate(" + margin.left + "," + margin.top + ")");
		return svg
	}

	function getScale(svg, dimensions, density){
		
		const { height, width, margin } = dimensions;
		var xScale = d3.scaleLinear()
				.domain([0,200])
				.range([0,width]);
		svg.append("g")
			.attr("transform", "translate(0," + height + ")")
			.call(d3.axisBottom(xScale));

		var yScale = d3.scaleLinear()
					.range([height, 0])
					.domain([0, 0.03]);
					
		svg.append("g")
			.call(d3.axisLeft(yScale));

		return {x: xScale, y: yScale}
	}


	function plotDensity(svg, x, y, denstiy){

		var gradientColor = (p) => {
			console.log(p[0])
          if(p[0]>=155){
            return 'blue'
          }
          else{
            return 'none'
          }
        };

		svg.append("path")
			.datum(density)
			.attr("fill","blue")
			.attr("opacity",".8")
			.attr("stroke",p => gradientColor(p))
			.attr("stroke-width",1)
			.attr("stroke-linejoin", "round")
			.attr("d", d3.line()
						 .curve(d3.curveBundle.beta(1))
						 .x(function(d){return x(d[0])})
						 .y(function(d){return y(d[1])})
			);

	}
}
function kernelDensityEstimator(kernel, X) {
  return function(V) {
    return X.map(function(x) {
      return [
        x,
        d3.mean(V, function(v) {
          return kernel(x - v);
        })
      ];
    });
  };
}

function kernelEpanechnikov(k) {
  return function(v) {
    return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
  };
}