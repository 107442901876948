import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
	$('#position_catcher').click(function(){
		$('#catcher').show()
		$('#infielder').hide()
		$('#outfielder').hide()
		$('#righty').hide()
		$('#lefty').hide()
	});
	$('#position_infielder').click(function(){
		$('#catcher').hide()
		$('#infielder').show()
		$('#outfielder').hide()
		$('#righty').hide()
		$('#lefty').hide()
	});
	$('#position_outfielder').click(function(){
		$('#catcher').hide()
		$('#infielder').hide()
		$('#outfielder').show()
		$('#righty').hide()
		$('#lefty').hide()
	});
	$('#position_righty').click(function(){
		$('#catcher').hide()
		$('#infielder').hide()
		$('#outfielder').hide()
		$('#righty').show()
		$('#lefty').hide()
	});
	$('#position_lefty').click(function(){
		$('#catcher').hide()
		$('#infielder').hide()
		$('#outfielder').hide()
		$('#righty').hide()
		$('#lefty').show()
	});

	$(document).on('click','button[id^="pref_last_"]',function(){
    	let parent_id = $(this).closest('ul').attr('id');
    	var item = $(this).parent().parent().detach();
    	if(parent_id == 'pref_sortable'){$("#pref_sortable").append(item);}
    	else{$("#pref_reports_list").append(item);}
    	newOrder()
  	});

  	function newOrder(){
    for (let n of $("#pref_reports_list").children()){
      let formdata = new FormData()
      formdata.append("report_player_id",$(n).data("rpid").toString())
      formdata.append("new_ranking","0")
      $(n).find('span:eq(0)').text($(n).index()+1);
      Rails.ajax({
        url: "/preflists/1/newplace",
        type: "PATCH",
        data: formdata
      })
    }

    for (let n of $("#pref_sortable").children()){
      let formdata = new FormData()
      formdata.append("report_player_id",$(n).data("rpid").toString())
      formdata.append("new_ranking",($(n).index()+1).toString())
      $(n).find('span:eq(0)').text($(n).index()+1);
      Rails.ajax({
        url: "/preflists/1/newplace",
        type: "PATCH",
        data: formdata
      })
    }
  }
});