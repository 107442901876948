import { Controller } from "stimulus"

export default class extends Controller {
    
    defaultSeason = document.getElementById('player-plan-stat').dataset.defaultSeason
    defaultLevel = document.getElementById('player-plan-stat').dataset.defaultLevel
    defaultPosition = document.getElementById('player-plan-stat').dataset.positionId

    fetchData(season, level) {

        const doc_player_plan_stat = document.getElementById('player-plan-stat');

        const queryParams = [
            `player_id=${doc_player_plan_stat.dataset.playerId}`,
            `season=${season}`,
            `level=${level}`,
            `position=${doc_player_plan_stat.dataset.positionId}`,
        ];

        fetch(`/player_plan_stat?${queryParams.join("&")}`)
            .then(resp => resp.json())
            .then(data => {
                this.tableData = data;
                this.drawTable();
            }
        );
    }

    setSelected(category='season'){
        const select = category=='season' ? document.getElementById("player_plan_stat_year") : document.getElementById("player_plan_stat_level")
        const selected_value = category=='season' ? this.defaultSeason : this.defaultLevel 
        for (let i=0; i<select.options.length; i++){
            if(select.options[i].value == selected_value){
                select.options[i].selected = true;
            }
        }
    }

    changeSeason(event) {
        event.preventDefault();
        const season = event.target.value;
    
        this.defaultSeason = season;
    
        this.setSelected('season');
        this.fetchData(season, this.defaultLevel);
    }

    changeLevel(event) {
        event.preventDefault();
        const level = Number(event.target.value);

        this.defaultLevel = level;
        
        this.setSelected('level');
        this.fetchData(this.defaultSeason, level);
    }
    
    drawTable(event){
        event?.preventDefault();
        this.clearTable();

        if (this.tableData.hasOwnProperty('player_standard_stat')){
            this.setStandardTable();
        }

        if (this.tableData.hasOwnProperty('player_counting_stat')){
            this.setCountingTable();
        }

        if (this.tableData.hasOwnProperty('player_tracking')){
            this.setTrackingTable();
        }
    };

    clearTable(event){
        document.getElementById("player_plan_standard_body").innerHTML = '';
        document.getElementById("player_plan_counting_body").innerHTML = '';
        document.getElementById("player_plan_tracking_body").innerHTML = '';
    }

    createTableData(value){
        const table_data_stat = document.createElement("td");
        table_data_stat.innerHTML = value;
        return table_data_stat
    };

    setStandardTable(){

        const table_element = document.getElementById('player_plan_standard_body');
        const table_row_standard_stat = document.createElement("tr");
        let value = this.tableData['player_standard_stat']

        if (this.defaultPosition==1){

            const table_data_game = this.createTableData(value['g'])
            const table_data_game_started = this.createTableData(value['gs'])
            const table_data_ip = this.createTableData(value['ip'])
            const table_data_era = this.createTableData(value['era'])
            const table_data_fip = this.createTableData(value['fip'])
            const table_data_hr_rate = this.createTableData(value['hr_rate'])
            const table_data_ubb_rate = this.createTableData(value['ubb_rate'])
            const table_data_k_rate = this.createTableData(value['k_rate'])

            table_row_standard_stat.appendChild(table_data_game)
            table_row_standard_stat.appendChild(table_data_game_started)
            table_row_standard_stat.appendChild(table_data_ip)
            table_row_standard_stat.appendChild(table_data_era)
            table_row_standard_stat.appendChild(table_data_fip)
            table_row_standard_stat.appendChild(table_data_hr_rate)
            table_row_standard_stat.appendChild(table_data_ubb_rate)
            table_row_standard_stat.appendChild(table_data_k_rate)

        }

        else{

            const table_data_game = this.createTableData(value['g'])
            const table_data_avg = this.createTableData(value['avg'])
            const table_data_obp = this.createTableData(value['obp'])
            const table_data_slg = this.createTableData(value['slg'])
            const table_data_ops = this.createTableData(value['ops'])
            const table_data_ubb_rate = this.createTableData(value['ubb_rate'])
            const table_data_k_rate = this.createTableData(value['k_rate'])

            table_row_standard_stat.appendChild(table_data_game)
            table_row_standard_stat.appendChild(table_data_avg)
            table_row_standard_stat.appendChild(table_data_obp)
            table_row_standard_stat.appendChild(table_data_slg)
            table_row_standard_stat.appendChild(table_data_ops)
            table_row_standard_stat.appendChild(table_data_ubb_rate)
            table_row_standard_stat.appendChild(table_data_k_rate)
        }

        table_element.appendChild(table_row_standard_stat)
    }

    setCountingTable(){

        const table_element = document.getElementById('player_plan_counting_body');
        const table_row_counting_stat = document.createElement("tr");
        let value = this.tableData['player_counting_stat']

        if (this.defaultPosition==1){

            const table_data_hit = this.createTableData(value['h'])
            const table_data_run = this.createTableData(value['r'])
            const table_data_earned_run = this.createTableData(value['er'])
            const table_data_homerun = this.createTableData(value['hr'])
            const table_data_bb = this.createTableData(value['bb'])
            const table_data_k = this.createTableData(value['k'])
            const table_data_hbp = this.createTableData(value['hbp'])

            table_row_counting_stat.appendChild(table_data_hit)
            table_row_counting_stat.appendChild(table_data_run)
            table_row_counting_stat.appendChild(table_data_earned_run)
            table_row_counting_stat.appendChild(table_data_homerun)
            table_row_counting_stat.appendChild(table_data_bb)
            table_row_counting_stat.appendChild(table_data_k)
            table_row_counting_stat.appendChild(table_data_hbp)

        }

        else{

            const table_data_pa = this.createTableData(value['pa'])
            const table_data_ab = this.createTableData(value['ab'])
            const table_data_h = this.createTableData(value['h'])
            const table_data_doubles = this.createTableData(value['doubles'])
            const table_data_hr = this.createTableData(value['hr'])
            const table_data_rbi = this.createTableData(value['rbi'])
            const table_data_bb = this.createTableData(value['bb'])
            const table_data_k = this.createTableData(value['k'])
            const table_data_sb = this.createTableData(value['sb'])
            const table_data_cs = this.createTableData(value['cs'])
            const table_data_gdp = this.createTableData(value['gdp'])

            table_row_counting_stat.appendChild(table_data_pa)
            table_row_counting_stat.appendChild(table_data_ab)
            table_row_counting_stat.appendChild(table_data_h)
            table_row_counting_stat.appendChild(table_data_doubles)
            table_row_counting_stat.appendChild(table_data_hr)
            table_row_counting_stat.appendChild(table_data_rbi)
            table_row_counting_stat.appendChild(table_data_bb)
            table_row_counting_stat.appendChild(table_data_k)
            table_row_counting_stat.appendChild(table_data_sb)
            table_row_counting_stat.appendChild(table_data_cs)
            table_row_counting_stat.appendChild(table_data_gdp)            
        }

        table_element.appendChild(table_row_counting_stat)
    }

    setTrackingTable(){
        
        const table_element = document.getElementById('player_plan_tracking_body');
        let tracking_data = this.tableData['player_tracking']

        if (tracking_data.length>0){

            if (this.defaultPosition==1){
         
                for (const [index, value] of tracking_data.entries() ){

                    const table_row_tracking_stat = document.createElement("tr");
    
                    const table_data_tracking_pitch_type = this.createTableData(value['pitch_type'])
                    const table_data_tracking_speed = this.createTableData(parseFloat(value['speed']).toFixed(1))
                    const table_data_tracking_vertical_movement = this.createTableData(parseFloat(value['v_mov']).toFixed(1))
                    const table_data_tracking_horizontal_movement = this.createTableData(parseFloat(value['h_mov']).toFixed(1))
                    const table_data_tracking_spin_rate = this.createTableData(parseFloat(value['spin_rate']).toFixed(0))
                    const table_data_tracking_swing_percent = this.createTableData(value['swing_percent'].toFixed(1))
                    const table_data_tracking_miss_percent = this.createTableData(value['miss_percent'].toFixed(1))
                    const table_data_tracking_chase_percent = this.createTableData(value['chase_percent'].toFixed(1))
                    const table_data_tracking_in_zone_percent = this.createTableData(value['in_zone_percent'].toFixed(1))
    
                    table_row_tracking_stat.appendChild(table_data_tracking_pitch_type)
                    table_row_tracking_stat.appendChild(table_data_tracking_speed)
                    table_row_tracking_stat.appendChild(table_data_tracking_vertical_movement)
                    table_row_tracking_stat.appendChild(table_data_tracking_horizontal_movement)
                    table_row_tracking_stat.appendChild(table_data_tracking_spin_rate)
                    table_row_tracking_stat.appendChild(table_data_tracking_swing_percent)
                    table_row_tracking_stat.appendChild(table_data_tracking_miss_percent)
                    table_row_tracking_stat.appendChild(table_data_tracking_chase_percent)
                    table_row_tracking_stat.appendChild(table_data_tracking_in_zone_percent)

                    table_element.appendChild(table_row_tracking_stat)
    
                };
            }

            else{
                for (const [index, value] of tracking_data.entries() ){

                    const table_row_tracking_stat = document.createElement("tr");

                    const table_data_tracking_pitch_type = this.createTableData(value['pitch_type'])
                    const table_data_tracking_avg_ev = this.createTableData(value['avg_ev'].toFixed(1))
                    const table_data_tracking_avg_angle = this.createTableData(value['avg_angle'].toFixed(1))
                    const table_data_tracking_hard_hit_rate = this.createTableData(value['hard_hit_rate'].toFixed(1))
                    const table_data_tracking_heart_swing_rate = this.createTableData(value['heart_swing_rate'].toFixed(1))
                    const table_data_tracking_chase_rate = this.createTableData(value['chase_rate'].toFixed(1))

                    table_row_tracking_stat.appendChild(table_data_tracking_pitch_type)
                    table_row_tracking_stat.appendChild(table_data_tracking_avg_ev)
                    table_row_tracking_stat.appendChild(table_data_tracking_avg_angle)
                    table_row_tracking_stat.appendChild(table_data_tracking_hard_hit_rate)
                    table_row_tracking_stat.appendChild(table_data_tracking_heart_swing_rate)
                    table_row_tracking_stat.appendChild(table_data_tracking_chase_rate)

                    table_element.appendChild(table_row_tracking_stat)

                }
            }
        }
    }
}
