import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Controller } from "stimulus";
import html2canvas from 'html2canvas';
import Plotly from 'plotly.js-dist';

import {radar} from "../screening/screening_result/chart_radar";
import {shoulderMMT} from "../screening/screening_result/chart_shoulder_mmt";
import {hip} from "../screening/screening_result/chart_hip";
import {yBalance} from "../screening/screening_result/chart_y_balance";
import {shoulderRom} from "../screening/screening_result/chart_shoulder_rom";
import {handGrip} from "../screening/screening_result/chart_hand_grip";
import {jump} from "../screening/screening_result/chart_jump";
import {pushUp} from "../screening/screening_result/chart_push_up";
import {fieldTestCommon, fieldTestPitcher, fieldTestPosition} from "../screening/screening_result/chart_field_test";

import yCrossLeft from "../images/screening/y_balance_cross_left.png";
import yCrossRight from "../images/screening/y_balance_cross_left.png";
// import yCrossRight from "../images/screening/y_balance_cross_right.png";
import ysideLeft from "../images/screening/y_balance_side_left.png";
import ySideRight from "../images/screening/y_balance_side_left.png";
// import ySideRight from "../images/screening/y_balance_side_right.png";
import yFrontLeft from "../images/screening/y_balance_front_left.png";
import yFrontRight from "../images/screening/y_balance_front_left.png";
// import yFrontRight from "../images/screening/y_balance_front_right.png";


export default class extends Controller {

  chart_label_dict={
    // 'neck_fx' : 'Neck-FX',
    'neck_fx' : '목 굽힘',
    // 'neck_ext' : 'Neck-EXT',
    'neck_ext': '목 폄',
    // 'neck_l_r' : 'Neck(L->R)',    
    'neck_l_r': '목 회전(왼->오)',
    // 'neck_r_l' : 'Neck(R->L)',    
    'neck_r_l': '목 회전(오->왼)',

    // 'shoulder_left_internal_rotation': 'Shoulder Internal Rotation(L)',
    'shoulder_left_internal_rotation': '어깨 내회전(왼)',
    // 'shoulder_right_internal_rotation': 'Shoulder Internal Rotation(R)',
    'shoulder_right_internal_rotation': '어깨 내회전(오)',
    // 'shoulder_left_external_rotation': 'Shoulder External Rotation(L)',
    'shoulder_left_external_rotation': '어깨 외회전(왼)',
    // 'shoulder_right_external_rotation': 'Shoulder External Rotation(R)',
    'shoulder_right_external_rotation': '어깨 외회전(오)',

    // 'overhead_squat': 'Overhead Squat',
    'overhead_squat': '오버헤드 스쿼트',

    // 'thoracic_rotation_left': 'Thoracic Rotation(L)',
    'thoracic_rotation_left': '흉추회전(왼)',
    // 'thoracic_rotation_right': 'Thoracic Rotation(R)',
    'thoracic_rotation_right': '흉추회전(오)',

    // 'trunk_flexion': 'Trunk Flexion',
    'trunk_flexion': '몸통 굽힘',

    // 'single_leg_calf_raise_left': 'Single Leg Calf Raise(L)',
    'single_leg_calf_raise_left': '카프레이즈(왼)',
    // 'single_leg_calf_raise_right': 'Single Leg Calf Raise(R)',
    'single_leg_calf_raise_right': '카프레이즈(오)',

    // 'y_balance_left' : 'Y-Balance(L)',
    'y_balance_left' : 'Y-밸런스(왼)',
    // 'y_balance_right' : 'Y-Balance(R)',
    'y_balance_right' : 'Y-밸런스(오)',

    // 'hip_left_internal_rotation' : 'Internal Rotation(L)',
    'hip_left_internal_rotation' : '내회전(왼)',
    // 'hip_left_external_rotation' : 'External Rotation(L)',
    'hip_left_external_rotation' : '외회전(왼)',
    // 'hip_right_internal_rotation': 'Internal Rotation(R)',
    'hip_right_internal_rotation': '내회전(오)',
    // 'hip_right_external_rotation': 'External Rotation(R)',
    'hip_right_external_rotation': '외회전(오)',

    // 'hip_right_external_rotation': 'Internal Rotation(R)',
    'd_internal_rotation' : '내회전(주동)',
    // 'hip_right_external_rotation': 'External Rotation(R)',
    'd_external_rotation' : '외회전(주동)', 
    // 'hip_right_external_rotation': 'Internal Rotation(R)',
    'nd_internal_rotation' : '내회전(비주동)',
    // 'hip_right_external_rotation': 'External Rotation(R)',
    'nd_external_rotation' : '외회전(비주동)',
    
    // 'd_seated_external_rotation': 'Seated External Rotation(D)',
    'd_seated_external_rotation': '어깨 외회전(주동/앉은자세)',
    // 'd_full_can': 'Full Can(D)',
    'd_full_can': '극상근 기능(주동)',
    // 'd_prone_internal_rotation': 'Prone Internal Rotation(D)',
    'd_prone_internal_rotation': '어깨 내회전(주동/엎드린자세)',
    // 'd_prone_external_rotation': 'Prone External Rotation(D)',
    'd_prone_external_rotation': '어깨 외회전(주동/엎드린자세)',

    // 'nd_seated_external_rotation': 'Seated External Rotation(ND)',
    'nd_seated_external_rotation': '어깨 외회전(비주동/앉은자세)',
    // 'nd_full_can': 'Full Can(ND)',
    'nd_full_can': '극상근 기능(비주동)',
    // 'nd_prone_internal_rotation': 'Prone Internal Rotation(ND)',
    'nd_prone_internal_rotation': '어깨 내회전(비주동/엎드린자세)',
    // 'nd_prone_external_rotation': 'Prone External Rotation(ND)',
    'nd_prone_external_rotation': '어깨 외회전(비주동/엎드린자세)',
    
    // 'y_balance_left_front': 'Front(L)',
    'y_balance_left_front': '앞방향(왼)',
    // 'y_balance_right_front': 'Front(R)',  
    'y_balance_right_front': '앞방향(오)',    
    // 'y_balance_left_side': 'Side(L)',
    'y_balance_left_side': '옆방향(왼)',
    // 'y_balance_right_side': 'Side(R)',
    'y_balance_right_side': '옆방향(오)',
    // 'y_balance_left_cross': 'Cross(L)',
    'y_balance_left_cross': '교차방향(왼)',
    // 'y_balance_right_cross': 'Cross(R)',
    'y_balance_right_cross': '교차방향(오)',

    // 'left_hand': 'Left',
    'left_hand': '왼손',
    // 'right_hand': 'Right',
    'right_hand': '오른손',

    // 'counter_movement_jump': 'CMJ',
    'counter_movement_jump': '반동점프(CMJ)',
    // 'squat_jump': 'Sqaut Jump',
    'squat_jump': '무반동점프(SJ)',
    
    'force': 'FORCE',
    'rfd': 'RFD',
    
    // 'field_test_sprint_10': 'Front(L)',
    'field_test_sprint_10': '스프린트(10m)',
    // 'field_test_sprint_30': 'Front(R)',  
    'field_test_sprint_30': '스프린트(30m)',    
    // 'field_test_medball_throws': 'Side(L)',
    'field_test_medball_throws': '메디신볼 던지기',

    // 'field_test_run_276': 'Side(R)',
    'field_test_run_276': '276m달리기',

    // 'y_balance_left_cross': 'Cross(L)',
    'field_test_three_feet': '3피트 달리기',
    // 'y_balance_right_cross': 'Cross(R)',
    'field_test_first_to_second': '베이스런닝(1루->2루)',
    // 'y_balance_left_cross': 'Cross(L)',
    'field_test_first_to_third': '베이스런닝(1루->3루)',
    // 'y_balance_right_cross': 'Cross(R)',
    'field_test_t_run': 'T-Run',    

  }

  chart_radar_list=[
    'neck_fx', 
    'neck_ext', 
    // 'neck_l_r', 
    // 'neck_r_l',

    'shoulder_left_internal_rotation', 
    'shoulder_left_external_rotation', 
    'shoulder_right_internal_rotation', 
    'shoulder_right_external_rotation',

    'overhead_squat',

    'thoracic_rotation_left', 
    'thoracic_rotation_right', 
    'trunk_flexion',

    'single_leg_calf_raise_left', 
    'single_leg_calf_raise_right',
    
    'y_balance_left',
    'y_balance_right'
  ]

  chart_hip_list=[
    'hip_left_internal_rotation', 
    'hip_left_external_rotation', 
    'hip_right_internal_rotation', 
    'hip_right_external_rotation'
  ]

  chart_shoulder_rom_list=[
    'd_internal_rotation',
    'd_external_rotation', 
    'nd_internal_rotation',
    'nd_external_rotation'
  ]

  chart_shoulder_mmt_d_list=[
    'd_seated_external_rotation',
    'd_full_can',
    'd_prone_internal_rotation', 
    'd_prone_external_rotation',
  ]

  chart_shoulder_mmt_nd_list=[
    'nd_seated_external_rotation', 
    'nd_full_can',  
    'nd_prone_internal_rotation', 
    'nd_prone_external_rotation'
  ]

  chart_y_balance_list = [
    'y_balance_left_front',
    'y_balance_right_front', 
    'y_balance_left_side', 
    'y_balance_right_side', 
    'y_balance_left_cross', 
    'y_balance_right_cross'
  ]

  chart_hand_grip_list = [
    'left_hand',
    'right_hand'
  ]

  chart_jump_list = [
    'counter_movement_jump',
    'squat_jump',
  ]

  chart_push_up_list = [
    'force',
    'rfd'
  ]

  chart_field_test_common_list = [
    'field_test_sprint_10',
    'field_test_sprint_30',
    'field_test_medball_throws',
  ]

  chart_field_test_pitcher_list = [
    'field_test_run_276'
  ]

  chart_field_test_position_player_list = [
    'field_test_three_feet',
    'field_test_first_to_second',
    'field_test_first_to_third',
    'field_test_t_run',    
  ]

  color_list = [
    'rgba(255, 0, 0, 0.4)', //red
    'rgba(255, 165, 0, 0.2)', //orange
    'rgba(0, 128, 0, 0.2)', // green
    'rgba(0, 0, 255, 0.2)', // blue
    'rgba(75, 0, 130, 0.2)', // navy
    'rgba(238, 130, 238, 0.2)' // purple
  ]

  radar_image_list = [].map(png => {
    const image = new Image();
    image.src = png;
    return image;
  });

  y_balance_image_list = [
    yFrontLeft,
    yFrontRight,
    ysideLeft,
    ySideRight,
    yCrossLeft,
    yCrossRight,
  ].map(png => {
    const image = new Image();
    image.src = png;
    return image;
  });

  background_color_list = [
    'rgba(255, 0, 0, 0.75)', //red
    'rgba(255, 165, 0, 0.75)', //orange
    'rgba(0, 128, 0, 0.75)', // green
    'rgba(0, 0, 255, 0.75)', // blue
    'rgba(75, 0, 130, 0.75)', // navy
    'rgba(238, 130, 238, 0.75)' // purple
  ]

  connect() {
    Chart.register(ChartDataLabels);
    Chart.register(annotationPlugin);



    // Get Screening Report
    $('#radar_explain').on('click',function() {
      if ($('#div_radar_images').is(":visible")==true){
        $('#div_radar_images').fadeOut()
        $('#div_radar_images').hide() 
      }
      else{
        $('#div_radar_images').fadeIn()
        $('#div_radar_images').show()
      }
      
    })

    $('#hip_note').on('click',function() {
      if ($('#div_hip_note').is(":visible")==true){
        $('#hip_note').text('NOTE 내역 ▼')
        $('#div_hip_note').fadeOut()
        $('#div_hip_note').hide() 
      }
      else{
        $('#hip_note').text('NOTE 내역 ▲')
        $('#div_hip_note').fadeIn()
        $('#div_hip_note').show()
      }
    })
    
    // Get Screening Report
    $('#y_balance_note').on('click',function() {
      if ($('#div_y_balance_note').is(":visible")==true){
        $('#y_balance_note').text('NOTE 내역 ▼')
        $('#div_y_balance_note').fadeOut()
        $('#div_y_balance_note').hide() 
      }
      else{
        $('#y_balance_note').text('NOTE 내역 ▲')
        $('#div_y_balance_note').fadeIn()
        $('#div_y_balance_note').show()
      }
    })
    
    // Get Screening Report
    $('#shoulder_rom_note').on('click',function() {
      if ($('#div_shoulder_rom_note').is(":visible")==true){
        $('#shoulder_rom_note').text('NOTE 내역 ▼')
        $('#div_shoulder_rom_note').fadeOut()
        $('#div_shoulder_rom_note').hide() 
      }
      else{
        $('#shoulder_rom_note').text('NOTE 내역 ▲')
        $('#div_shoulder_rom_note').fadeIn()
        $('#div_shoulder_rom_note').show()
      }
    })

    // Get Screening Report
    $('#shoulder_mnt_note').on('click',function() {
      if ($('#div_shoulder_mnt_note').is(":visible")==true){
        $('#shoulder_mnt_note').text('NOTE 내역 ▼')
        $('#div_shoulder_mnt_note').fadeOut()
        $('#div_shoulder_mnt_note').hide() 
      }
      else{
        $('#shoulder_mnt_note').text('NOTE 내역 ▲')
        $('#div_shoulder_mnt_note').fadeIn()
        $('#div_shoulder_mnt_note').show()
      }
    })

    // Get Screening Report
    $('#hand_grip_note').on('click',function() {
      if ($('#div_hand_grip_note').is(":visible")==true){
        $('#hand_grip_note').text('NOTE 내역 ▼')
        $('#div_hand_grip_note').fadeOut()
        $('#div_hand_grip_note').hide() 
      }
      else{
        $('#hand_grip_note').text('NOTE 내역 ▲')
        $('#div_hand_grip_note').fadeIn()
        $('#div_hand_grip_note').show()
      }
    })

    // Get Screening Report
    $('#field_test_note').on('click',function() {
      if ($('#div_field_test_note').is(":visible")==true){
        $('#field_test_note').text('NOTE 내역 ▼')
        $('#div_field_test_note').fadeOut()
        $('#div_field_test_note').hide() 
      }
      else{
        $('#field_test_note').text('NOTE 내역 ▲')
        $('#div_field_test_note').fadeIn()
        $('#div_field_test_note').show()
      }
    })


    this.player_position = $('#player_position').text()
    // const screening_year = document.getElementById('div_result_chart').getAttribute("data-screening-year");
    // const screening_month = document.getElementById('div_result_chart').getAttribute("data-screening-month");
    // const screening_id = document.getElementById('div_result_chart').getAttribute("data-screening-id");
  };

  showScreeningResult(){
    const elem_year_months = document.getElementsByClassName('year-month-check')
    const screening_player_id = Number($('#screening_player_id').val());
    const list_year_months = []
    for (let year_month of elem_year_months){
      if (year_month.checked==true){
        list_year_months.push(`'${year_month.value}'`)
      }
    }

    const queryParams = [
      `year_month=${list_year_months.join(',')}`,
      // `month=${screening_month}`,
      `player_id=${screening_player_id}`
    ];

    fetch(`/screening_result_processing?${queryParams.join("&")}`)
    .then(resp => resp.json())
    .then(data => {
            this.chartData = data;
            this.drawChart();
          }
    );

    $('#injury_table').tablesorter()

    // Get Screening Report
    $('#blobButton_screening_result').on('click',function() {
      this.PrintDiv($("#div_screening"));
    })

  }

  drawChart(){

    this.initCharts()

    // Screening_1
    const chart_canvas = document.getElementById('result_average_chart').getContext('2d');
    const [chart_data, _] = this.setChartData('radar');
    new Chart(chart_canvas, {
      type:'radar',
      data:chart_data,
      
      options: {
        responsive:true,
        // maintainAspectRatio:true,
        maintainAspectRatio:false,
        elements: {
          point: {
            hoverRadius: 7,
            radius: 5
          }
        },
        scales: {
          r: {
              min: 0,
              max: 3,
              ticks:{
                stepSize: 1,
                display: false
              },
              pointLabels: {
                font: {
                  size: 16
                }
              }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: function(context) {
              return context.dataset.borderColor;
            },
            color: 'white',
            font: {
              weight: 'bold'
            },
            formatter: Math.round,
            padding: 5
          }
        },
        layout: {
          padding: 0
        }        
      }
    });
    
    // Hip
    try{
      const hip_chart = new hip(this.chartData);
      hip_chart.drawChart();
    } catch(e){console.log(e)}

    // Shoulder(ROM)
    try{
      const shoulder_rom_chart = new shoulderRom(this.chartData);
      shoulder_rom_chart.drawChart();
    } catch(e){console.log(e)}

    // Shoulder(MMT)
    try{
      const shoulder_mmt_chart = new shoulderMMT(this.chartData);
      shoulder_mmt_chart.drawChart();
    } catch (e){console.log(e)}

    // // Y-Balance(Split)
    try{
      const y_balance_chart = new yBalance(this.chartData);
      y_balance_chart.drawChart();
    } catch (e){console.log(e)}
    
    // // Hand Grip(Split)
    try{
      const hand_grip = new handGrip(this.chartData);
      hand_grip.drawChart();
    } catch (e){console.log(e)}
    
    // // jump
    try{
      const jump_chart = new jump(this.chartData);
      jump_chart.drawChart();
    } catch (e){console.log(e)}    
    
    // // push_up
    try{
      const push_up_chart = new pushUp(this.chartData);
      push_up_chart.drawChart();
    } catch (e){console.log(e)}

    // // Field Test
    try{
      const field_test_common_chart = new fieldTestCommon(this.chartData);
      field_test_common_chart.drawChart();
    } catch (e){console.log(e)}

    if (this.player_position=='투수'){
      // // Field Test(pitcher)
  
      try{
        const field_test_pitcher_chart = new fieldTestPitcher(this.chartData);
        field_test_pitcher_chart.drawChart();
      } catch (e){console.log(e)}

    } else {
      // // Field Test(pitcher)
      try{
        const field_test_position_player_chart = new fieldTestPosition(this.chartData);
        field_test_position_player_chart.drawChart();
      } catch (e){console.log(e)}
    }
    
  };

  setLabels(chart){
    let labels = [];
    let chart_list = null;
    if (chart=='hip'){
      chart_list = this.chart_hip_list
    }
    else if (chart=='shoulder_rom'){
      chart_list = this.chart_shoulder_rom_list
    }
    else if (chart=='shoulder_mmt_d'){
      chart_list = this.chart_shoulder_mmt_d_list
    }
    else if (chart=='shoulder_mmt_nd'){
      chart_list = this.chart_shoulder_mmt_nd_list
    }            
    else if(chart=='y_balance'){
      chart_list = this.chart_y_balance_list
    }
    else if (chart=='hand_grip'){
      chart_list = this.chart_hand_grip_list
    }
    else if (chart=='jump'){
      chart_list = this.chart_jump_list
    }
    else if (chart=='push_up'){
      chart_list = this.chart_push_up_list
    }        
    else if (chart=='field_test_common'){
      chart_list = this.chart_field_test_common_list
    }        
    else if (chart=='field_test_pitcher'){
      chart_list = this.chart_field_test_pitcher_list
    }        
    else if (chart=='field_test_position_player'){
      chart_list = this.chart_field_test_position_player_list
    }                    
    else{
      chart_list = this.chart_radar_list
    }

    for(let category of chart_list){
      labels.push(this.chart_label_dict[category])
    }
    return labels
  };

  setChartData(chart){
    let labels = this.setLabels(chart);
    let data = {
      labels: labels,
      datasets: [],
    };
    let [other_month_data, max_tick] = this.setOtherMonthData(chart)
    data['datasets'] = data['datasets'].concat(other_month_data)
    max_tick += this.roundNearest5(max_tick)
    return [data, max_tick]
  };

  setOtherMonthData(chart){
    let month_dataset_list = [];
    let chart_list = null;
    let max_tick = null;
    if (chart=='hip'){
      chart_list = this.chart_hip_list
    }
    else if (chart=='shoulder_rom'){
      chart_list = this.chart_shoulder_rom_list
    }       
    else if (chart=='shoulder_mmt_d'){
      chart_list = this.chart_shoulder_mmt_d_list
    }
    else if (chart=='shoulder_mmt_nd'){
      chart_list = this.chart_shoulder_mmt_nd_list
    }           
    else if(chart=='y_balance'){
      chart_list = this.chart_y_balance_list
    }
    else if (chart=='hand_grip'){
      chart_list = this.chart_hand_grip_list
    }
    else if (chart=='jump'){
      chart_list = this.chart_jump_list
    }    
    else if (chart=='push_up'){
      chart_list = this.chart_push_up_list
    }        
    else if (chart=='field_test_common'){
      chart_list = this.chart_field_test_common_list
    }        
    else if (chart=='field_test_pitcher'){
      chart_list = this.chart_field_test_pitcher_list
    }        
    else if (chart=='field_test_position_player'){
      chart_list = this.chart_field_test_position_player_list
    }                        
    else{
      chart_list = this.chart_radar_list
    }

    // let other_months_array = chart=='radar' ? this.chartData['other_months'].reverse().entries() : this.chartData['other_months'].entries()
    
    for(let [ index, month ] of this.chartData['other_months'].entries()) {
      let month_dataset = {
        label: month['screening_month'],
        backgroundColor: this.color_list[index],
        borderColor: this.background_color_list[index],
        pointBackgroundColor: this.color_list[index],
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: this.color_list[index]
      };
      let month_data = []
      for(let category of chart_list){
        if (month[category]==null){
          month_data.push(null)  
        }
        else{
          if (max_tick<month[category]){
            max_tick=month[category]
          }
          month_data.push(month[category])
        }
      }
      month_dataset['data']=month_data
      month_dataset_list.push(month_dataset)
    }
    return [month_dataset_list, max_tick]
  }

  show_screening_sector(event){
    var sector_screening = document.getElementById('sector_screening');
    sector_screening.style.display = 'block';
    var sector_biomechanic = document.getElementById('sector_biomechanic');
    sector_biomechanic.style.display = 'none';
    var sector_injury = document.getElementById('sector_injury');
    sector_injury.style.display = 'none';        
  }

  show_biomechanic_sector(event){
    var sector_screening = document.getElementById('sector_screening');
    sector_screening.style.display = 'none';
    var sector_biomechanic = document.getElementById('sector_biomechanic');
    sector_biomechanic.style.display = 'block';
    var sector_injury = document.getElementById('sector_injury');
    sector_injury.style.display = 'none';        
  }

  show_injury_sector(event){
    var sector_screening = document.getElementById('sector_screening');
    sector_screening.style.display = 'none';
    var sector_biomechanic = document.getElementById('sector_biomechanic');
    sector_biomechanic.style.display = 'none';
    var sector_injury = document.getElementById('sector_injury');
    sector_injury.style.display = 'block';        
  }

  PrintDiv(div){
    div = div[0];
    html2canvas(div, {scrollY: -window.scrollY, scale:2}).then(function(canvas){
      var myImage = canvas.toDataURL();
      this.downloadURI(myImage, "DownloadReport.png");
    });
  }

  downloadURI(uri, name){
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
  }

  initCharts(){
    const radarChart = $('#result_average_chart')
    Chart.getChart(radarChart)?.destroy(); 
    $('#result_hip').html(null);

    $('#result_y_balances').html(null);
    $('#result_shoulder_rom').html(null);    
    $('#result_shoulder_mmt_d').html(null);    
    $('#result_hand_grips').html(null);
    $('#result_jump').html(null);
    $('#result_push_up').html(null);
    $('#result_field_test_common').html(null);
    $('#result_field_test_pitcher').html(null);
    $('#result_field_test_position_player').html(null);
  }

  // setAnnotation(chart, chart_instance){
  //   let annotation_list = [];
  //   let chart_list = null;
  //   const month_chart_data = this.chartData
    
  //   if (chart=='hip'){
  //     chart_list = this.chart_hip_list
  //   }
  //   else if (chart=='shoulder_rom'){
  //     chart_list = this.chart_shoulder_rom_list
  //   }       
  //   else if (chart=='shoulder_mmt_d'){
  //     chart_list = this.chart_shoulder_mmt_d_list
  //   }
  //   else if (chart=='shoulder_mmt_nd'){
  //     chart_list = this.chart_shoulder_mmt_nd_list
  //   }           
  //   else if(chart=='y_balance'){
  //     chart_list = this.chart_y_balance_list
  //   }
  //   else if (chart=='hand_grip'){
  //     chart_list = this.chart_hand_grip_list
  //   }
  //   else if (chart=='jump'){
  //     chart_list = this.chart_jump_list
  //   }    
  //   else if (chart=='push_up'){
  //     chart_list = this.chart_push_up_list
  //   }        
  //   else if (chart=='field_test_common'){
  //     chart_list = this.chart_field_test_common_list
  //   }        
  //   else if (chart=='field_test_pitcher'){
  //     chart_list = this.chart_field_test_pitcher_list
  //   }        
  //   else if (chart=='field_test_position_player'){
  //     chart_list = this.chart_field_test_position_player_list
  //   }                        
  //   else{
  //     chart_list = this.chart_radar_list
  //   }

  //   chart_instance.data.datasets.forEach(function (dataset, month_index) {
  //     var meta = chart_instance.getDatasetMeta(month_index);
  //     meta.data.forEach(function (bar, category_index) {
  //       let average_month = month_chart_data['other_month_average'][month_index]
  //       let average_value = average_month[chart_list[category_index]]
  //       if (average_value!=null){
  //         let annotation = {
  //           type: 'line',
  //           borderColor: 'green',
  //           borderWidth: 3,
  //           label: {
  //             display: true,
  //             backgroundColor: 'green',
  //             borderRadius: 0,
  //             color: 'black',
  //             content: `AVG : ${average_value}`
  //           },
  //           // xMax: xValue + 0.05,
  //           // xMin: xValue - 0.05,
  //           xScaleID: 'x',
  //           yMax: average_value,
  //           yMin: average_value,
  //           yScaleID: 'y'        
  //         }
  //         annotation_list.push(annotation)
  //       }
  //     })
  //   })
  //     return annotation_list  
  // };

  roundNearest5(num) {
    return Math.ceil(num / 10) * 10;
  }

};


jQuery(document).on('ready',function() {
    // Table Sort

})