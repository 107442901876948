import { Controller } from "stimulus";

export default class extends Controller {

    position = document.getElementById('pro-video').getAttribute("data-pro-video-position");
    playerId = document.getElementById('pro-video').getAttribute("data-pro-video-player-id");

    table_data_default_class = "px-6 py-1.5 whitespace-no-wrap border-b border-gray-200 text-center"
    table_data_div_default_class = "text-sm leading-5 text-gray-900"

    selectedKindId='all'

    video_kind_dict = {
        'all' : '전체',
        'broadcast' : '중견수-투수',
        'first_to_pitcher' : '1루-투수',
        'first_to_hitter' : '1루-타자',
        'catcher_to_pitcher' : '포수-투수',
        'third_to_hitter' : '3루-타자',
        'third_to_pitcher' : '3루-투수'
    }

    connect() {
        this.fetchData()
    };

    fetchData(seasonId=null, dateId=null) {

        if (dateId!=null){
            const queryParams = [
                `position_number=${this.position}`,
                `player_id=${this.playerId}`,
                `game_date=${dateId}`,
            ];
            fetch(
                `/pro_video_list?${queryParams.join("&")}`)
                .then(resp => resp.json())
                .then(data => {
                    this.videoList = data['pro_video_list'];
                    this.createTable();
            });
        }
        else{
            const queryParams = [
                `position_number=${this.position}`,
                `player_id=${this.playerId}`,
                `game_year=${seasonId}`
            ];
            fetch(
                `/pro_video_game_date?${queryParams.join("&")}`)
                .then(resp => resp.json())
                .then(data => {
                    this.gameDateList = data['pro_video_game_date'];
                    this.createSelect();
            });            
        }
    };

    changeGameYear(event) {
        event.preventDefault();
        const seasonId = event.currentTarget.getAttribute("data-game-year-id");
    
        if (seasonId === this.selectedSeasonId) {
          return;
        }
        this.selectedSeasonId = seasonId;
    
        const menu = document.getElementById("pro-video-game-year-select-menu");
        menu.innerText = seasonId;
    
        this.fetchData(seasonId, null);
    };

    changeGameDate(event) {
        event.preventDefault();
        const dateId = event.currentTarget.getAttribute("data-game-date-id");
    
        if (dateId === this.selectedDateId) {
          return;
        }
        this.selectedDateId = dateId;
    
        const menu = document.getElementById("pro-video-game-date-select-menu");
        menu.innerText = dateId;
    
        this.fetchData(null, dateId);
    };

    createSelect(event) {
        event?.preventDefault();
        this.clearSelect();

        for (let game_date of this.gameDateList){

            const date_select_element = document.getElementById('pro-video-game-date-select-item');

            const select_game_date = this.createSelectItem(game_date)
            date_select_element.appendChild(select_game_date)
        }
    };

    createSelectItem(game_date){
        const select_game_date = document.createElement("a")
    
        select_game_date.setAttribute('href', '#')
        select_game_date.setAttribute('data-action', 'pro-video#changeGameDate')
        select_game_date.setAttribute('data-game-date-id', game_date)
        select_game_date.setAttribute('class', 'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900')
        select_game_date.setAttribute('x-on:click',"dateSelectOpen = false")
        select_game_date.innerHTML = game_date;
    
        return select_game_date
    };

    createTable(event){
        event?.preventDefault();
        this.clearTable();
        if (this.videoList.length>0){
            const table_element = document.getElementById('pro-video-table-body');

            for (const [index, value] of this.videoList.entries() ){

                const table_row_video = document.createElement("tr");
                table_row_video.setAttribute('id', `video_tr_${index}`)
                table_row_video.setAttribute('data-vi', value['id'])
                table_row_video.setAttribute('data-url', value['location'])
                table_row_video.setAttribute('x-on:click',"isOpen = true")
                table_row_video.classList.add(value['kind'])
                table_row_video.classList.add('all')

                const table_data_video_game_date = this.createTableData(value, 'game_date')
                const table_data_video_pitcher_name = this.createTableData(value, 'pitcher_name')
                const table_data_video_batter_name = this.createTableData(value, 'batter_name')
                const table_data_video_inning = this.createTableData(value, 'inning')
                const table_data_video_ball_count = this.createTableData(value, 'ball_count')

                table_row_video.appendChild(table_data_video_game_date)
                table_row_video.appendChild(table_data_video_pitcher_name)
                table_row_video.appendChild(table_data_video_batter_name)
                table_row_video.appendChild(table_data_video_inning)
                table_row_video.appendChild(table_data_video_ball_count)

                table_element.appendChild(table_row_video)

            };
        }
    };

    createTableData(value, category){
        const table_data_video = document.createElement("td")
        table_data_video.setAttribute('class', this.table_data_default_class)
        const table_data_div_video = document.createElement("div")
        table_data_div_video.setAttribute('class', this.table_data_div_default_class)

        let title = null;

        switch (category){
            case 'game_date':
                title = value['game_date'];
                break
            case 'batter_name':
                title = value['batter_name']
                break
            case 'pitcher_name':
                title = value['pitcher_name']
                break
            case 'inning':
                title = value['inning']
                break   
            case 'ball_count':
                title = `${value['balls']}B-${value['strikes']}S ${value['outs']}OUT`
                break                                                           
        }

        table_data_div_video.innerHTML = title;
        table_data_video.appendChild(table_data_div_video)

        return table_data_video
    };

    clearSelect() {
        document.getElementById("pro-video-game-date-select-item").innerHTML = '';
    };

    clearTable() {
        document.getElementById("pro-video-table-body").innerHTML = '';
    };

    displayProperty(elemList, isBlock) {
        for (const [index, value] of elemList.entries() ) {
            elemList[index].style.display = isBlock;
        }
    };

    changeKind(event){
        event.preventDefault();
        const kindId = event.currentTarget.getAttribute("data-kind-id");
        if (kindId == this.selectedKindId) {
            return;
        }
        else{

            // 1. 모두 접기

            const prevElemList = Array.from(document.getElementsByClassName('all'));
            this.displayProperty(prevElemList, 'none')
            this.selectedKindId = kindId;

            // 2. 특정 유형만 피기
            // 2.1. 특정 유형이 all인 경우 => 유형을 모두 다 피기
            // 2.2. 특정 유형이 all이 아닌 경우 => 특정 유형이 아닌 경우만 제외하고 모두 접기

            const currElemList = Array.from(document.getElementsByClassName(kindId));
            this.displayProperty(currElemList, '')

            const menu = document.getElementById("pro-video-kind-select-menu");
            menu.innerText = this.video_kind_dict[kindId];
        }
    };


}