// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element.getElementsByTagName('div')[0],{
      group: 'same',
      animation: 150,
      onEnd: this.end.bind(this)
    });

    this.sortable_major = Sortable.create(this.element.querySelector('#major_roster'),{
      group: 'same',
      animation: 150,
      onEnd: this.end.bind(this)
    });

    this.sortable_rehab = Sortable.create(this.element.querySelector('#rehab_roster'),{
      group: 'same',
      animation: 150,
      onEnd: this.end.bind(this)
    });

    this.sortable_army = Sortable.create(this.element.querySelector('#army_roster'),{
      group: 'same',
      animation: 150,
      onEnd: this.end.bind(this)
    });
  }

  end(event){
    let id = event.item.dataset.id
    console.log(event.item.dataset)
    let data = new FormData()
    data.append("position",event.to.id)

    Rails.ajax({
      url: this.data.get("url").replace(":id",id),
      type: 'PATCH',
      data: data
    })
  }
}
