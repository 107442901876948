import { Controller } from "stimulus"

export default class extends Controller {
    player_id = document.getElementById('playerId').value;

    button_sector_scouting = document.getElementById('button_sector_scouting');
    button_sector_record = document.getElementById('button_sector_record');
    sector_scouting = document.getElementById('sector_scouting');
    sector_record = document.getElementById('sector_record');

    button_tab_report = document.getElementById('buttonTabReport');
    button_tab_comment = document.getElementById('buttonTabComment');
    button_tab_video = document.getElementById('buttonTabVideo');
    button_tab_transaction = document.getElementById('buttonTabTransaction');

    tab_report = document.getElementById('tab_report');
    tab_comment = document.getElementById('tab_comment');
    tab_video = document.getElementById('tab_video');
    tab_transaction = document.getElementById('tab_transaction');
    
    // record- tab buttons
    button_tab_standard = document.getElementById('buttonTabStandard');
    button_tab_split = document.getElementById('buttonTabSplit');
    button_tab_game_logs = document.getElementById('buttonTabGameLogs');

    tab_standard = document.getElementById('tabStandard');
    tab_split = document.getElementById('tabSplit');
    tab_game_logs = document.getElementById('tabGameLogs');

    // record- split buttons
    button_split_tab_kbo = document.getElementById('buttonTabSplitKBO');
    button_split_tab_mlb = document.getElementById('buttonTabSplitMLB');
    button_split_tab_milb = document.getElementById('buttonTabSplitMiLB');
    button_split_tab_npb = document.getElementById('buttonTabSplitNPB');
    button_split_tab_cpbl = document.getElementById('buttonTabSplitCPBL');

    split_tab_kbo = document.getElementById('tabKBOSplit');
    split_tab_mlb = document.getElementById('tabMLBSplit');
    split_tab_milb = document.getElementById('tabMiLBSplit');
    split_tab_npb = document.getElementById('tabNPBSplit');
    split_tab_cpbl = document.getElementById('tabCPBLSplit');

    // record- game_logs buttons
    button_game_logs_tab_kbo = document.getElementById('buttonTabGameLogsKBO');
    button_game_logs_tab_mlb = document.getElementById('buttonTabGameLogsMLB');
    button_game_logs_tab_milb = document.getElementById('buttonTabGameLogsMiLB');
    button_game_logs_tab_npb = document.getElementById('buttonTabGameLogsNPB');
    button_game_logs_tab_cpbl = document.getElementById('buttonTabGameLogsCPBL');

    game_logs_tab_kbo = document.getElementById('tabKBOGameLogs');
    game_logs_tab_mlb = document.getElementById('tabMLBGameLogs');
    game_logs_tab_milb = document.getElementById('tabMiLBGameLogs');
    game_logs_tab_npb = document.getElementById('tabNPBGameLogs');
    game_logs_tab_cpbl = document.getElementById('tabCPBLGameLogs');

    kboGameLogTable = document.getElementById('kboGameLogTable')
    mlbGameLogTable = document.getElementById('mlbGameLogTable')
    milbGameLogTable = document.getElementById('milbGameLogTable')
    npbGameLogTable = document.getElementById('npbGameLogTable')
    cpblGameLogTable = document.getElementById('cpblGameLogTable')

    initialize(){
        if (this.kboGameLogTable!=null){
            $('#kboGameLogTable').tablesorter()
        }
        if (this.mlbGameLogTable!=null){
            $('#mlbGameLogTable').tablesorter()
        }
        if (this.npbGameLogTable!=null){
            $('#npbGameLogTable').tablesorter()
        }
        if (this.cpblGameLogTable!=null){
            $('#cpblGameLogTable').tablesorter()
        }
    }

    connect(){
        $('.button_int_video').on('click',function(event) {
            var myTarget = $(event.target).find('.int_video')
            if (myTarget.is(":visible")==true){
                myTarget.fadeOut()
                myTarget.hide() 
            }
            else{
                myTarget.fadeIn()
                myTarget.show()
            }
            var videoId = myTarget.attr('video-id')
            var videoName = myTarget.attr('name')
            let data = { 
                "player_id":this.player_id,
                "kind" : 'show',
                "sector": 'scouting',
                "tab": 'video',
                "tab_kind": 'show',
                "international_scout_video_id": videoId,
                "report_name":videoName
            }
            setLogs(data)
        })
        $(window).on('resize', function(){
            $('.international-batter-scout-comment').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
        });
    }

    // show scouting sector
    showScoutingSector(event){
        if ($('#button_sector_scouting').hasClass('bg-black text-white')==false){
            this.button_sector_scouting.classList.replace('bg-white', 'bg-black');
            this.button_sector_scouting.classList.replace('text-black', 'text-white');
        }
        this.button_sector_record.classList.replace('bg-black', 'bg-white');
        this.button_sector_record.classList.replace('text-white', 'text-black');
    
        this.sector_scouting.style.display = 'block';
        this.sector_record.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
        }
        setLogs(data)
    }

    showScoutingReportTab(event){
        if ($('#buttonTabReport').hasClass('bg-black text-white')==false){
            this.button_tab_report.classList.replace('bg-white', 'bg-black');
            this.button_tab_report.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_report.style.display = 'block';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
        }
        setLogs(data)
    }

    showVideoTab(event){
        if ($('#buttonTabVideo').hasClass('bg-black text-white')==false){
            this.button_tab_video.classList.replace('bg-white', 'bg-black');
            this.button_tab_video.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'block';
        this.tab_transaction.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'video',
        }
        setLogs(data)
    }

    showScoutingCommentTab(event){
        if ($('#buttonTabComment').hasClass('bg-black text-white')==false){
            this.button_tab_comment.classList.replace('bg-white', 'bg-black');
            this.button_tab_comment.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.button_tab_transaction.classList.replace('bg-black', 'bg-white');
        this.button_tab_transaction.classList.replace('text-white', 'text-gray-400');

        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'block';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'none';

        $('.international-batter-scout-comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'comment',
        }
        setLogs(data)
    }

    showTransactionTab(event){
        if ($('#buttonTabTransaction').hasClass('bg-black text-white')==false){
            this.button_tab_transaction.classList.replace('bg-white', 'bg-black');
            this.button_tab_transaction.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_report.classList.replace('bg-black', 'bg-white');
        this.button_tab_report.classList.replace('text-white', 'text-gray-400');

        this.button_tab_comment.classList.replace('bg-black', 'bg-white');
        this.button_tab_comment.classList.replace('text-white', 'text-gray-400');

        this.button_tab_video.classList.replace('bg-black', 'bg-white');
        this.button_tab_video.classList.replace('text-white', 'text-gray-400');

        this.tab_report.style.display = 'none';
        this.tab_comment.style.display = 'none';
        this.tab_video.style.display = 'none';
        this.tab_transaction.style.display = 'block';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'transaction',
        }
        setLogs(data)
    }

    // show record sector
    showRecordSector(event){
        if ($('#button_sector_record').hasClass('bg-black text-white')==false){
            this.button_sector_record.classList.replace('bg-white', 'bg-black');
            this.button_sector_record.classList.replace('text-black', 'text-white');
        }
        this.button_sector_scouting.classList.replace('bg-black', 'bg-white');
        this.button_sector_scouting.classList.replace('text-white', 'text-black');

        this.sector_scouting.style.display = 'none';
        this.sector_record.style.display = 'block';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
        }
        setLogs(data)
    }
    
    showRecordStandardTab(event){
        if ($('#tabStandard').hasClass('bg-black text-white')==false){
            this.button_tab_standard.classList.replace('bg-white', 'bg-black');
            this.button_tab_standard.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_split.classList.replace('bg-black', 'bg-white');
        this.button_tab_split.classList.replace('text-white', 'text-gray-400');

        this.button_tab_game_logs.classList.replace('bg-black', 'bg-white');
        this.button_tab_game_logs.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'block';
        this.tab_split.style.display = 'none';
        this.tab_game_logs.style.display = 'none';
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'standard',
        }
        setLogs(data)
    }

    showRecordSplitTab(event){
        if ($('#tabSplit').hasClass('bg-black text-white')==false){
            this.button_tab_split.classList.replace('bg-white', 'bg-black');
            this.button_tab_split.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_standard.classList.replace('bg-black', 'bg-white');
        this.button_tab_standard.classList.replace('text-white', 'text-gray-400');

        this.button_tab_game_logs.classList.replace('bg-black', 'bg-white');
        this.button_tab_game_logs.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'none';
        this.tab_split.style.display = 'block';
        this.tab_game_logs.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'split',
        }
        setLogs(data)
    }

    showRecordGameLogsTab(event){
        if ($('#tabGameLogs').hasClass('bg-black text-white')==false){
            this.button_tab_game_logs.classList.replace('bg-white', 'bg-black');
            this.button_tab_game_logs.classList.replace('text-gray-400', 'text-white');
        }
        this.button_tab_standard.classList.replace('bg-black', 'bg-white');
        this.button_tab_standard.classList.replace('text-white', 'text-gray-400');

        this.button_tab_split.classList.replace('bg-black', 'bg-white');
        this.button_tab_split.classList.replace('text-white', 'text-gray-400');

        this.tab_standard.style.display = 'none';
        this.tab_split.style.display = 'none';
        this.tab_game_logs.style.display = 'block';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'game_logs',
        }
        setLogs(data)
    }

    showYears(event){
        const showYearId = event.target.id;
        let season = document.getElementById(showYearId).getAttribute("season")
        let changeText = '';
        $(`.${showYearId}_split`).each(function(){
            if(this.style.display==''){
                this.style.display = 'none';
                changeText = `${season} ▼`;
                event.target.innerHTML=changeText
            } else{
                this.style.display = '';
                changeText = `${season} ▲`;
                event.target.innerHTML=changeText
            }
        })
    }

    // show Split each leagues
    showRecordSplitKBO(event){
        if ($('#buttonTabSplitKBO').hasClass('bg-black text-white')==false){
            this.button_split_tab_kbo.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_kbo.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }
        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'block';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_mlib!=null) this.split_tab_mlib.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'kbo',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitMLB(event){
        if ($('#buttonTabSplitMLB').hasClass('bg-black text-white')==false){
            this.button_split_tab_mlb.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_mlb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'block';
        if(this.split_tab_mlib!=null) this.split_tab_mlib.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'mlb',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitMiLB(event){
        if ($('#buttonTabSplitMiLB').hasClass('bg-black text-white')==false){
            this.button_split_tab_milb.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_milb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_mlib!=null) this.split_tab_mlib.style.display = 'block';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'milb',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitNPB(event){
        if ($('#buttonTabSplitNPB').hasClass('bg-black text-white')==false){
            this.button_split_tab_npb.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_npb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_cpbl!=null){
            this.button_split_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_mlib!=null) this.split_tab_mlib.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'block';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'none';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'npb',
            'type':'split'
        }
        setLogs(data)
    }

    showRecordSplitCPBL(event){
        if ($('#buttonTabSplitCPBL').hasClass('bg-black text-white')==false){
            this.button_split_tab_cpbl.classList.replace('bg-white', 'bg-black');
            this.button_split_tab_cpbl.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_split_tab_kbo!=null){
            this.button_split_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_mlb!=null){
            this.button_split_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_milb!=null){
            this.button_split_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_split_tab_npb!=null){
            this.button_split_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_split_tab_npb.classList.replace('text-white', 'text-gray-400');
        }

        if(this.split_tab_kbo!=null) this.split_tab_kbo.style.display = 'none';
        if(this.split_tab_mlb!=null) this.split_tab_mlb.style.display = 'none';
        if(this.split_tab_mlib!=null) this.split_tab_mlib.style.display = 'none';
        if(this.split_tab_npb!=null) this.split_tab_npb.style.display = 'none';
        if(this.split_tab_cpbl!=null) this.split_tab_cpbl.style.display = 'block';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'cpbl',
            'type':'split'
        }
        setLogs(data)
    }

    changeSplitYear(event){
        
        let selectYearId =  event.target.id
        let selectYear =  event.target.value

        if (selectYearId.includes('Split') & selectYear!=''){
            if (selectYearId.includes('KBO')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 1, "league_id":1, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableKBOSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableKBOSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['batter_stand_split_records']
                        let monthSplitRecords = data['batter_month_split_records']
                        
                        // Batter Throws Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, batter_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['avg']==null ? '--' : batter_stand_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['obp']==null ? '--' : batter_stand_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['slg']==null ? '--' : batter_stand_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ops']==null ? '--' : batter_stand_split_record['ops'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ubb_rate']==null ? '--' : (batter_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k_rate']==null ? '--' : (batter_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // Batter Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, batter_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['avg']==null ? '--' : batter_month_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['obp']==null ? '--' : batter_month_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['slg']==null ? '--' : batter_month_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ops']==null ? '--' : batter_month_split_record['ops'].toFixed(3)}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 2, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableMLBSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableMLBSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['batter_stand_split_records']
                        let monthSplitRecords = data['batter_month_split_records']
                        
                        // Batter Throws Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, batter_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['avg']==null ? '--' : batter_stand_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['obp']==null ? '--' : batter_stand_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['slg']==null ? '--' : batter_stand_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ops']==null ? '--' : batter_stand_split_record['ops'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ubb_rate']==null ? '--' : (batter_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k_rate']==null ? '--' : (batter_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // Batter Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, batter_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['avg']==null ? '--' : batter_month_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['obp']==null ? '--' : batter_month_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['slg']==null ? '--' : batter_month_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ops']==null ? '--' : batter_month_split_record['ops'].toFixed(3)}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MiLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": "6,7,8,9", "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableMiLBSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableMiLBSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['batter_stand_split_records']
                        let monthSplitRecords = data['batter_month_split_records']
                        
                        // Batter Throws Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, batter_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['avg']==null ? '--' : batter_stand_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['obp']==null ? '--' : batter_stand_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['slg']==null ? '--' : batter_stand_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ops']==null ? '--' : batter_stand_split_record['ops'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ubb_rate']==null ? '--' : (batter_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k_rate']==null ? '--' : (batter_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // Batter Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, batter_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['avg']==null ? '--' : batter_month_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['obp']==null ? '--' : batter_month_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['slg']==null ? '--' : batter_month_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ops']==null ? '--' : batter_month_split_record['ops'].toFixed(3)}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('NPB')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 3, "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableNPBSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableNPBSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['batter_stand_split_records']
                        let monthSplitRecords = data['batter_month_split_records']
                        
                        // Batter Throws Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, batter_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['avg']==null ? '--' : batter_stand_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['obp']==null ? '--' : batter_stand_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['slg']==null ? '--' : batter_stand_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ops']==null ? '--' : batter_stand_split_record['ops'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ubb_rate']==null ? '--' : (batter_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k_rate']==null ? '--' : (batter_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // Batter Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, batter_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['avg']==null ? '--' : batter_month_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['obp']==null ? '--' : batter_month_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['slg']==null ? '--' : batter_month_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ops']==null ? '--' : batter_month_split_record['ops'].toFixed(3)}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('CPBL')){
                $.ajax({
                    url: '/scouts/international_scouts/getSplitRecords',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "league_id": 4, "league_id":"4", "season":selectYear},
                    success: function (data){
                        let standSplitTableTbodyElement = document.getElementById('tableCPBLSplitStandTbody')
                        standSplitTableTbodyElement.innerHTML=''
                        let monthSplitTableTbodyElement = document.getElementById('tableCPBLSplitMonthTbody')
                        monthSplitTableTbodyElement.innerHTML=''
                        let standSplitRecords = data['batter_stand_split_records']
                        let monthSplitRecords = data['batter_month_split_records']
                        
                        // Batter Throws Split Table
                        if (standSplitRecords.length>0){
                            for(let [ index, batter_stand_split_record ] of standSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_stand_split_record['stands']=='L' ? 'vs L' : 'vs R'}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['hit']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['avg']==null ? '--' : batter_stand_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['obp']==null ? '--' : batter_stand_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['slg']==null ? '--' : batter_stand_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ops']==null ? '--' : batter_stand_split_record['ops'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['ubb_rate']==null ? '--' : (batter_stand_split_record['ubb_rate']*100).toFixed(2)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_stand_split_record['k_rate']==null ? '--' : (batter_stand_split_record['k_rate']*100).toFixed(2)}</td>
                                    </tr>
                                `
                                standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            standSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }

                        // Batter Month Split Table
                        if (monthSplitRecords.length>0){
                            for(let [ index, batter_month_split_record ] of monthSplitRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${batter_month_split_record['month']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['g']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['homerun']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['avg']==null ? '--' : batter_month_split_record['avg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['obp']==null ? '--' : batter_month_split_record['obp'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['slg']==null ? '--' : batter_month_split_record['slg'].toFixed(3)}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${batter_month_split_record['ops']==null ? '--' : batter_month_split_record['ops'].toFixed(3)}</td>
                                    </tr>
                                `
                                monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }                            
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            monthSplitTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
        }

    }

    // show Game Logs each leagues
    showRecordGameLogsKBO(event){
        if ($('#buttonTabGameLogsKBO').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_kbo.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_kbo.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }
        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'block';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'kbo',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsMLB(event){
        if ($('#buttonTabGameLogsMLB').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_mlb.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_mlb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'block';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'mlb',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsMiLB(event){
        if ($('#buttonTabGameLogsMiLB').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_milb.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_milb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'block';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'milb',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsNPB(event){
        if ($('#buttonTabGameLogsNPB').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_npb.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_npb.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_cpbl!=null){
            this.button_game_logs_tab_cpbl.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_cpbl.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'block';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'none';

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'npb',
            'type':'game_logs'
        }
        setLogs(data)
    }

    showRecordGameLogsCPBL(event){
        if ($('#buttonTabGameLogsCPBL').hasClass('bg-black text-white')==false){
            this.button_game_logs_tab_cpbl.classList.replace('bg-white', 'bg-black');
            this.button_game_logs_tab_cpbl.classList.replace('text-gray-400', 'text-white');
        }
        if(this.button_game_logs_tab_kbo!=null){
            this.button_game_logs_tab_kbo.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_kbo.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_mlb!=null){
            this.button_game_logs_tab_mlb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_mlb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_milb!=null){
            this.button_game_logs_tab_milb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_milb.classList.replace('text-white', 'text-gray-400');
        }
        if(this.button_game_logs_tab_npb!=null){
            this.button_game_logs_tab_npb.classList.replace('bg-black', 'bg-white');
            this.button_game_logs_tab_npb.classList.replace('text-white', 'text-gray-400');
        }

        if(this.game_logs_tab_kbo!=null) this.game_logs_tab_kbo.style.display = 'none';
        if(this.game_logs_tab_mlb!=null) this.game_logs_tab_mlb.style.display = 'none';
        if(this.game_logs_tab_milb!=null) this.game_logs_tab_milb.style.display = 'none';
        if(this.game_logs_tab_npb!=null) this.game_logs_tab_npb.style.display = 'none';
        if(this.game_logs_tab_cpbl!=null) this.game_logs_tab_cpbl.style.display = 'block';
        
        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'record',
            "tab": 'cpbl',
            'type':'game_logs'
        }
        setLogs(data)
    }

    changeGameLogYear(event){
        
        let selectYearId =  event.target.id
        let selectYear =  event.target.value

        if (selectYearId.includes('GameLog') & selectYear!=''){
            if (selectYearId.includes('KBO')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 1, "league_id":1, "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableKBOGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['batter_game_logs']

                        // batter game logs table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['homeruns']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['sb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['cs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hbp']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 2, "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableMLBGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['batter_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['homeruns']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['sb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['cs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hbp']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('MiLB')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": '6,7,8,9', "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableMiLBGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['batter_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['homeruns']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['sb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['cs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hbp']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('NPB')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "sport_id": 3, "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableNPBGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['batter_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['homeruns']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['sb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['cs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hbp']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
            else if (selectYearId.includes('CPBL')){
                $.ajax({
                    url: '/scouts/international_scouts/getGameLogs',
                    method: 'POST',
                    async: false,
                    data: { "player_id": this.player_id, "league_id": 4, "level_id":"4", "season":selectYear},
                    success: function (data){
                        let gameLogTableTbodyElement = document.getElementById('tableCPBLGameLogsTbody')
                        gameLogTableTbodyElement.innerHTML=''
                        let gameLogRecords = data['batter_game_logs']

                        // pitcher Split Table
                        if (gameLogRecords.length>0){
                            for(let [ index, gameLog ] of gameLogRecords.entries()) {
                                var strHTML = `
                                    <tr class="">
                                        <td class='sticky left-0 border whitespace-no-wrap px-6 py-2 bg-white text-center text-sm'>${gameLog['game_date']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['opp_team']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['pa']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['ab']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['runs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hits']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['doubles']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['triples']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['homeruns']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['rbi']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['bb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['k']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['sb']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['cs']}</td>
                                        <td class='border whitespace-no-wrap text-center px-6 py-2 text-sm'>${gameLog['hbp']}</td>
                                    </tr>
                                `
                                gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML );
                            }
                        }else{
                            let strHTML = '<tr class=""><td colspan="14" class="border whitespace-no-wrap text-center px-6 py-2 text-sm">Record does not exists.</td></tr>'
                            gameLogTableTbodyElement.insertAdjacentHTML( 'beforeend', strHTML);
                        }
                    },
                });
            }
        }
    }

    newWindowReport(event){
        const reportSrc = document.getElementById('divOpenReport').getAttribute("src")
        const reportId = document.getElementById('divOpenReport').getAttribute("report-id")
        
        $.ajax({
            url: '/scouts/international_scout_trackman_reports/open_reports',
            method: 'GET',
            async: false,
            data: { "international_scout_trackman_report_id": reportId},
            success: function (data){
            window.open(reportSrc, '_blank'); 
            },
        });
    }
    
    showReport(event){
        const season = event.target.id
        const reportId = event.target.getAttribute("report-id")
  
        if ($(`#report_${season}`).is(":visible")==true){
          $(`#report_${season}`).fadeOut()
          $(`#report_${season}`).hide() 
        }
        else{
          $(`#report_${season}`).fadeIn()
          $(`#report_${season}`).show()
        }

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
            "tab_kind": 'show',
            "international_scout_trackman_report_id": reportId,
        }
        setLogs(data)
    }
    
    openReport(event){
        const reportSrc = event.target.getAttribute("src")
        const reportId = event.target.getAttribute("report-id")

        let data = { 
            "player_id":this.player_id,
            "kind" : 'show',
            "sector": 'scouting',
            "tab": 'report',
            "tab_kind": 'open',
            "international_scout_trackman_report_id": reportId,
        }
        setLogs(data)
        window.open(reportSrc, '_blank');
    }

}

function setLogs(data){
    $.ajax({
        url: '/scouts/international_scouts/set_logs',
        method: 'GET',
        async: false,
        data: data
    });        
}
