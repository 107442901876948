jQuery(document).on('turbolinks:load', function() {
	let clickedTD = '';
	$('#elbow_fc_avg').html('90도 이상 (&uarr;)');
	$('#shoulderout_fc_avg').html('40도 이상 (&uarr;)');
	$('#shoulderout_max_avg').html('150 ~ 180도');
	$('#shoulderin_br_avg').html('90도');
	$('#shoulderin_max_avg').html('100도 이하 (&darr;)');
	$('#shoulderhr_fc_avg').html('25도');
	$('#shoulderhr_br_avg').html('0도 근처');
	$('#shoulderhr_max_avg').html('35도');
	$('#torsofront_fc_avg').html('-10도(0도 이하)');
	$('#torsofront_br_avg').html('20도');
	$('#torsodegree_fc_avg').html('10도(0에 가깝게)');
	$('#pelvis_fc_avg').html('45도');
	$('#buttshoulder_max_avg').html('32도');
	$('#stride_fc_avg').html('80% 까지');
	$('#footlocation_fc_avg').html('25.4-30.5: 일직선');
	$('#centervelo_max_avg').html('2.8m/s');
	$('#pelvisvelo_max_avg').html('650 ~ 750');
	$('#torsovelo_max_avg').html('1100 ~ 1200');
	$('#elbowvelo_max_avg').html('2100 ~ 2300');
	$('#armvelo_max_avg').html('4600 ~ 4800');
	$('#maxpelvistotorso_fc_avg').html('0.0300초');

	$('#elbow_fc_avg_whole').html('90도 이상 (&uarr;)');
	$('#shoulderout_fc_avg_whole').html('40도 이상 (&uarr;)');
	$('#shoulderout_max_avg_whole').html('150 ~ 180도');
	$('#shoulderin_br_avg_whole').html('90도');
	$('#shoulderin_max_avg_whole').html('100도 이하 (&darr;)');
	$('#shoulderhr_fc_avg_whole').html('25도');
	$('#shoulderhr_br_avg_whole').html('0도 근처');
	$('#shoulderhr_max_avg_whole').html('35도');
	$('#torsofront_fc_avg_whole').html('-10도(0도 이하)');
	$('#torsofront_br_avg_whole').html('20도');
	$('#torsodegree_fc_avg_whole').html('10도(0에 가깝게)');
	$('#pelvis_fc_avg_whole').html('45도');
	$('#buttshoulder_max_avg_whole').html('32도');
	$('#stride_fc_avg_whole').html('80% 까지');
	$('#footlocation_fc_avg_whole').html('25.4-30.5: 일직선');
	$('#centervelo_max_avg_whole').html('2.8m/s');
	$('#pelvisvelo_max_avg_whole').html('650 ~ 750');
	$('#torsovelo_max_avg_whole').html('1100 ~ 1200');
	$('#elbowvelo_max_avg_whole').html('2100 ~ 2300');
	$('#armvelo_max_avg_whole').html('4600 ~ 4800');
	$('#maxpelvistotorso_fc_avg_whole').html('0.0300초');

	$(document).on('click','td[id$="bio_notes_td"]',function(){
		if(clickedTD != ''){
			clickedTD.removeClass('bg-blue-100 animate-pulse')
		}
		$("#note_biomechanic_note").get(0).scrollIntoView({ behavior: 'smooth', block: 'start' });
  		$('#note_biomechanic_details_id').val($(this).data('bdid'))
  		$('#note_biomechanic_kind').val($(this).data('kind'))
  		$(this).addClass('bg-blue-100 animate-pulse')
  		console.log($('#note_biomechanic_details_id').val())
  		console.log($('#note_biomechanic_kind').val())
  		clickedTD = $(this)
  		$("#note_biomechanic_note").focus()
  	})

});