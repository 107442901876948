import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class hip{
    
    // init
    constructor(chartData, div_id){
        this.chartData = chartData;
        this.hip_list=[
            'hip_left_internal_rotation', 
            'hip_left_external_rotation', 
            'hip_right_internal_rotation', 
            'hip_right_external_rotation'
        ]

        this.label_hip_list=[
            '내회전(왼)',
            '외회전(왼)',
            '내회전(오)',
            '외회전(오)',
        ]
        this.div_id=div_id
        this.max_value_list=[
            this.chartData['screening_max_value']['hip_left_internal_rotation'],
            this.chartData['screening_max_value']['hip_left_external_rotation'],
            this.chartData['screening_max_value']['hip_right_internal_rotation'],
            this.chartData['screening_max_value']['hip_right_external_rotation'],
        ]
        // this.unit_annotations={
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.02,
        //     xanchor: 'right',
        //     y: 1.04,
        //     yanchor: 'bottom',
        //     text: '단위 : [°]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // }
    };

    //function
    drawChart(){
        var max_value = Math.max.apply(null,this.max_value_list)*1.5;
        var layout = {
            barmode: 'group',
            yaxis:{
                fixedrange:true,
                ticksuffix: '°',
                range: [0, max_value],
                type: 'linear'
            },
            xaxis:{  
                fixedrange:true,
                min:0,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },

            annotations:[
                // this.unit_annotations
            ],
            autosize:true
        }
        var data_list = this.getDataset()

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false,
            clickmode:'select'
        }
        Plotly.newPlot('result_hip', data_list, layout, config );
        var graphDiv = document.getElementById('result_hip')
        
        var annotationList = this.getAverageAnnotations(graphDiv)
        Plotly.relayout(graphDiv,{
            'shapes':annotationList
        });

    };

    getDataset(){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = this.hip_list
        for(let [ monthIndex, monthData ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = this.getTrace(category_list, monthData, monthAverageData, monthIndex)
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData, monthIndex){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let value = monthData[category]==null ? '' : parseInt(monthData[category])
            let averageValue = monthAverageData[category]==null ? '' : parseInt(monthAverageData[category])
            dataList.push(value)
            averageDataList.push(`평균 : ${averageValue}°`)
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            // for (let [ dataIndex, data ] of dataList.entries()){
            //     var averageValue = averageList[dataIndex]==null ? '' :averageList[dataIndex];
            //     textList.push(`${data}(${averageValue})`)
            // }

            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                type:'bar',
                name:this.chartData['other_month_average'][monthIndex]['screening_month'],
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };

            return trace
        }
        else{
            return null
        }

    };

    getAverageAnnotations(graph_div){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of this.hip_list.entries()){
                var x0 = graph_div.calcdata[monthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[monthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        name:this.chartData['other_month_average'][monthIndex]['screening_month'],
                        layer:'below',
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            // text: `AVG:${averageValue}`,
                            font:{
                                size:10
                            }
                        },
                    }   
                    annotation_list.push(annotation)
                }
            }
        }
        return annotation_list
    }
};