import Rails from "@rails/ujs";

jQuery(document).on('turbolinks:load', function() {
  $('#youth_player_name_select').on('change',() => {
    let selectedPlayer = $('#youth_player_name_select').val()

    var ajax = new XMLHttpRequest()
    let queryParams = []
    if (selectedPlayer != ''){
      queryParams.push(`id=${selectedPlayer}`)
    }
    ajax.open("GET", `/lotte_players?${queryParams.join('&')}`, true)
    ajax.onload = function(data) {
      var player = JSON.parse(ajax.responseText)
      $('#player_id').val(player['player']['id'])
      var url = $("#player_img").attr("src")
      $('#player_name').html(player['player']['name_kor'])
      $('#player_birthdate').html(player['player']['birthdate'])
      $('#player_weight_kg').html(player['player']['weight_kg'])
      $('#player_height_cm').html(player['player']['height_cm'])
      $('#player_back_number').html(player['player']['back_number'])
      $('#player_position_number').html(player['player']['position_number'])
      $('#player_bats').html(player['player']['bats'])
      $('#player_throws').html(player['player']['throws'])
          }
    ajax.send()
  })
});