// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "template" ]
    initialize(){
        $(document).ready(function() {
          $('.coach-select').select2({
            placeholder: "코치 선택",
            width: '100%',
          });
        })
        $('.comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        });
    }
    connect() {
        $('#pitching_coach_month_evaluation_form').on('submit',function(event){
            var isReturn=true
            $('.pitching-coach-month-evaluation').each(function(index){
            
                var current_coach = $('.coach-select').eq(index).val()
                if (current_coach==""){
                    alert(`${index+1}번 평가서의 코치를 선택해주세요.`)
                    event.preventDefault()
                    isReturn=false
                    return false
                }
            });
            if (isReturn==false){
              return false
            } else {
              let year = document.getElementById('pitching_coach_month_evaluation_year').value
              let month = document.getElementById('pitching_coach_month_evaluation_month').value

              $.ajax({
                url: '/month_evaluations/pitching_coach_month_evaluations/check_duplicate',
                method: 'POST',
                async: false,
                data: { "year": year, "month": month},
                success: function (data){
                  if(data['duplicate']>=1){
                    event.preventDefault()
                    isReturn=false
                  }
                  else{
                    isReturn=true
                  }
                },
              });
            }
            if (isReturn==false){
              alert('같은 월의 월간평가서 이미 존재합니다.')
              return false
            }else{
              alert('작성 완료')
              return true
            }
        })
        $(window).on('resize', function(){
            $('.comment').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
          });
    }

    add_coach(event) {
        const content = this.templateTarget.innerHTML.replace(/NEW_COACH/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML("beforebegin", content)
        $('.coach-select').select2({
            placeholder: "코치 선택",
            width: '100%',
            height: '100%'
        });
    }
    
    remove_coach(event) {
        const wrapper = event.target.closest(".pitching-coach-month-evaluation")
        if (wrapper.dataset.newRecord == "true") {
            wrapper.remove()
        }
        else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = "none"
        }
    }

    setYear(event){
      const yearSelect = event.target.value
      $('.each_pitching_coach_month_evaluation_year').each(function(index){
        $('.each_pitching_coach_month_evaluation_year').eq(index).val(yearSelect)
      });
    }

    setYearHalf(event){
      const month = event.target.value=='1st' ? 6 :  9

      document.getElementById('pitching_coach_month_evaluation_month').value = month
      $('.each_pitching_coach_month_evaluation_month').each(function(index){
        $('.each_pitching_coach_month_evaluation_month').eq(index).val(month)
      });

    }
    add_onground_point(event){
        const wrapper = event.target.closest(".pitching-coach-month-evaluation")
        let onground_knowledge = wrapper.querySelector(("input[name*='onground_knowledge']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_knowledge']:checked")).value) : 0
        let onground_coaching = wrapper.querySelector(("input[name*='onground_coaching']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_coaching']:checked")).value) : 0
        let onground_attitude = wrapper.querySelector(("input[name*='onground_attitude']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_attitude']:checked")).value) : 0
        let onground_leadership = wrapper.querySelector(("input[name*='onground_leadership']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_leadership']:checked")).value) : 0
        let onground_responsibility = wrapper.querySelector(("input[name*='onground_responsibility']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='onground_responsibility']:checked")).value) : 0

        let evaluation = onground_knowledge+onground_coaching+onground_attitude+onground_leadership+onground_responsibility
        
        wrapper.querySelector(".pitching-coach-onground").innerHTML = `1. 그라운드(ON)- ${evaluation}점`
    }

    add_offground_point(event){
        const wrapper = event.target.closest(".pitching-coach-month-evaluation")
        let offground_selfstudy = wrapper.querySelector(("input[name*='offground_selfstudy']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='offground_selfstudy']:checked")).value) : 0
        let offground_communication = wrapper.querySelector(("input[name*='offground_communication']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='offground_communication']:checked")).value) : 0
        let offground_makeup = wrapper.querySelector(("input[name*='offground_makeup']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='offground_makeup']:checked")).value) : 0
        let offground_relation = wrapper.querySelector(("input[name*='offground_relation']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='offground_relation']:checked")).value) : 0
        let offground_popularity = wrapper.querySelector(("input[name*='offground_popularity']:checked"))!=null ? parseInt(wrapper.querySelector(("input[name*='offground_popularity']:checked")).value) : 0

        let evaluation = offground_selfstudy+offground_communication+offground_makeup+offground_relation+offground_popularity
        
        wrapper.querySelector(".pitching-coach-offground").innerHTML = `2. 그라운드(OFF) - ${evaluation}점`
    }
    setHeight(event){
        const wrapper = event.target
        wrapper.style.height = 'auto'
        wrapper.style.height=wrapper.scrollHeight+"px"
    }
}