import Plotly from 'plotly.js-dist';
import { color_list, label_dict } from "./variables";

export class fieldTestCommon{
    
    // init
    constructor(chartData){
        this.chartData = chartData;
        this.sprint_list=[
            'field_test_sprint_10',
            'field_test_sprint_30',
        ]
        this.label_sprint_list=[
            // 'field_test_sprint_10': 'Front(L)',
            '스프린트(10m)',
            // 'field_test_sprint_30': 'Front(R)',  
            '스프린트(30m)',    
            // 'field_test_medball_throws': 'Side(L)',
        ]
        this.sprint_max_value_list=[
            this.chartData['screening_max_value']['field_test_sprint_10'],
            this.chartData['screening_max_value']['field_test_sprint_30']
        ]
        // this.unit_annotations=[{
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.02,
        //     xanchor: 'right',
        //     y: 1.04,
        //     yanchor: 'bottom',
        //     text: '단위 : [초]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // }]
    };

    //function
    drawChart(){
        var sprint_max_value = Math.max.apply(null,this.sprint_max_value_list)*1.25;
        var layout = {
            barmode: 'group',
            xaxis:{  
                fixedrange:true,
                
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },            
            yaxis:{
                fixedrange:true,
                ticksuffix: '초',
                range: [0, sprint_max_value],
                type: 'linear',
                dtick:0.5,
                minallowed:0,
                rangemode:'nonnegative',
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            // automargin:true,
            
            // annotations:this.unit_annotations,
        }
        var sprint_list = this.getDataset('sprint')

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }

        Plotly.newPlot('result_field_test_common', sprint_list, layout, config );
        var graphDiv = document.getElementById('result_field_test_common')
        var sprint_annotation_list = this.getAverageAnnotations(graphDiv);
        graphDiv.layout['shapes']=sprint_annotation_list
        Plotly.redraw(graphDiv);

    };

    getDataset(category){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = this.sprint_list
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = this.getTrace(category_list, month, monthAverageData, monthIndex)
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData,  monthIndex, screening_category='sprint'){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let value = monthData[category]==null ? '' : parseFloat(monthData[category]).toFixed(2)
            let averageValue = monthAverageData[category]==null ? '' : parseFloat(monthAverageData[category]).toFixed(1)
            let averageText = `평균 : ${averageValue}초`
            averageDataList.push(averageText)
            dataList.push(value)
            
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                name : monthData['screening_month'],
                type:'bar',
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };
            return trace
        }
        else{
            return null
        }
    };

    getAverageAnnotations(graph_div, screening_category='sprint'){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        var category_list = this.sprint_list
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of category_list.entries()){
                var x0 = graph_div.calcdata[monthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[monthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            // text: `AVG:${averageValue}`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    annotation_list.push(annotation)
                }
            }
        }
        return annotation_list
    }
};

export class fieldTestPitcher{
    
    // init
    constructor(chartData, div_id){
        this.chartData = chartData;
        this.pitcher_test_list=[
            'field_test_run_276'
        ]

        this.label_pitcher_test_list=[
            '276m달리기',
        ]
        // this.unit_annotations=[{
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.02,
        //     xanchor: 'right',
        //     y: 1.04,
        //     yanchor: 'bottom',
        //     text: '단위 : [초]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // }]
    };

    //function
    drawChart(){
        var max_value = this.chartData['screening_max_value']['field_test_run_276']*1.5
        var layout = {
            barmode: 'group',
            yaxis:{
                fixedrange:true,
                ticksuffix: '초',
                range:[0, max_value],
                rangemode:'nonnegative'
            },
            xaxis:{  
                fixedrange:true,
                min:0,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },
            // autosize:true,
            // annotations:this.unit_annotations,
        }
        var data_list = this.getDataset()

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }
        Plotly.newPlot('result_field_test_pitcher', data_list, layout, config );
        var graphDiv = document.getElementById('result_field_test_pitcher')
        var annotationList = this.getAverageAnnotations(graphDiv)
        graphDiv.layout['shapes']=annotationList
        Plotly.redraw(graphDiv);

    };

    getDataset(){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = this.pitcher_test_list
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = this.getTrace(category_list, month, monthAverageData, monthIndex)
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData, monthIndex){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let averageValue = monthAverageData[category]==null ? '' : parseFloat(monthAverageData[category]).toFixed(1)
            averageDataList.push(`평균 : ${averageValue}초`)
            dataList.push(monthData[category])
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                name : monthData['screening_month'],
                type:'bar',
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };

            return trace
        }
        else{
            return null
        }

    };

    getAverageAnnotations(graph_div){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of this.pitcher_test_list.entries()){
                var x0 = graph_div.calcdata[monthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[monthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        layer: 'below',
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            // text: `AVG:${averageValue}`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    annotation_list.push(annotation)
                }

            }
        }
        return annotation_list
    }
};

export class fieldTestPosition{
    
    // init
    constructor(chartData, div_id){
        this.chartData = chartData;
        this.position_player_list=[
            'field_test_three_feet',
            'field_test_first_to_second',
            'field_test_first_to_third',
            'field_test_t_run',    
        ]

        this.label_position_player_list=[
            // 'y_balance_left_cross': 'Cross(L)',
            '3피트 달리기',
            // 'y_balance_right_cross': 'Cross(R)',
            '베이스런닝(1루->2루)',
            // 'y_balance_left_cross': 'Cross(L)',
            '베이스런닝(1루->3루)',
            // 'y_balance_right_cross': 'Cross(R)',
            'T-Run',    
        ]
        this.fieldtest_max_value_list=[
            this.chartData['screening_max_value']['field_test_three_feet'],
            this.chartData['screening_max_value']['field_test_first_to_second'],
            this.chartData['screening_max_value']['field_test_first_to_third'],
            this.chartData['screening_max_value']['field_test_t_run']
        ]
        // this.unit_annotations=[{
        //     xref: 'paper',
        //     yref: 'paper',
        //     x: 0.02,
        //     xanchor: 'right',
        //     y: 1.04,
        //     yanchor: 'bottom',
        //     text: '단위 : [초]',
        //     showarrow: false,
        //     font:{
        //         size:12
        //     }
        // }]
    };
    //function
    drawChart(){
        var max_value = Math.max.apply(null,this.fieldtest_max_value_list)*1.25;
        var layout = {
            barmode: 'group',
            yaxis:{
                fixedrange:true,
                ticksuffix: '초',
                range:[0, max_value],
                rangemode:'nonnegative'
            },
            xaxis:{  
                fixedrange:true,
                min:0,
                tickfont: {
                    size: 14,
                    color: "black",
                },                
            },
            legend:{
                x:1,
                xref:'paper',
                xanchor: 'right',
                y: 1.1,
                "orientation": "h",
                itemclick:false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
            },
        }
        var data_list = this.getDataset()

        var config = {
            responsive: true,
            displayModeBar: false,
            showAxisDragHandles: false
        }
        Plotly.newPlot('result_field_test_position_player', data_list, layout, config );
        var graphDiv = document.getElementById('result_field_test_position_player')
        var annotationList = this.getAverageAnnotations(graphDiv)
        graphDiv.layout['shapes']=annotationList
        Plotly.redraw(graphDiv);

    };
    getDataset(){
        // example
        // var trace1 = {
        //     x: ['어깨 외회전(앉은자세)', '극상근 기능', '어깨 내회전(엎드린 자세)', '어깨 외회전(엎드린 자세)'],
        //     y: [d_internal_rotation, d_external_rotation, nd_internal_rotation],
        //     name: '4월',
        //     type: 'bar',
        //     orientation: 'h'
        //   };
          
        var trace_list = []
        var category_list = this.position_player_list
        for(let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            var monthAverageData = this.chartData['other_month_average'][monthIndex]
            var trace = this.getTrace(category_list, month, monthAverageData, monthIndex)
            if (trace!=null){
                trace_list.push(trace)
            }
        }
        return trace_list

    };
    getTrace(category_list, monthData, monthAverageData, monthIndex){
        var dataList=[];
        var labelList=[];
        var averageDataList=[];
        for (let category of category_list){
            let value = monthData[category]==null ? '' : parseFloat(monthData[category]).toFixed(1)
            dataList.push(value)
            let averageValue = monthAverageData[category]==null ? '' : parseFloat(monthAverageData[category]).toFixed(1)
            averageDataList.push(`평균 : ${averageValue}초`)
            labelList.push(label_dict[category])
        }
        if (dataList.length!=0){
            // for (let [ dataIndex, data ] of dataList.entries()){
            //     var averageValue = averageList[dataIndex]==null ? '' :averageList[dataIndex];
            //     textList.push(`${data}(${averageValue})`)
            // }

            var trace = {
                x:labelList,
                text:dataList,
                textposition:'outside',
                y:dataList,
                name : monthData['screening_month'],
                type:'bar',
                legendgroup:monthData['screening_month'],
                hovertext:averageDataList,
                hoverinfo: 'text',
                marker: {
                    color: color_list[monthIndex],
                    opacity: 0.15,
                },
                insidetextfont: {
                    size: 14,
                    color: "black",
                },
                showlegend : true
            };

            return trace
        }
        else{
            return null
        }

    };
    getAverageAnnotations(graph_div){
        // annotations: [
        //   {
        //     type: 'line',
        //     x0: '2015-02-01',
        //     y0: 8,
        //     x1: '2015-02-28',
        //     y1: 8,
        //     fillcolor: '#d3d3d3',
        //     opacity: 0.2,
        //     editable: true,
        //     label: {
        //       text: 'January average',
        //       yanchor: 'top',
        //     },
        //   },
        //]
        var annotation_list = [];
        for (let [ monthIndex, month ] of this.chartData['other_months'].entries()) {
            for (let [categoryIndex, category] of this.position_player_list.entries()){
                var x0 = graph_div.calcdata[monthIndex][categoryIndex].p0+0.01
                var x1 = graph_div.calcdata[monthIndex][categoryIndex].p1-0.01
                var averageValue = this.chartData['other_month_average'][monthIndex][category]
                if (averageValue!=null){
                    const annotation = {
                        type: 'line',
                        layer:'below',
                        line:{
                            width:5,
                            color: color_list[monthIndex],
                        },
                        opacity: 0.7,
                        x0: x0,
                        x1: x1,
                        y0: averageValue,
                        y1: averageValue,
                        xref: 'x',
                        yref: 'y',
                        label: {
                            // text: `AVG:${averageValue}`,
                            font:{
                                size:10
                            }
                        }
                    }   
                    annotation_list.push(annotation)
                }

            }
        }
        return annotation_list
    }
};