jQuery(document).on("turbolinks:load", function() {
  $('#player_plan_player_goals').on('click',function(){
      $('#player_plan_player_goals_detail').slideToggle(100)
    })

  $('#player_plan_player_pros').on('click',function(){
      $('#player_plan_player_pros_detail').slideToggle(100)
    })

  $('#player_plan_player_cons').on('click',function(){
      $('#player_plan_player_cons_detail').slideToggle(100)
    })

});