import Rails from "@rails/ujs";
import Plyr from "plyr";
import pickadate from "pickadate";
import koreanTranslation from "pickadate/builds/translations/ko_KR";

jQuery(document).on('turbolinks:load', function() {
  $('input[id$="_checkbox"]').on('click', event =>{
	  	if($(event.currentTarget).prop("checked")){
	    	$(event.currentTarget).parent().addClass("animate-pulse");
		}
      else{
        $(event.currentTarget).parent().removeClass("animate-pulse","");
      }
	});

  const DEFAULT_START_DATE = new Date(
  	new Date().setDate(new Date().getDate()-10)
  	)
  const DEFAULT_END_DATE = new Date();
  const selectedFilters = {};
  let pitcherSearch;
  let batterSearch;
  let allPitches = [];
  let selectedPitches = {};
  let fromPicker;
  let toPicker;
  let playlist;
  let view_select;
  setUpMobileDateFilters();
  enablePitcherSearch();
  enableBatterSearch();

  $('#batter-team-filter').on('change',event=>{
    var ajax = new XMLHttpRequest();
    let queryParams = [""];
    let selectedTeam = $("#batter-team-filter").val();
    if (selectedTeam != "") {
      selectedTeam = check_organization(selectedTeam)
      queryParams.push(`hitter_team_id=${selectedTeam}`);
    }
    ajax.open("GET", `/pitch_hitters?${queryParams.join("&")}`, true);
    ajax.onload = function() {
      const list = JSON.parse(ajax.responseText)["pitch_hitters"].map(i => ({
        label: `${i.name_kor} (${i.name_full}) (${i.back_number})`,
        value: i.id
      }));
      const hitters = list;

      batterSearch.list = hitters

      Awesomplete.$("#batter-mobile-search-dropdown").removeEventListener(
        "click",
        batterSearchDropdown
      );
      Awesomplete.$("#batter-mobile-search-dropdown").addEventListener(
        "click",
        batterSearchDropdown
      );
    };
    ajax.send();
  })

  $('#pitcher-team-filter').on('change',event=>{
    var ajax = new XMLHttpRequest();
    let queryParams = [""];
    let selectedTeam = $("#pitcher-team-filter").val();
    if (selectedTeam != "") {
      selectedTeam = check_organization(selectedTeam)
      queryParams.push(`pitcher_team_id=${selectedTeam}`);
    }
    ajax.open("GET", `/pitch_pitchers?${queryParams.join("&")}`, true);
    ajax.onload = function() {
      const list = JSON.parse(ajax.responseText)["pitch_pitchers"].map(i => ({
        label: `${i.name_kor} (${i.name_full}) (${i.back_number})`,
        value: i.id
      }));
      const pitchers = list;

      pitcherSearch.list = pitchers

      Awesomplete.$("#pitcher-mobile-search-dropdown").removeEventListener(
        "click",
        pitcherSearchDropdown
      );
      Awesomplete.$("#pitcher-mobile-search-dropdown").addEventListener(
        "click",
        pitcherSearchDropdown
      );
    };
    ajax.send();
  })

  let mobile_player = new Plyr("#mobile_video_player",{fullscreen:{iosNative:true, fallback:true}});
  $("#search_mobile_video").on('click',applyMobileFilters);

  function check_organization(selectedTeamId){
    let return_select_team_id
    switch (selectedTeamId){
      case '11':
        return_select_team_id = 1
        break
      case '12':
        return_select_team_id = 2
        break
      case '13':
        return_select_team_id = 3
        break
      case '14':
        return_select_team_id = 4
        break
      case '15':
        return_select_team_id = 5
        break
      case '16':
        return_select_team_id = 6
        break
      case '17':
        return_select_team_id = 7
        break
      case '18':
        return_select_team_id = 8
        break
      case '19':
        return_select_team_id = 9
        break
      case '20':
        return_select_team_id = 23
        break
      default:
        return_select_team_id = selectedTeamId
    }
    return return_select_team_id
  }

  function setUpMobileDateFilters(){
  	const fromInitialState = { selected: DEFAULT_START_DATE };
	const toInitialState = { selected: DEFAULT_END_DATE };

  if ($("#player-login-mobile-video-game-date").val() != null){
    fromPicker = pickadate.create({selected: new Date($("#player-login-mobile-video-game-date").val()) });
  }
  else{
    fromPicker = pickadate.create(fromInitialState);
  }
	const fromElement = document.getElementById("pitch-mobile-video-start-date");
	if (!fromElement) {
	  return;
	}
	pickadate.render(fromElement, fromPicker);

  if ($("#player-login-mobile-video-game-date").val() != null){
    toPicker = pickadate.create({selected: new Date($("#player-login-mobile-video-game-date").val()) });
  }
  else{
    toPicker = pickadate.create(fromInitialState);
  }
	const toElement = document.getElementById("pitch-mobile-video-end-date");
	if (!toElement) {
	  return;
	}
	pickadate.render(toElement, toPicker);

	fromPicker.setMaximum({ value: toPicker.store.getState().selected });
	toPicker.setMinimum({ value: fromPicker.store.getState().selected });

	fromElement.addEventListener("pickadate:change", formattedValue => {
	  fromElement.value = fromPicker.getValue();
	  toPicker.setMinimum({ value: fromPicker.store.getState().selected });
	  });

	toElement.addEventListener("pickadate:change", formattedValue => {
	  toElement.value = toPicker.getValue();
	  fromPicker.setMaximum({ value: toPicker.store.getState().selected });
	});

  }
  function applyMobileFilters(){
    $("#mobile_video_waiting").show()
    $("#mobile_video_waiting_over").addClass("opacity-50")
  	const query_params = []
  		let pa_result = []
      const fromDate = fromPicker
        ? new Date(fromPicker.store.getState().selected)
        : DEFAULT_START_DATE;
      const toDate = toPicker
        ? new Date(toPicker.store.getState().selected)
        : DEFAULT_END_DATE;
      query_params.push(`date_from=${fromDate.toISOString()}`);
      query_params.push(`date_to=${toDate.toISOString()}`);
  		if($('#hit_hitter_checkbox').prop("checked")){
  			pa_result.push('Single')
  		}
  		if($('#double_hitter_checkbox').prop("checked")){
  			pa_result.push('Double')
  		}
  		if($('#triple_hitter_checkbox').prop("checked")){
  			pa_result.push('Triple')
  		}
  		if($('#homerun_hitter_checkbox').prop("checked")){
  			pa_result.push('HomeRun')
  		}

  		let pitch_result = []
  		let hit_type = []

  		if($('#swing_pitcher_checkbox').prop("checked")){
  			pitch_result.push('StrikeSwinging')
  		}
  		if($('#looking_pitcher_checkbox').prop("checked")){
  			pitch_result.push('StrikeCalled')
  		}

  		if($('#fly_pitcher_checkbox').prop("checked")){
  			hit_type.push("FlyBall,LineDrive,Popup")
  		}
  		if($('#ground_pitcher_checkbox').prop("checked")){
  			hit_type.push("GroundBall")
  		}

  		if (pitch_result.length>0){
  			pa_result.push("Strikeout")
  		}
  		if (hit_type.length>0){
  			pa_result.push("Out")
  		}

      let selectedPitchResult = pitch_result.join(",");
      let selectedHitType = hit_type.join(",");
      let selectedPAResult = pa_result.join(",");

  		query_params.push(`hit_type=${selectedHitType}`)
  		query_params.push(`pitch_result=${selectedPitchResult}`)
  		query_params.push(`pa_result=${selectedPAResult}`)
      
      if (selectedFilters.hasOwnProperty("pitcher")) {
      query_params.push(`pitcher_id=${selectedFilters["pitcher"]}`)
      }

      let selectedPitcherTeam = $('#pitcher-team-filter').val()
      if (selectedPitcherTeam != '') {
        query_params.push(`pitcher_team_id=${selectedPitcherTeam}`)
      }

      if (selectedFilters.hasOwnProperty("batter")) {
        query_params.push(`batter_id=${selectedFilters["batter"]}`)
      }

      let selectedBatterTeam = $('#batter-team-filter').val()
      if (selectedBatterTeam != '') {  
        query_params.push(`hitter_team_id=${selectedBatterTeam}`)
      };

      view_select = $("#view_select").val();

      Rails.ajax({
        url: "/filtered_pitches",
        type: "post",
        data: query_params.join("&"),
        success: fetch_video
      })
  }
  function fetch_video(payload){
  	const {pitches} = payload
  	selectedPitches = pitches
  	Rails.ajax({
  		url: `/pitch_playlist?ids=${pitches.map(pitch => pitch.id).join(",")}&view=${view_select}`,
  		type: "get",
  		success: playMobileVideo
  	})
  }

  function playMobileVideo(payload){
  	mobilePlayList(".plyr-playlist", payload)

  }

  function saveFilter(filterType, value) {
  selectedFilters[filterType] = value;
}

  function pitcherSearchDropdown() {
  if (pitcherSearch.ul.childNodes.length === 0) {
    pitcherSearch.minChars = 0;
    pitcherSearch.evaluate();
  } else if (pitcherSearch.ul.hasAttribute("hidden")) {
    pitcherSearch.open();
  } else {
    pitcherSearch.close();
  }
}

function batterSearchDropdown() {
  if (batterSearch.ul.childNodes.length === 0) {
    batterSearch.minChars = 0;
    batterSearch.evaluate();
  } else if (batterSearch.ul.hasAttribute("hidden")) {
    batterSearch.open();
  } else {
    batterSearch.close();
  }
}

function enablePitcherSearch() {
  const input = document.querySelector("#pitch-mobile-video-pitcher-search");
  if (!input) {
    return;
  }
  input.value = "";
  input.addEventListener("awesomplete-selectcomplete", selected => {
    saveFilter("pitcher", selected.text.value);
  });
  input.addEventListener("input", ev => {
    const value = input.value.trim();

    if (!value) {
      delete selectedFilters["pitcher"];
    }
  });

  var ajax = new XMLHttpRequest();
  let queryParams = ["all_pitchers=true"];
  let selectedTeam = $("#pitcher-team-filter").val();
  if (selectedTeam != "") {
    queryParams.push(`pitcher_team_id=${selectedTeam}`);
  }
  ajax.open("GET", `/pitch_pitchers?${queryParams.join("&")}`, true);
  ajax.onload = function() {
    const list = JSON.parse(ajax.responseText)["pitch_pitchers"].map(i => ({
      label: `${i.name_kor} (${i.name_full}) (${i.back_number})`,
      value: i.id
    }));
    const pitchers = list;

    pitcherSearch = new Awesomplete(input, {
      list: pitchers,
      replace: suggestion => (input.value = suggestion.label),
      minChars: 0,
      maxItems: 5
    });

    $("#mobile_video_waiting").hide()
    $("#mobile_video_waiting_over").removeClass("opacity-50")

    Awesomplete.$("#pitcher-mobile-search-dropdown").removeEventListener(
      "click",
      pitcherSearchDropdown
    );
    Awesomplete.$("#pitcher-mobile-search-dropdown").addEventListener(
      "click",
      pitcherSearchDropdown
    );

    playerLoginSetup()
  };
  ajax.send();
}

function playerLoginSetup() {
  var pitcherLogin = $("#player-login-mobile-video-pitcher").val()
  var batterLogin = $("#player-login-mobile-video-batter").val()

  if(pitcherLogin != null && selectedFilters["batter"] == null){
    selectedFilters["pitcher"] = pitcherLogin
    $("#search_mobile_video").click()
  }
  else if(batterLogin != null && selectedFilters["pitcher"] == null){
    selectedFilters["batter"] = batterLogin
    $("#search_mobile_video").click()
  }
  else{
    return ;
  }
}

function enableBatterSearch() {
  const input = document.querySelector("#pitch-mobile-video-batter-search");
  if (!input) {
    return;
  }
  input.value = "";
  input.addEventListener("awesomplete-selectcomplete", selected => {
    saveFilter("batter", selected.text.value);
  });
  input.addEventListener("input", ev => {
    const value = input.value.trim();

    if (!value) {
      delete selectedFilters["batter"];
    }
  });

  var ajax = new XMLHttpRequest();
  let queryParams = ["all_batters=true"];
  var selectedTeam = $("#batter-team-filter").val();
  if (selectedTeam != "") {
    queryParams.push(`hitter_team_id=${selectedTeam}`);
  }
  ajax.open("GET", `/pitch_hitters?${queryParams.join("&")}`, true);
  ajax.onload = function() {
    const list = JSON.parse(ajax.responseText)["pitch_hitters"].map(i => ({
      label: `${i.name_kor} (${i.name_full}) (${i.back_number})`,
      value: i.id
    }));
    const batters = list;

    batterSearch = new Awesomplete(input, {
      list: batters,
      replace: suggestion => (input.value = suggestion.label),
      minChars: 0,
      maxItems: 5
    });

    Awesomplete.$("#batter-mobile-search-dropdown").removeEventListener(
      "click",
      batterSearchDropdown
    );
    Awesomplete.$("#batter-mobile-search-dropdown").addEventListener(
      "click",
      batterSearchDropdown
    );
  };
  ajax.send();
}
	
	function mobilePlayList(target, queryedPlaylist){
    $("#mobile_video_waiting").hide()
    $("#mobile_video_waiting_over").removeClass("opacity-50")
    if ($("#playlist_wrapper").length){
      $("#playlist_wrapper").remove();
    }
		$('<div class="plyr-playlist-wrapper" id="playlist_wrapper"><ul class="plyr-playlist max-h-32 mx-2 border overflow-auto rounded-md"></ul></div>').insertAfter("#mobile_video-video")
		let startwith = 0;
		let playingclass="";
		let items = [];
		$.each(queryedPlaylist, function(index,item){
			if(0 === index){
				playingclass = "pls-playing opacity-75 opacity-100";
				mobile_player.source = {
					type: "video",
					sources:[
					{
						src:item["url"],
						type:"video/mp4"
					}
					]
				}
				mobile_player.play();
			}
			else playingclass = "";

			items.push(
	        '<li class="bg-black opacity-75 '+playingclass+' border-t"><a class="text-xs text-white font-bold mx-2" href="#" data-type="mp4' +
          '" data-video-id="' + item["url"] +'">'+ "날짜: " + item["game_date"] + ", " + 
          "타자: "+ item["batter_name_kor"] +'(' + item["batter_team_name"] + ')' + ', 투수: ' + item["pitcher_name_kor"] +'(' + item["pitcher_team_name"] + ')'+
           '<br class="mx-2">' +'구종: ' + item["pitch_type"] +', 구속: ' + String(Number(item["rel_speed"]).toFixed(1)) + ', 회전수: ' + String(Number(item["spin_rate"]).toFixed(1)) +
           '<br class="mx-2">' + '타구 속도: ' + String(Number(item["exit_speed"]).toFixed(1)) + ', 발사 각도: ' + String(Number(item["angle"]).toFixed(1)) + "</a></li> ");
			$(target).html(items.join(""));
		})
	}

	$(document).on("click", "ul.plyr-playlist li a", function(event) {
    //$("ul.plyr-playlist li a").on("click", function(event) {
	    event.preventDefault();

	    $("li.pls-playing").removeClass("pls-playing opacity-100");

	    $(this)
	      .parent()
	      .addClass("pls-playing opacity-100");

	    var video_id = $(this).data("video-id");
	    var video_type = $(this).data("type");

	    mobile_player.source = {
	      type: "video",
	      sources: [
	      { 
	      	src: video_id, 
	      	type: "video/mp4"
	      }
	      ]
	    };
      $(".plyr-playlist").scrollTo(".pls-playing", 300);

	  })
	mobile_player.on("ready",function(event){
		if(mobile_player.fullscreen.active){mobile_player.fullscreen.enter()}
		mobile_player.play();
	})

	mobile_player.on("ended", function(event) {
    var $next = $(".plyr-playlist .pls-playing")
      .next()
      .find("a")
      .trigger("click");
  });

  jQuery.fn.scrollTo = function(elem, speed, margin) {
  jQuery(this).animate(
    {
      scrollTop:
        jQuery(this).scrollTop() -
        jQuery(this).offset().top +
        jQuery(elem).offset().top
    },
    speed == undefined ? 1000 : speed
  );
  return this;
};

});