// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus"

export default class extends Controller {
    initialize(){
      $(document).ready(function() {
        $('.interview-content').each(function(){
          //scroll height가 client height의 길이를 뛰어넘는 지점에서
          // if (elem.clientHeight < elem.scrollHeight)
          if(this.clientHeight < this.scrollHeight){
            $(this).height(this.scrollHeight)
          }
        })             
      })
 
    }
    connect(){
      $(window).on('resize', function(){
        $('.interview-content').each(function(){
          $(this).css('height', 'auto' );
          $(this).height(this.scrollHeight)
        })
      });
    }

    show_interview(event){
      const log_id = event.target.id

      if ($(`#sector_${log_id}`).is(":visible")==true){
        $(`#${log_id}`).text(`${log_id} 면담일지`)
        $(`#sector_${log_id}`).fadeOut()
        $(`#sector_${log_id}`).hide() 
      }
      else{
        $(`#${log_id}`).text(`${log_id} 면담일지`)
        $(`#sector_${log_id}`).fadeIn()
        $(`#sector_${log_id}`).show()
      }      

    }
}